// React and hooks
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { addArtist } from "../../redux/slice/artist";

// Axios for API requests
import axios from "axios";

// Material UI components
import {
  Box,
  ImageList,
  ImageListItem,
  Avatar,
  CircularProgress,
  Modal,
  Typography,
  Grid,
  Button,
  Stack,
  Skeleton,
} from "@mui/material";

// Material UI icons
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

// Utilities
import { toast } from "react-toastify";
import { addCommasToNumber } from "../../utils/helper";
import { getSpotifySession } from "../../utils/spotifyApiServiceHandler";

// Styles
import classes from "./style.module.scss";

// Component function
const SimilarArtist = ({ similarArtist, loader }) => {
  // State hooks
  const [selected, setSelected] = useState(null);
  const [AConfirm, setAConfirm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // Navigation and dispatch hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Handlers for modal and artist actions
  const handleOpen = (artist) => {
    setSelected(artist);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAConfirm(true);
    setSelected(null);
  };

  // Function to get albums of an artist from Spotify
  const getAlbums = (spotify_accessToken, spotify_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `https://api.spotify.com/v1/artists/${spotify_id}/albums`;
        const config = {
          headers: { Authorization: `Bearer ${spotify_accessToken}` },
        };
        const response = await axios.get(url, config);

        console.log(response.data);

        resolve(response.data.items);
      } catch (error) {
        reject(error);
      }
    });
  };

  // Function to get tracks by album from Spotify
  const getTracksByAlbum = (spotify_accessToken, album) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `https://api.spotify.com/v1/albums/${album.id}/tracks`;
        const config = {
          headers: { Authorization: `Bearer ${spotify_accessToken}` },
        };
        const response = await axios.get(url, config);

        console.log(album);

        let result = response.data.items.map((item) => ({
          track_title: item.name,
          album: {
            id: album.id,
            name: album.name,
            release_date: album.release_date,
          },
        }));

        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  // Function to handle the process before adding an artist
  const BeforeHandler = async () => {
    setIsLoading(true);

    let spotify_token = await getSpotifySession();
    let albums = await getAlbums(spotify_token, selected?.similar_artist_id);
    let result = [];

    for (let album of albums) {
      let tracks = await getTracksByAlbum(spotify_token, album);
      for (let track of tracks) {
        result.push(track);
      }
    }

    const payload = {
      albums: result,
      artist: {
        name: selected?.name,
        spotify_id: selected?.similar_artist_id,
        avatar: selected?.image,
        genres: selected?.genres,
      },
    };

    console.log(payload);

    let response = dispatch(
      addArtist({
        data: payload,
      })
    );

    response
      .then(async (res) => {
        if (res.payload.message === "artist created") {
          toast.message("New artist added");
        }
      })
      .finally(() => {
        setIsLoading(false);
        navigate("/blig/my-artist");
      });
  };

  // Function to handle actions after artist is added
  const AfterHandler = () => {
    setAConfirm(true);
    navigate("/blig/my-artist");
  };

  return (
    <Box component="div" varient="div" className={classes.page}>
      <Box component="div" varient="div" className={classes.page__header}>
        <Box
          component="div"
          varient="div"
          className={classes.page__header__title}
        >
          Similar Artists
        </Box>
      </Box>

      <Box component="div" varient="div" className={classes.page__list}>
        {loader && (
          <ImageList className={classes.page__list__list_continer}>
            {Array.from({ length: 10 }).map((_, index) => (
              <SkeletonLoaderComponent />
            ))}
          </ImageList>
        )}
        {similarArtist && similarArtist.length > 0 && (
          <ImageList className={classes.page__list__list_continer}>
            {similarArtist.slice(0, 19).map((artist, idx) => (
              <ArtistListComponent
                artist={artist}
                handleOpen={handleOpen}
                idx={idx}
              />
            ))}
          </ImageList>
        )}

        <CustomModalForAddArtist
          open={open}
          handleClose={handleClose}
          AConfirm={AConfirm}
          selected={selected}
          isLoading={isLoading}
          BeforeHandler={BeforeHandler}
          AfterHandler={AfterHandler}
        />
      </Box>
    </Box>
  );
};

export default SimilarArtist;

const SkeletonLoaderComponent = () => {
  return (
    <ImageListItem className={classes.page__list__list_continer__list_items}>
      <Box
        className={classes.page__list__list_continer__list_items__img_details}
      >
        <Box
          component="div"
          varient="div"
          className={classes.page__list__wrapper}
        >
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
        <Box
          component="div"
          varient="div"
          className={classes.page__list__ul__li__content}
        >
          <span className={classes.page__list__ul__li__content__name}>
            <Skeleton variant="text" fontSize="1rem" />
          </span>

          <span className={classes.page__list__ul__li__content__listners}>
            <Skeleton variant="text" fontSize="1rem" />
          </span>
        </Box>
      </Box>
    </ImageListItem>
  );
};

const ArtistListComponent = ({ artist, handleOpen, idx }) => {
  return (
    <ImageListItem
      key={idx}
      className={classes.page__list__list_continer__list_items}
    >
      <Box
        className={classes.page__list__list_continer__list_items__img_details}
      >
        <Box
          component="div"
          varient="div"
          className={classes.page__list__wrapper}
        >
          <Box className={classes.page__list__wrapper__controls}>
            {artist.status === true ? (
              <Link
                to={`/blig/view-artist/${artist?.ref_artist_id}`}
                style={{ color: "#FFF", textDecoration: "none" }}
              >
                <Box className={classes.page__list__wrapper__controls__view}>
                  <RemoveRedEyeOutlinedIcon
                    sx={{ fontSize: 20, color: "var(--picture-border-color)" }}
                  />
                </Box>
              </Link>
            ) : (
              <Box
                className={classes.page__list__wrapper__controls__add}
                onClick={() => handleOpen(artist)}
              >
                <PersonAddAltIcon
                  sx={{ fontSize: 20, color: "var(--picture-border-color)" }}
                />
              </Box>
            )}
          </Box>

          <Avatar
            className={classes.page__list__ul__li__image}
            src={artist?.image}
            alt={artist?.name}
            sx={{ width: "50px", height: "50px" }}
          />
        </Box>

        <Box
          component="div"
          varient="div"
          className={classes.page__list__ul__li__content}
        >
          <span className={classes.page__list__ul__li__content__name}>
            {artist?.name}
          </span>

          <span className={classes.page__list__ul__li__content__listners}>
            {addCommasToNumber(artist?.followers)} <span>followers</span>
          </span>
        </Box>
      </Box>
      <Box className={classes.page__list__list_continer__list_items}>
        {artist?.followers > 100000 ? (
          <TrendingUpIcon
            sx={{
              height: 18,
              width: 18,
              color: "#5FF979",
            }}
          />
        ) : (
          <TrendingDownIcon
            sx={{
              height: 18,
              width: 18,
              color: "#F13838",
            }}
          />
        )}
      </Box>
    </ImageListItem>
  );
};

const CustomModalForAddArtist = ({
  open,
  handleClose,
  AConfirm,
  selected,
  isLoading,
  BeforeHandler,
  AfterHandler,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      keepMounted
    >
      {AConfirm && AConfirm ? (
        <Box className={classes.modalbox}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add selected artist to list?
          </Typography>

          <Grid container className={classes.modalbox__artistbox} mt={2} mb={2}>
            <Grid className={classes.modalbox__artistbox__image}>
              <Avatar
                className={classes.page__list__ul__li__image}
                sx={{ width: "50px", height: "50px" }}
                src={selected?.image}
              />
            </Grid>
            <Grid className={classes.modalbox__artistbox__title}>
              <span title={selected?.name} onMouseEnter={selected?.name}>
                {selected?.name}
              </span>
            </Grid>
          </Grid>
          {isLoading ? (
            <Stack direction="row" justifyContent="center" spacing={2}>
              <CircularProgress size={40} color="secondary" />
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                className={classes.modalbox__cancelBtn}
                // sx={{
                //   backgroundColor: "transparent",
                //   borderWidth: "1px",
                //   borderColor: "#FF0000",
                //   borderStyle: "solid",
                //   color: "#FF0000",
                //   borderRadius: "12px",
                //   "&:hover": { color: "#000" },
                // }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                className={classes.modalbox__confirmBtn}
                onClick={BeforeHandler}
                // sx={{
                //   backgroundColor: "#4FFCB7",
                //   color: "#000",
                //   borderRadius: "12px",
                //   "&:hover": { backgroundColor: "#4FFCB7" },
                // }}
              >
                Confirm
              </Button>
            </Stack>
          )}
        </Box>
      ) : (
        <Box className={classes.modalbox}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon
              sx={{ fontSize: 30, marginRight: "5px", color: "#4FFCB7" }}
            />
            <Typography id="modal-modal-title" variant="h6" component="span">
              Artist successfully added!
            </Typography>
          </Box>

          <Grid
            container
            className={classes.modalbox__artistboxx}
            mt={2}
            mb={2}
          >
            <Grid className={classes.modalbox__artistboxx__image}>
              <Avatar
                className={classes.page__list__ul__li__image}
                sx={{ width: "50px", height: "50px" }}
                src={selected?.image}
              />
            </Grid>
            <Grid className={classes.modalbox__artistboxx__title}>
              <Typography variant="h5" component="h2">
                {selected?.name}
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              variant="contained"
              type="button"
              size="large"
              sx={{ width: "100%", backgroundColor: "#4FFCB7" }}
              onClick={() => AfterHandler()}
            >
              Take me to my Artist Page{" "}
              <ArrowForwardIcon sx={{ fontSize: 20, marginLeft: "6px" }} />
            </Button>
          </Stack>
        </Box>
      )}
    </Modal>
  );
};
