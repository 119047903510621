/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { IconButton } from "@mui/material";
import { MdClose } from "react-icons/md";
import Chartapexline from "./dash-line-gragh/dashLineGraph";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { config as URLconfig } from "../../enviorment/enviorment";
import { getItemToLocalStorage } from "../../services/storage";
import axios from "axios";
import { toast } from "react-toastify";
import { TiStarOutline } from "react-icons/ti";
import { countries } from "country-data";
import ReactCountryFlag from "react-country-flag";
import Tooltip from "@mui/material/Tooltip";
import SearchAddArtist from "../../components/search-artist-compare/SearchAddArtist";

const CompareArtists = () => {
  const user = useSelector((state) => state.auth.user);
  const storedToken = getItemToLocalStorage("accessToken");
  const navigate = useNavigate();
  const [spotlightartist, setSpotlightartist] = useState([]);
  const [hoveredStates, setHoveredStates] = useState([]);
  const [refreshData, setRefreshData] = useState(false);

  const [value, setValue] = React.useState("1");
  const [yAxisMaximum] = useState();
  const [artistincomedata, setArtistincomedata] = useState([]);
  const [artistspotifydata, setartistspotifydata] = useState([]);
  const [artistyoutubeydata, setartistyoutubeydata] = useState([]);

  const [chartData, setChartData] = useState([]);

  const handleMouseEnter = (index) => {
    const newHoveredStates = [...hoveredStates];
    newHoveredStates[index] = true;
    setHoveredStates(newHoveredStates);
  };

  useEffect(() => {
    fetchSpotlightartist();
  }, [refreshData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMouseLeave = (index) => {
    const newHoveredStates = [...hoveredStates];
    newHoveredStates[index] = false;
    setHoveredStates(newHoveredStates);
  };

  const options = {
    series: artistincomedata,
    option: {
      chart: {
        height: 455,
        type: "area",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: true,
        },
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      legend: {
        show: true,
        labels: {
          colors: "#FFF",
        },
        position: "top",
        itemMargin: {
          horizontal: 10,
        },
      },
      stroke: {
        width: 5,
        curve: "straight",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#1BAFFB", "#1BFB5E", "#FBEC1B"],
          type: "horizontal",
          stops: [0, 0, 0, 0],
          enabled: true,
          opacityFrom: 0,
          opacityTo: 0,
        },
      },
      xaxis: {
        type: "Monthly",
        categories: chartData,
        tickAmount: 10,
      },
      yaxis: {
        min: 0,
        max: yAxisMaximum,
        showAlways: false,
        labels: {
          formatter: function (value) {
            if (typeof value !== "undefined" && !isNaN(value)) {
              if (value >= 1000000) {
                return "$" + (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000) {
                return "$" + (value / 1000).toFixed(1) + "K";
              } else {
                return "$" + value.toFixed(0);
              }
            } else {
              return "";
            }
          },
        },
      },
      title: {
        style: {
          fontSize: "16px",
          color: "white",
        },
      },
    },
  };

  const optionsspotify = {
    series: artistspotifydata,
    option: {
      chart: {
        height: 455,
        type: "area",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: true,
        },
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      legend: {
        show: true,
        labels: {
          colors: "#FFF",
        },
        position: "top",
        itemMargin: {
          horizontal: 10,
        },
      },
      stroke: {
        width: 5,
        curve: "straight",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#1BAFFB", "#1BFB5E", "#FBEC1B"],
          type: "horizontal",
          stops: [0, 0, 0, 0],
          enabled: true,
          opacityFrom: 0.2,
          opacityTo: 0,
        },
      },
      xaxis: {
        type: "Monthly",
        categories: chartData,
        tickAmount: 10,
      },
      yaxis: {
        min: 0,
        max: yAxisMaximum,
        showAlways: false,
        labels: {
          formatter: function (value) {
            if (typeof value !== "undefined" && !isNaN(value)) {
              if (value >= 1000000) {
                return "" + (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000) {
                return "" + (value / 1000).toFixed(1) + "K";
              } else {
                return "" + value.toFixed(0);
              }
            } else {
              return "";
            }
          },
        },
      },
      title: {
        // text: "",
        // align: "left",
        style: {
          fontSize: "16px",
          color: "white",
        },
      },
    },
  };

  const optionsyoutube = {
    series: artistyoutubeydata,
    option: {
      chart: {
        height: 455,
        type: "area",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: true,
        },
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      legend: {
        show: true,
        labels: {
          colors: "#FFF",
        },
        position: "top",
        itemMargin: {
          horizontal: 10,
        },
      },
      stroke: {
        width: 5,
        curve: "straight",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#1BAFFB", "#1BFB5E", "#FBEC1B"],
          type: "horizontal",
          stops: [0, 0, 0, 0],
          enabled: true,
          opacityFrom: 0.2,
          opacityTo: 0,
        },
      },
      xaxis: {
        type: "Monthly",
        categories: chartData,
        tickAmount: 10,
      },
      yaxis: {
        min: 0,
        max: yAxisMaximum,
        showAlways: false,
        labels: {
          formatter: function (value) {
            if (typeof value !== "undefined" && !isNaN(value)) {
              if (value >= 1000000) {
                return "" + (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000) {
                return "" + (value / 1000).toFixed(1) + "K";
              } else {
                return "" + value.toFixed(0);
              }
            } else {
              return "";
            }
          },
        },
      },
      title: {
        // text: "",
        // align: "left",
        style: {
          fontSize: "16px",
          color: "white",
        },
      },
    },
  };

  const fetchSpotlightartist = () => {
    axios
      .get(`${URLconfig.BASE_URL}/spotlight`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
      .then((response) => {
        fetchSpotlightartistData(
          response.data.data.artistIncome,
          response.data.data.data
        );
        fetchSpotlightartistDataspotify(
          response.data.data.artistIncome,
          response.data.data.data
        );
        fetchSpotlightartistDatayoutube(
          response.data.data.artistIncome,
          response.data.data.data
        );
        setSpotlightartist(response.data.data.data);
      })
      .catch((error) => {
        console.error("Error fetching spotlight data:", error);
      });
  };

  const fetchSpotlightartistData = (artistIncome, data) => {
    console.log("fetchSpotlightartistData", data);
    const series = [];
    data.forEach((artist, index) => {
      const artistName = artist.name;
      var number = 0;
      if (artistIncome[0].length === 0) {
        number = 1;
      }
      const artistdate = artistIncome[number].map((item) => item.date);
      const reversedartistdate = artistdate.reverse();
      setChartData(reversedartistdate);
      const artistIncomeData =
        artistIncome[index].length !== 0
          ? artistIncome[index].map((item) => item.income)
          : [];
      const reversedIncomeData = artistIncomeData.reverse();
      const artistSeries = {
        name: artistName,
        data: reversedIncomeData,
      };
      if (artistSeries.data.length !== 0) {
        series.push(artistSeries);
      }
    });
    setArtistincomedata(series);
  };

  const fetchSpotlightartistDataspotify = (artistIncome, data) => {
    const series = [];
    data.forEach((artist, index) => {
      const artistName = artist.name;
      var number = 0;
      if (artistIncome[0].length === 0) {
        number = 1;
      }
      const artistdate = artistIncome[number].map((item) => item.date);
      const reversedartistdate = artistdate.reverse();
      setChartData(reversedartistdate);
      const artiststreams_totalData = artistIncome[index].map(
        (item) => item.streams_total
      );
      const reversedstreams_totalData = artiststreams_totalData.reverse();
      const artistSeries = {
        name: artistName,
        data: reversedstreams_totalData,
      };
      if (artistSeries.data.length !== 0) {
        series.push(artistSeries);
      }
    });
    setartistspotifydata(series);
  };

  const fetchSpotlightartistDatayoutube = (artistIncome, data) => {
    const series = [];
    data.forEach((artist, index) => {
      const artistName = artist.name;
      var number = 0;
      if (artistIncome[0].length === 0) {
        number = 1;
      }
      const artistdate = artistIncome[number].map((item) => item.date);
      const reversedartistdate = artistdate.reverse();
      setChartData(reversedartistdate);
      const artistvideo_views_totalData = artistIncome[index].map(
        (item) => item.video_views_total
      );
      const reversedvideo_views_totalData =
        artistvideo_views_totalData.reverse();
      const artistSeries = {
        name: artistName,
        data: reversedvideo_views_totalData,
      };
      if (artistSeries.data.length !== 0) {
        series.push(artistSeries);
      }
    });
    setartistyoutubeydata(series);
  };

  const removespotlightartist = (_id) => {
    const body = {
      _id: user.id,
    };
    const headers = {
      Authorization: `Bearer ${storedToken}`,
    };
    axios
      .put(`${URLconfig.BASE_URL}/spotlight/${_id}`, body, { headers: headers })
      .then((response) => {
        toast.success("Spotlight removed");
        fetchSpotlightartist();
      })
      .catch((error) => {
        console.error("Error fetching spotlight data:", error);
      });
  };

  const ClickedArtistAddToSpoptify = (artist) => {
    artist_add_to_spotlight(artist);
  };

  const artist_add_to_spotlight = (artist) => {
    const payload = {
      spotify_id: artist?.spotify_id,
      name: artist?.name,
      email: artist?.email,
      image: artist?.avatar,
      artist_id: artist._id,
      country: artist?.country,
    };
    axios
      .post(`${URLconfig.BASE_URL}/spotlight`, payload, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
      .then((res) => {
        toast.success("Spotlight added");
        setRefreshData((prev) => !prev); // Toggle the state to trigger re-fetch
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <Container maxWidth="xxl" className="graphToolsHide">
      <Grid container spacing={2} className={classess.page}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
          <Box
            component="div"
            variant="div"
            className={classess.chartMainContainer}
          >
            <span className={classess.topHeading}>Artist Deal Tracker</span>

            <Box className={classess.tabContainer}>
              <TabContext value={value}>
                <Box className={classess.tabInnerContainer}>
                  <TabList
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#4FFCB7",
                      },
                    }}
                  >
                    <Tab
                      label="Earnings"
                      value="1"
                      // className={classess.page__tabindicatorcolor}
                      sx={{
                        // opacity: value === "1" ? "1" : "0.5",
                        color: value === "1" ? "#4FFCB7 !important" : "#ffffff",
                      }}
                    />
                    <Tab
                      label="Spotify"
                      value="2"
                      // className={classess.page__tabindicatorcolor}
                      sx={{
                        // opacity: value === "2" ? "1" : "0.5",

                        color: value === "2" ? "#4FFCB7 !important" : "#ffffff",
                      }}
                    />
                    <Tab
                      label="Youtube"
                      value="3"
                      // className={classess.page__tabindicatorcolor}
                      sx={{
                        // opacity: value === "3" ? "1" : "0.5",

                        color: value === "3" ? "#4FFCB7 !important" : "#ffffff",
                      }}
                    />
                  </TabList>
                </Box>

                <TabPanel value="1" sx={{ padding: 0, color: "#fff" }}>
                  <Box
                    component="div"
                    variant="div"
                    className={classess.bg__chart_container}
                    sx={{
                      pt: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Chartapexline
                      options={options.option}
                      series={options.series}
                      height={455}
                      type={"area"}
                      text={"Revenue"}
                    />
                  </Box>
                </TabPanel>

                <TabPanel value="2" sx={{ padding: 0, color: "#fff" }}>
                  <Box
                    component="div"
                    variant="div"
                    className={classess.bg__chart_container}
                    sx={{
                      pt: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Chartapexline
                      options={optionsspotify.option}
                      series={optionsspotify.series}
                      height={455}
                      type={"area"}
                      text={"Streams"}
                    />
                  </Box>
                </TabPanel>

                <TabPanel value="3" sx={{ padding: 0, color: "#fff" }}>
                  <Box
                    component="div"
                    variant="div"
                    className={classess.bg__chart_container}
                    sx={{
                      pt: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Chartapexline
                      options={optionsyoutube.option}
                      series={optionsyoutube.series}
                      height={455}
                      type={"area"}
                      text={"Streams"}
                    />
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={3}>
          <SearchAddArtist
            ClickedArtistAddToSpoptify={ClickedArtistAddToSpoptify}
            artist_add_to_spotlight={artist_add_to_spotlight}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
          <Box className={classess.artistList}>
            <Box className={classess.headAndBtn}>
              <span className={classess.heading}>Artist Spotlight</span>
              <Button
                className={classess.spotBtn}
                onClick={() => navigate("/blig/my-artist")}
                startIcon={<TiStarOutline />}
              >
                Add to Spotlight
              </Button>
            </Box>

            <Box className={classess.allArtist}>
              {spotlightartist.map((item, index) => (
                <Box
                  key={index}
                  className={classess.artist}
                  // onClick={() =>
                  //   fetchSpotlightartistData(item.spotify_id, item.name)
                  // }
                >
                  <Box className={classess.details}>
                    <Box className={classess.artistImg}>
                      <Avatar
                        src={item.image}
                        alt="artist img"
                        className={classess.avatar}
                      />
                      {/* {console.log(item.image)} */}
                      <Box className={classess.onLine} />
                    </Box>
                    <Box>
                      <Tooltip title={item?.name} placement="top">
                        <span
                          className={classess.name}
                          onClick={() =>
                            navigate(`/blig/view-artist/${item.artist_id}`)
                          }
                        >
                          {item?.name ? item.name.split(" ", 2).join(" ") : ""}
                          {/* {item?.name} */}
                        </span>
                      </Tooltip>
                      <Typography className={classess.email}>
                        {item.email
                          ? item.email
                          : item.name.replace(/\s+/g, "").toLowerCase() +
                            "@blacklionapp.xyz"}
                      </Typography>
                      <Box className={classess.coutry_continer}>
                        <Box className={classess.coutry_flag}>
                          {/* {item?.country
                            ? countries[item?.country].emoji
                            : "N/A"} */}
                          <ReactCountryFlag countryCode={item?.country} svg />
                        </Box>
                        <Box className={classess.coutry_name}>
                          {/* {item?.country ? countries[item?.country].name : null} */}
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className={classess.icon_container}>
                    <Box
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      <Box>
                        <IconButton
                          size="small"
                          className={classess.icon}
                          onClick={() => removespotlightartist(item._id)}
                        >
                          <MdClose className={classess.innerIcon} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CompareArtists;
