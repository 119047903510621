import React, { useEffect, useRef, useState, useCallback } from "react";
import { debounce } from "lodash";
import classes from "./style.module.scss";
import { Box, Button, Typography } from "@mui/material";
import { getItemToLocalStorage } from "../../services/storage";
import axios from "axios";
import { config as URLconfig } from "../../enviorment/enviorment";
import NewLoader from "../lottie-loader/Loader.json";
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";
import SearchIcon from "../../assets/searchIcon.svg";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { countries } from "country-data";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CustomTextField } from "../../custom-mui-style/custom-mui-styles";
import { toast } from "react-toastify";

const SearchAddArtist = ({
  ClickedArtistAddToSpoptify,
  artist_add_to_spotlight,
}) => {
  const storedToken = getItemToLocalStorage("accessToken");
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResultArtist, setSearchResultArtist] = useState([]);
  const [loading, setLoading] = useState(false);
  const cancelTokenSource = useRef(null);
  const searchListRef = useRef(null);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = useCallback(async () => {
    const trimmedQuery = searchQuery.trim();
    if (!trimmedQuery) {
      setSearchResultArtist([]);
      setLoading(false);
      return;
    }

    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Cancelled by new request");
    }

    cancelTokenSource.current = axios.CancelToken.source();
    setLoading(true);

    if (trimmedQuery.length >= 3) {
      try {
        const response = await axios.get(
          `${URLconfig.BASE_URL}/artists/search-with-tracks/${trimmedQuery}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
            cancelToken: cancelTokenSource.current.token,
          }
        );
        if (response.status === 200) {
          setSearchResultArtist(response.data?.data?.artists || []);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error("Error:", error);
        }
      } finally {
        setLoading(false);
      }
    }
  }, [searchQuery, storedToken]);

  useEffect(() => {
    const debouncedSearch = debounce(handleSearch, 1000);
    debouncedSearch();

    return () => debouncedSearch.cancel();
  }, [searchQuery, handleSearch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchListRef.current &&
        !searchListRef.current.contains(event.target)
      ) {
        setSearchResultArtist([]);
      }
    };

    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel("Component unmounting");
      }
    };
  }, []);

  return (
    <Box className={classes.page}>
      <Box className={classes.page__topSec} ref={searchListRef}>
        <Box className={classes.page__topSec__header}>
          <Box className={classes.page__topSec__header__heading}>
            Search to compare
          </Box>
        </Box>

        <Box className={classes.page__topSec__searchContainer}>
          <Box className={classes.page__topSec__searchContainer__search}>
            <input
              className={classes.page__topSec__searchContainer__search__input}
              placeholder="Search by Artist Name"
              type="text"
              value={searchQuery}
              onChange={handleInputChange}
            />
            <ReactSVG
              src={SearchIcon}
              className={
                classes.page__topSec__searchContainer__search__input__icon
              }
            />

            {loading && (
              <Box sx={{ width: "25px", height: "25px", marginLeft: "10px" }}>
                <Lottie
                  animationData={NewLoader}
                  loop
                  autoplay
                  className={classes.page__topSec__loader}
                />
              </Box>
            )}
          </Box>
        </Box>

        {/* Artist results list */}
        {searchResultArtist.length > 0 ? (
          <>
            <Box className={classes.page__topSec__result}>
              <Typography className={classes.page__topSec__result__found}>
                {searchResultArtist.length} Artist
                {searchResultArtist.length > 1 ? "s" : ""} Found
              </Typography>
              {searchResultArtist.map((item) => (
                <>
                  <Box
                    key={item._id}
                    className={classes.page__topSec__result__details}
                    // onClick={() => navigate(`/blig/view-artist/${item._id}`)}
                    // onClick={ClickedArtistAddToSpoptify}
                    onClick={() => {
                      ClickedArtistAddToSpoptify(item); // Existing functionality to handle artist addition
                      setSearchQuery(""); // Resets the search input field
                    }}
                  >
                    <LazyLoadImage
                      alt={item.name}
                      src={item.avatar || "path/to/default_avatar.png"} // Use a default avatar if none is provided
                      effect="blur"
                      className={classes.page__topSec__result__details__avatar}
                    />
                    <Box>
                      <Typography
                        className={classes.page__topSec__result__details__name}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        className={
                          classes.page__topSec__result__details__country_flag
                        }
                      >
                        <ReactCountryFlag
                          countryCode={item.country || ""}
                          svg
                        />
                        {countries[item.country?.toUpperCase()]?.name || "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className={classes.page__topSec__result__details__divider}
                  />
                </>
              ))}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              padding: "20px",
            }}
          >
            {searchQuery.length <= 0 || searchResultArtist.length > 0 ? null : (
              <Box
                className={classes.page__topSec__noArtists}
                sx={{
                  width: "100%",
                  padding: "20px",
                  height: "100%",
                }}
              >
                <Typography
                  className={classes.page__topSec__noArtists__no_result}
                >
                  No results
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate("/blig/add-artist")}
                  className={classes.page__topSec__noArtists__button}
                >
                  Add Artist
                </Button>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "12px",
                    textAlign: "center",
                    mt: 0.5,
                  }}
                >
                  Can’t find what you’re looking for?
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SearchAddArtist;
