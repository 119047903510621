/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import { CustomSliderWithStyles } from "../../../../custom-mui-style/custom-mui-styles";
import {
  setIsLoading,
  setIsLoadingNew,
  // setLicenceType,
  setMultiple,
} from "../../../../redux/slice/artist";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Typography from "@mui/material/Typography";
// import WalletImg from "../../../../assets/wallet.png";
import WalletImg from "../../../../assets/walletNew.svg";
// import WalletImg from "../../../../assets/walletPng.png";
// import { LiaSaveSolid } from "react-icons/lia";
// import { AiOutlineCloudDownload } from "react-icons/ai";
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  // Button,
  // FormControl,
  // FormControlLabel,
  IconButton,
  // Radio,
  // RadioGroup,
  Tooltip,
} from "@mui/material";
// import Stack from "@mui/material/Stack";
// import Button from "@mui/material/Button";
import Chartapexline from "./chartapexline";
// import { addCommasToNumber } from "../../../../utils/helper";
import { ReactSVG } from "react-svg";
import GaugeChart from "react-gauge-chart";

const CustomizedConfig = ({
  set_contract_length,
  set_catelog_income,
  included_music,
  contract_length,
  catelog_income,
  new_music_income,
  funding_metadata,
  set_funding_metadata,
  updateArtistFunding,
  calcalute_tracks_estimate,
  calcalute_Year_income_by_tracks,
  // new_music_estimiate,
  monthlyIncome,
  totalFunding,
  artist_advance,
  marketing_budget,
  set_artist_advance,
  set_marketing_budget,
  internationalNumberFormat,
  setChangeButton,
  // onClick,
  // isBorderChanged,
  // artAdvance,
  // setArtAdvance,
  // marketBudget,
  // setMarketBudget,
  // downloadPDF,
  // isPending,
  setRECOUPMENTPERIOD,
  setRECOUPMENTPERIOD_IN_ENG,
  tabValue,
}) => {
  const showSelectedTracksFunding = useSelector(
    (state) => state.artist.showSelectedTracksFunding
  );
  const [avargeSixMonthRevenue, setAvgSixMonthRevenue] = useState(0);
  const multiple = useSelector((state) => state.artist.multiple);
  const licenceType = useSelector((state) => state.artist.licenseType);
  const dispatch = useDispatch();
  const tracks = useSelector((state) => state.artist.tracks);
  const selected = useSelector((state) => state.artist.selectedTracks);
  const newMusicTracks = useSelector((state) => state.artist.newMusicTracks);
  const authUser = useSelector((state) => state.auth.user);
  const [timeoutId, setTimeoutId] = useState(null); // Declare 'timeoutId' using useState
  const [errorMessageArtist, setErrorMessageArtist] = useState("");
  const [errorMessageMarketing, setErrorMessageMarketing] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isInputFocused2, setIsInputFocused2] = useState(false);
  const dspQuality = ((funding_metadata?.dsp_rate - 0.0005) / 0.007) * 100;
  const mrcQuality =
    ((funding_metadata?.missing_reports_compensation - 0) / 2.75) * 100;
  // const isLoading = useSelector((state) => state.artist.isLoading);
  const addCommas = (num) =>
    num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num?.toString()?.replace(/[^0-9]/g, "");

  // const [change_new_music_estimiate, set_change_new_music_estimiate] =
  //   useState(0);
  // const [change_marketing_budget, set_change_marketing_budget] = useState(0);
  // const [change_artist_advance, set_change_artist_advance] = useState(0);
  const [
    total_of_Advance_and_Marketing_budget,
    setTotal_of_Advance_and_Marketing_budget,
  ] = useState(0);
  const [Recoupment_Period_months, setRecoupment_Period_months] = useState(0);
  const [
    Recoupment_Period_months_in_English,
    setRecoupment_Period_months_in_English,
  ] = useState(0);
  // const inputRef = useRef(null);
  // const timeoutRef = useRef(null);
  function getSingleTrack(id) {
    return tracks.filter((track) => track.id === id)[0];
  }

  // const handleRadioChange = (event) => {
  //   dispatch(setLicenceType(event.target.value));
  // };

  //artist Advanced
  const new_music_estimiate_given_market_budget = (value) => {
    if (value === 0) {
      set_artist_advance(0);
      calculate_Recoupment_income_And_period(0);
    } else {
      const artist_advance_payment = value;
      set_artist_advance(artist_advance_payment);

      const Marketing_budget_payment = marketing_budget;
      setTotal_of_Advance_and_Marketing_budget(
        Math.round(
          parseInt(artist_advance_payment) +
            parseInt(Marketing_budget_payment) || 0
        )
      );

      // console.log("sum_of_last_six_month_income", lastsixmonthincome);
      // calculate_Recoupment_income_And_period(sum_of_last_six_month_income);
    }
  };

  // const new_music_estimiate_given_market_budget = (value) => {
  //   if (value === 0) {
  //     set_artist_advance(0);
  //     calculate_Recoupment_income_And_period(0);
  //   } else {
  //     const artist_advance_payment =
  //       ((new_music_estimiate + totalFunding) * value) / 100;
  //     set_artist_advance(artist_advance_payment);
  //     const Marketing_budget_payment =
  //       ((new_music_estimiate + totalFunding) * change_marketing_budget) / 100;
  //     setTotal_of_Advance_and_Marketing_budget(
  //       Math.round(artist_advance_payment + Marketing_budget_payment)
  //     );
  //     const dataByMonthArray = Object.entries(monthlyIncome).map(
  //       ([key, value]) => ({
  //         date: key,
  //         income: value.income,
  //       })
  //     );
  //     const sortedData = dataByMonthArray.sort(
  //       (a, b) => new Date(a.date) - new Date(b.date)
  //     );
  //     const monthsArray = sortedData.map((data) => data?.date);
  //     const Monthlyincome = sortedData?.map((data) => data?.income);
  //     const lastsixmonthincome = Monthlyincome.slice(-7, -1);
  //     const sum_of_last_six_month_income = lastsixmonthincome.reduce(
  //       (accumulator, currentValue) => accumulator + currentValue,
  //       0
  //     );
  //     console.log("sum_of_last_six_month_income", lastsixmonthincome);
  //     calculate_Recoupment_income_And_period(sum_of_last_six_month_income);
  //   }
  // };

  //Marketing Budget
  const calculate_marketing_budget = (value) => {
    if (value === 0) {
      set_marketing_budget(0);
      calculate_Recoupment_income_And_period(0);
    } else {
      // const Percentage = (value/ (new_music_estimiate + totalFunding)) * 100
      const cal_marketing_budget = value;
      // ((new_music_estimiate + totalFunding) * value) / 100;
      set_marketing_budget(cal_marketing_budget);
      setTotal_of_Advance_and_Marketing_budget(
        Math.round(parseInt(cal_marketing_budget) + parseInt(artist_advance)) ||
          0
      );
      // const dataByMonthArray = Object.entries(monthlyIncome).map(
      //   ([key, value]) => ({
      //     date: key,
      //     income: value.income,
      //   })
      // );
      // const sortedData = dataByMonthArray.sort(
      //   (a, b) => new Date(a.date) - new Date(b.date)
      // );
      // const monthsArray = sortedData.map((data) => data?.date);
      // const Monthlyincome = sortedData?.map((data) => data?.income);
      // const lastsixmonthincome = Monthlyincome.slice(-7, -1);
      // const sum_of_last_six_month_income = lastsixmonthincome.reduce(
      //   (accumulator, currentValue) => accumulator + currentValue,
      //   0
      // );
      // console.log("sum_of_last_six_month_income", lastsixmonthincome);
      // calculate_Recoupment_income_And_period(sum_of_last_six_month_income);
    }
  };
  const [chartData, setChartData] = useState({
    labels: [
      "2022-08-31",
      "2022-09-01",
      "2022-09-02",
      "2022-09-03",
      "2022-09-04",
      "2022-09-05",
      "2022-09-06",
      "2022-09-07",
      "2022-09-08",
      "2022-09-09",
      "2022-09-10",
      "2022-09-11",
      "2022-09-12",
      "2022-09-13",
      "2022-09-14",
      "2022-09-15",
      "2022-09-16",
      "2022-09-17",
      "2022-09-18",
      "2022-09-19",
      "2022-09-20",
      "2022-09-21",
      "2022-09-22",
      "2022-09-23",
      "2022-09-24",
      "2022-09-25",
      "2022-09-26",
      "2022-09-27",
      "2022-09-28",
      "2022-09-29",
      "2022-09-30",
      "2022-10-01",
      "2022-10-02",
      "2022-10-03",
      "2022-10-04",
      "2022-10-05",
      "2022-10-06",
      "2022-10-07",
      "2022-10-08",
      "2022-10-09",
      "2022-10-10",
      "2022-10-11",
      "2022-10-12",
      "2022-10-13",
      "2022-10-14",
      "2022-10-15",
      "2022-10-16",
      "2022-10-17",
      "2022-10-18",
      "2022-10-19",
      "2022-10-20",
      "2022-10-21",
      "2022-10-22",
      "2022-10-23",
      "2022-10-24",
      "2022-10-25",
      "2022-10-26",
      "2022-10-27",
      "2022-10-28",
      "2022-10-29",
      "2022-10-30",
    ],
    datasets: [
      {
        label: "Spotify",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        backgroundColor: "rgb(60, 179, 113)",
        borderColor: "rgb(60, 179, 113)",
        borderWidth: 2,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
      },
    ],
  });
  useEffect(() => {
    if (total_of_Advance_and_Marketing_budget > 0) {
      calculate_Recoupment_income_And_period();
    } else {
      // console.log("error..");
    }
  }, [total_of_Advance_and_Marketing_budget]);
  const calculate_Recoupment_income_And_period = (Value) => {
    const dataByMonthArray = Object.entries(monthlyIncome).map(
      ([key, value]) => ({
        date: key,
        income: value.income,
      })
    );
    const sortedData = dataByMonthArray.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    // const monthsArray = sortedData.map((data) => data?.date);
    const Monthlyincome = sortedData?.map((data) => data?.income);
    // const removeCurrentMonth = Monthlyincome.slice(0, -1);
    const lastsixmonthincome = Monthlyincome.slice(-7, -1);
    const sum_of_last_six_month_income = lastsixmonthincome.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    const avgSixMonthRevenue = sum_of_last_six_month_income / 6;

    setAvgSixMonthRevenue(avgSixMonthRevenue);

    const no_of_month =
      total_of_Advance_and_Marketing_budget / avgSixMonthRevenue;
    // console.log("Value", Value);
    // console.log("(AVG Monthly Revenue)", avgSixMonthRevenue);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    let decimalNumber = no_of_month + currentMonth;
    // console.log("decimalNumber", decimalNumber);
    if (decimalNumber >= 12 && decimalNumber <= 12.5) {
      decimalNumber = 11;
    } else {
      decimalNumber = no_of_month + currentMonth;
    }
    // console.log("decimalNumber", decimalNumber);
    const referenceYear = 2023; // Provide your reference year here
    // let RecoupmentIncome = [];
    const year = Math.round(decimalNumber / 12) + referenceYear;
    const month = decimalNumber % 12;
    setRecoupment_Period_months(Math.round(no_of_month));
    // console.log("year", Math.floor(decimalNumber / 12));
    const date = new Date(`${year}-${Math.round(month)}-01`);
    const monthName = date.toLocaleString("en-US", { month: "short" });
    setRecoupment_Period_months_in_English(`${year}-${monthName}`);
    setRECOUPMENTPERIOD(Math.round(no_of_month));
    // console.log("no_of_month", Math.round(no_of_month), date);
    setRECOUPMENTPERIOD_IN_ENG(`${year}-${monthName}`);
    // console.log("monthName", `${year}-${monthName}`);
  };
  useEffect(() => {
    const dataByMonthArray = Object.entries(monthlyIncome).map(
      ([key, value]) => ({
        date: key,
        income: value.income,
      })
    );
    const sortedData = dataByMonthArray.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    const monthsArray = sortedData.map((data) => data?.date);
    const Monthlyincome = sortedData?.map((data) => data?.income);
    const removeCurrentMonth = Monthlyincome.slice(0, -1);
    const lastsixmonthincome = Monthlyincome.slice(-7, -1);
    const sum_of_last_six_month_income = lastsixmonthincome.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    // console.log("sum_of_last_six_month_income", lastsixmonthincome);
    calculate_Recoupment_income_And_period(sum_of_last_six_month_income);
    const monthNames = monthsArray.map((monthNumber) => {
      const [year, month] = monthNumber.split("-");
      const date = new Date(`${year}-${month}-01`);
      const monthName = date.toLocaleString("en-US", { month: "short" });
      return `${year}-${monthName}`;
    });
    // monthNames.push(`${year}-${monthName}`)
    // RecoupmentIncome[12]=(Monthlyincome[12]);
    // RecoupmentIncome[13]=total_of_Advance_and_Marketing_budget;
    let data = {
      labels: monthNames,
      datasets: [
        {
          label: "Monthly Income $",
          backgroundColor: "rgb(60, 179, 113)",
          borderColor: "rgb(60, 179, 113)",
          borderWidth: 2,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: removeCurrentMonth,
        },
        // {
        //   label: "Recoupment Income $",
        //   backgroundColor: " rgb(255,0,0)",
        //   borderColor: "rgb(255,0,0)",
        //   borderWidth: 2,
        //   hoverBackgroundColor: "rgba(255,99,132,0.4)",
        //   hoverBorderColor: "rgba(255,99,132,1)",
        //   data: RecoupmentIncome,
        // },
      ],
    };
    setChartData(data);
  }, [monthlyIncome]);
  const ariaLabel = { "aria-label": "description" };

  // const handleContractLengthChange = (event) => {
  //   const value = parseInt(event.target.value);
  //   if (!isNaN(value) && value >= 1 && value <= 12) {
  //     set_contract_length(value);
  //     dispatch(setMultiple(value));
  //     dispatch(setIsLoading(true));
  //     let selected_tracks =
  //       selected.length > 0 ? selected.map((e) => getSingleTrack(e)) : tracks;
  //     const new_music = newMusicTracks.map((e) => getSingleTrack(e));

  //     let val = {
  //       included_music,
  //       contract_length: value,
  //       catelog_income,
  //       new_music_income,
  //       selected_tracks,
  //       new_music_tracks: new_music,
  //     };

  //     calcalute_tracks_estimate(val);
  //   } else if (event.target.value === "") {
  //     set_contract_length(1);
  //   }
  // };

  // const handleRecoupmentRate = (event) => {
  //   const value = parseInt(event.target.value);
  //   if (!isNaN(value) && value >= 1 && value <= 100) {
  //     set_catelog_income(value);

  //     dispatch(setIsLoading(true));

  //     let selected_tracks =
  //       selected.length > 0 ? selected.map((e) => getSingleTrack(e)) : tracks;

  //     const new_music = newMusicTracks.map((e) => getSingleTrack(e));

  //     let val = {
  //       included_music,
  //       contract_length,
  //       catelog_income: value,
  //       new_music_income,
  //       selected_tracks,
  //       new_music_tracks: new_music,
  //     };

  //     calcalute_tracks_estimate(val);
  //   } else if (event.target.value === "") {
  //     set_catelog_income("");
  //     setChangeButton("");
  //   }
  // };
  // console.log("Chart Dataset", chartData.datasets[0].data);
  const dataValues = chartData.datasets[0].data;
  const maxDataValue = Math.max(...dataValues);
  const yAxisMax = maxDataValue + maxDataValue * 0.1;
  // const [data, setSeries] = useState([chartData.datasets[0].data]);
  // console.log("y = ", yAxisMax);
  const options = {
    series: [
      {
        name: "Income",
        data: dataValues,
      },
    ],

    option: {
      chart: {
        id: "chart",
        height: 550,
        type: "line",
        foreColor: "#ccc",
      },
      toolbar: {
        autoSelected: "pan",
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
      colors: ["var(--chart-lines-colors)"],
      stroke: {
        width: 5,
        curve: "straight",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["var(--chart-lines-colors)"],
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
        },
      },
      // markers: {
      //   size: 5,
      //   colors: ["#192233"],
      //   strokeColor: "#ff3d81",
      //   strokeWidth: 3,
      // },
      xaxis: {
        type: "Monthly",
        categories: chartData.labels,
        tickAmount: 10,
      },
      yaxis: {
        min: 0,
        // tickAmount: 50,
        max: yAxisMax,
        showAlways: false,
        labels: {
          formatter: function (value) {
            if (typeof value !== "undefined" && !isNaN(value)) {
              if (value >= 1000000) {
                return "$" + (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000) {
                return "$" + (value / 1000).toFixed(1) + "K";
              } else {
                return "$" + value.toFixed(0);
              }
            } else {
              return "";
            }
          },
        },
      },
    },
  };

  const handleContractLengthChange = (newValue) => {
    newValue = parseInt(newValue);
    if (!isNaN(newValue) && newValue >= 1 && newValue <= 12) {
      set_contract_length(newValue);
      dispatch(setMultiple(newValue));
      // Other state updates and API call cancellation logic
      clearTimeout(timeoutId);
      const delay = 1000; // Move 'delay' declaration here
      const newTimeoutId = setTimeout(() => {
        dispatch(setIsLoading(true));
        dispatch(setIsLoadingNew(true));
        let selected_tracks =
          selected.length > 0 ? selected.map((e) => getSingleTrack(e)) : tracks;
        const new_music = newMusicTracks.map((e) => getSingleTrack(e));
        let val = {
          included_music,
          contract_length: newValue,
          catelog_income,
          new_music_income,
          selected_tracks,
          new_music_tracks: new_music,
        };

        calcalute_tracks_estimate(val);
      }, delay);
      setTimeoutId(newTimeoutId);
    }
  };

  const handleIncrementYrs = () => {
    const newValue = Math.min(contract_length + 1, 12);
    handleContractLengthChange(newValue);
  };

  const handleDecrementYrs = () => {
    const newValue = Math.max(contract_length - 1, 1);
    handleContractLengthChange(newValue);
  };

  const handleInputChangeYrs = (e) => {
    handleContractLengthChange(e.target.value);
  };

  const handleRecoupmentRate = (newValue) => {
    newValue = parseInt(newValue);
    if (!isNaN(newValue) && newValue >= 1 && newValue <= 100) {
      set_catelog_income(newValue);

      // Other state updates and API call cancellation logic
      clearTimeout(timeoutId);
      const delay = 1000; // Move 'delay' declaration here
      const newTimeoutId = setTimeout(() => {
        dispatch(setIsLoading(true));
        dispatch(setIsLoadingNew(true));

        let selected_tracks =
          selected.length > 0 ? selected.map((e) => getSingleTrack(e)) : tracks;

        const new_music = newMusicTracks.map((e) => getSingleTrack(e));

        let val = {
          included_music,
          contract_length,
          catelog_income: newValue,
          new_music_income,
          selected_tracks,
          new_music_tracks: new_music,
        };

        calcalute_tracks_estimate(val);
      }, delay);
      setTimeoutId(newTimeoutId);
    }
  };

  const handleIncrementRec = () => {
    const newValue = Math.min(catelog_income + 1, 100);
    handleRecoupmentRate(newValue);
  };

  const handleDecrementRec = () => {
    const newValue = Math.max(catelog_income - 1, 1);
    handleRecoupmentRate(newValue);
  };
  const handleRecoupmentRateMain = (e) => {
    const value = e.target.value.replace("%", ""); // Remove percentage sign
    handleRecoupmentRate(value);
  };
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);
  return (
    <React.Fragment>
      <Box varient="div" component="div" className={classess.page}>
        {tabValue === 1 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: {
                  sm: "wrap",
                },
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                    mt: -2,
                  }}
                >
                  <Box varient="div" component="div" mt={2}>
                    <Box varient="div" component="div">
                      <span className={classess.page__title}>
                        Length of Contract
                      </span>
                      <Box
                        sx={{
                          "& > :not(style)": { m: 1, ml: 0 },
                          mt: 1,
                        }}
                        className={classess.page__mainInputField}
                      >
                        <Box
                          className={
                            classess.page__mainInputField__inputContainer
                          }
                        >
                          <IconButton
                            onClick={handleDecrementYrs}
                            disabled={contract_length <= 1}
                            style={{
                              opacity: contract_length <= 1 ? 0.5 : 1,
                            }}
                            className={
                              classess.page__mainInputField__inputContainer__action_btn
                            }
                          >
                            -
                          </IconButton>
                          <input
                            value={contract_length}
                            onChange={handleInputChangeYrs}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            className={
                              classess.page__mainInputField__inputContainer__inputFileds
                            }
                          />

                          <IconButton
                            onClick={handleIncrementYrs}
                            disabled={contract_length >= 12}
                            style={{
                              opacity: contract_length >= 12 ? 0.5 : 1,
                            }}
                            className={
                              classess.page__mainInputField__inputContainer__action_btn
                            }
                          >
                            +
                          </IconButton>
                        </Box>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "10px",
                        }}
                      >
                        Input should be 1 to 12
                      </Typography>
                    </Box>
                  </Box>

                  <Box varient="div" component="div" mt={2}>
                    <Box varient="div" component="div">
                      <span className={classess.page__title}>ARTIST SHARE</span>
                      <Box
                        sx={{
                          "& > :not(style)": { m: 1, ml: 0 },
                          mt: 1,
                        }}
                        className={classess.page__mainInputField}
                      >
                        <Box
                          className={
                            classess.page__mainInputField__inputContainer
                          }
                        >
                          <IconButton
                            onClick={handleDecrementRec}
                            disabled={catelog_income <= 1}
                            style={{ opacity: catelog_income <= 1 ? 0.5 : 1 }}
                            className={
                              classess.page__mainInputField__inputContainer__action_btn
                            }
                          >
                            -
                          </IconButton>
                          <input
                            value={catelog_income + "%"}
                            onChange={handleRecoupmentRateMain}
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            className={
                              classess.page__mainInputField__inputContainer__inputFileds
                            }
                          />

                          <IconButton
                            onClick={handleIncrementRec}
                            disabled={catelog_income >= 100}
                            style={{
                              opacity: catelog_income >= 100 ? 0.5 : 1,
                            }}
                            className={
                              classess.page__mainInputField__inputContainer__action_btn
                            }
                          >
                            +
                          </IconButton>
                        </Box>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "10px",
                        }}
                      >
                        Input should be 1 to 100
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    flexWrap: "wrap",
                    gap: "30px",
                    mt: 1,
                  }}
                >
                  <Box varient="div" component="div" mt={2}>
                    <Box varient="div" component="div">
                      <span className={classess.page__title}>
                        Artist's Advance
                      </span>
                      <Box
                        sx={{
                          "& > :not(style)": { m: 1, ml: 0 },
                        }}
                        className={classess.page__mainInputField}
                      >
                        <Box
                          className={
                            classess.page__mainInputField__inputContainer
                          }
                        >
                          <span
                            className={`${classess.page__mainInputField__inputContainer__dollar} 
                        `}
                            style={{
                              color: isInputFocused ? "#fff" : "#6d7480",
                              transition: "all 0.3s ease-in-out",
                            }}
                          >
                            $
                          </span>
                          <input
                            type="text"
                            className={`${classess.page__mainInputField__inputContainer__inputFileds} ${classess.page__mainInputField__inputContainer__dollarField}`}
                            value={addCommas(artist_advance)}
                            placeholder={"0"}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={(e) => {
                              const newValue = removeNonNumeric(e.target.value);
                              if (newValue < 0) {
                                // Handle negative values if needed
                              } else if (newValue > totalFunding) {
                                setErrorMessageArtist(
                                  `Value cannot exceed ${addCommas(
                                    totalFunding
                                  )}`
                                );
                              } else {
                                set_artist_advance(newValue);
                                setChangeButton(newValue);
                                new_music_estimiate_given_market_budget(
                                  newValue
                                );
                                setErrorMessageArtist("");
                              }
                            }}
                            style={{
                              textAlign: "left",
                              width: "200px",
                            }}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {errorMessageArtist && (
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: "#E12020",
                          position: "absolute",
                        }}
                      >
                        {errorMessageArtist}
                      </Typography>
                    )}
                  </Box>
                  <Box varient="div" component="div" mt={2}>
                    <Box varient="div" component="div">
                      <span className={classess.page__title}>
                        Marketing Budget
                      </span>
                      <Box
                        sx={{
                          "& > :not(style)": { m: 1, ml: 0 },
                        }}
                        className={classess.page__mainInputField}
                      >
                        <Box
                          className={
                            classess.page__mainInputField__inputContainer
                          }
                        >
                          <span
                            className={
                              classess.page__mainInputField__inputContainer__dollar
                            }
                            style={{
                              color: isInputFocused2 ? "white" : "#6d7480",
                            }}
                          >
                            $
                          </span>

                          <input
                            value={addCommas(marketing_budget)}
                            type="text"
                            placeholder={"0"}
                            onChange={(e) => {
                              const newValue = removeNonNumeric(e.target.value);
                              if (newValue < 0) {
                              } else if (newValue > totalFunding) {
                                setErrorMessageMarketing(
                                  `Value cannot exceed ${addCommas(
                                    totalFunding
                                  )}`
                                );
                              } else {
                                set_marketing_budget(newValue);
                                setChangeButton(newValue);
                                calculate_marketing_budget(newValue);
                                setErrorMessageMarketing("");
                              }
                            }}
                            inputProps={ariaLabel}
                            className={`${classess.page__mainInputField__inputContainer__inputFileds} ${classess.page__mainInputField__inputContainer__dollarField}`}
                            style={{
                              textAlign: "left",
                              width: "200px",
                            }}
                            onFocus={() => setIsInputFocused2(true)}
                            onBlur={() => setIsInputFocused2(false)}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {errorMessageMarketing && (
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: "#E12020",
                          position: "absolute",
                        }}
                      >
                        {errorMessageMarketing}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box mt={-2}>
                  {authUser.role === "admin" && (
                    <Box varient="div" component="div">
                      <Box
                        sx={{
                          display: "flex",
                          alignContent: "center",
                          gap: "30px",
                          mt: 5,
                        }}
                      >
                        <Box varient="div" component="div" mt={2}>
                          <Box varient="div" component="div">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "5px",
                              }}
                            >
                              <span className={classess.page__title}>
                                DSP Quality{" "}
                              </span>
                              <span className={classess.page__title}>
                                {dspQuality < 25 && <span>Low Economy</span>}
                                {dspQuality > 25 && dspQuality <= 50 && (
                                  <span>Economy</span>
                                )}
                                {dspQuality > 50 && dspQuality <= 75 && (
                                  <span>Low Premium</span>
                                )}
                                {dspQuality > 75 && <span>Premium</span>}
                              </span>
                            </div>

                            <Box
                              mt={5}
                              sx={{ display: "flex", width: "200px" }}
                            >
                              <GaugeChart
                                id="gauge-chart3"
                                animate={false}
                                nrOfLevels={4}
                                colors={[
                                  "#0099e5",
                                  "#0099e5",
                                  "#da1884",
                                  "#da1884",
                                ]}
                                arcWidth={0.1}
                                percent={dspQuality / 100}
                                textColor={"white"}
                                needleBaseColor="white"
                                hideText={true}
                              />
                            </Box>

                            <div
                              style={{
                                width: "150px",
                                margin: "0 auto",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: 10,
                                }}
                              >
                                {/* <button
                                  style={{
                                    backgroundColor: "#ff3d81",
                                    color: "black",
                                    borderRadius: "100%",
                                    border: "0px",
                                    padding: "7px 12px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => {
                                    if (funding_metadata.dsp_rate >= 0.00175) {
                                      set_funding_metadata((prevState) => ({
                                        ...prevState,
                                        dsp_rate:
                                          funding_metadata.dsp_rate - 0.00175,
                                      }));
                                    }

                                    updateArtistFunding();
                                  }}
                                >
                                  -
                                </button> */}

                                {/* <button
                                  style={{
                                    backgroundColor: "#ff3d81",
                                    color: "black",
                                    borderRadius: "100%",
                                    border: "0px",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => {
                                    if (funding_metadata.dsp_rate <= 0.007) {
                                      set_funding_metadata((prevState) => ({
                                        ...prevState,
                                        dsp_rate:
                                          funding_metadata.dsp_rate + 0.00175,
                                      }));
                                    }
                                    updateArtistFunding();
                                  }}
                                >
                                  +
                                </button> */}
                              </Box>
                            </div>
                          </Box>
                        </Box>

                        <Box varient="div" component="div" mt={2}>
                          <Box varient="div" component="div">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <span className={classess.page__title}>
                                  Monthly Revenue
                                </span>
                              </div>
                            </div>

                            <Box mt={5} sx={{ width: "200px" }}>
                              <GaugeChart
                                animate={false}
                                id="gauge-chart3"
                                nrOfLevels={4}
                                colors={[
                                  "#0099e5",
                                  "#0099e5",
                                  "#da1884",
                                  "#da1884",
                                ]}
                                arcWidth={0.1}
                                percent={mrcQuality / 100}
                                textColor={"white"}
                                needleBaseColor="white"
                                hideText={true}
                              />
                            </Box>

                            <Box
                              sx={{
                                "& > :not(style)": { m: 1, ml: 0 },
                                mt: -2.5,
                              }}
                              className={classess.page__mainInputField}
                            >
                              <Box
                                className={
                                  classess.page__mainInputField__inputContainer
                                }
                                // pb={1}
                              >
                                <IconButton
                                  className={
                                    classess.page__mainInputField__inputContainer__action_btn
                                  }
                                  onClick={() => {
                                    if (
                                      funding_metadata.missing_reports_compensation >
                                      0
                                    ) {
                                      set_funding_metadata((prevState) => ({
                                        ...prevState,
                                        missing_reports_compensation:
                                          funding_metadata.missing_reports_compensation -
                                          0.25,
                                      }));

                                      updateArtistFunding();

                                      const selected_tracks =
                                        selected.length > 0
                                          ? selected.map((e) =>
                                              getSingleTrack(e)
                                            )
                                          : showSelectedTracksFunding
                                          ? tracks
                                              .filter(
                                                (track) =>
                                                  track.is_selected === 1
                                              )
                                              .map(
                                                (checkedtracks) => checkedtracks
                                              )
                                          : tracks;
                                      const ids = selected_tracks.map(
                                        (item) => item.id
                                      );

                                      const data_for_year = {
                                        tracksId: ids,
                                      };

                                      calcalute_Year_income_by_tracks(
                                        data_for_year
                                      );
                                    }
                                  }}
                                >
                                  -
                                </IconButton>
                                <input
                                  value={
                                    "$" +
                                    Math.round(
                                      avargeSixMonthRevenue
                                    ).toLocaleString("en-US")
                                  }
                                  className={
                                    classess.page__mainInputField__inputContainer__inputFileds
                                  }
                                  disabled
                                />

                                <IconButton
                                  className={
                                    classess.page__mainInputField__inputContainer__action_btn
                                  }
                                  onClick={() => {
                                    if (
                                      funding_metadata.missing_reports_compensation <
                                      2.75
                                    ) {
                                      set_funding_metadata((prevState) => ({
                                        ...prevState,
                                        missing_reports_compensation:
                                          funding_metadata.missing_reports_compensation +
                                          0.25,
                                      }));

                                      updateArtistFunding();

                                      const selected_tracks =
                                        selected.length > 0
                                          ? selected.map((e) =>
                                              getSingleTrack(e)
                                            )
                                          : showSelectedTracksFunding
                                          ? tracks
                                              .filter(
                                                (track) =>
                                                  track.is_selected === 1
                                              )
                                              .map(
                                                (checkedtracks) => checkedtracks
                                              )
                                          : tracks;
                                      const ids = selected_tracks.map(
                                        (item) => item.id
                                      );

                                      const data_for_year = {
                                        tracksId: ids,
                                      };
                                      calcalute_Year_income_by_tracks(
                                        data_for_year
                                      );
                                    }
                                  }}
                                >
                                  +
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box className={classess.page__totalSpend}>
                <Box className={classess.page__totalSpend__walletImg}>
                  <ReactSVG src={WalletImg} />
                  <Box
                    className={
                      classess.page__totalSpend__walletImg__walletContent
                    }
                  >
                    <Box>
                      <span className={classess.page__title}>TOTAL SPEND</span>
                      <Box
                        sx={{
                          "& > :not(style)": { m: 1, ml: 0 },
                        }}
                      >
                        <Typography
                          className={
                            classess.page__totalSpend__walletImg__walletContent__text
                          }
                        >
                          ${" "}
                          {internationalNumberFormat.format(
                            total_of_Advance_and_Marketing_budget
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <span className={classess.page__title}>
                        RECOUPMENT PERIOD
                      </span>
                      <Box
                        sx={{
                          "& > :not(style)": { m: 1, ml: 0 },
                        }}
                      >
                        <Typography
                          className={
                            classess.page__totalSpend__walletImg__walletContent__text
                          }
                        >
                          {Recoupment_Period_months +
                            " Months ~ " +
                            Recoupment_Period_months_in_English}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}

        {tabValue === 2 && (
          <Box varient="div" component="div">
            <Box varient="div" component="div" className={classess.page}>
              <span className={classess.page__title}>Multiple</span>
              <Box varient="div" component="div" display={"flex"} gap={"10px"}>
                <CustomSliderWithStyles
                  defaultValue={3}
                  min={1}
                  max={30}
                  value={multiple}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  onChange={(e) => dispatch(setMultiple(e.target.value))}
                  onChangeCommitted={async (e, v) => {
                    dispatch(setMultiple(v));
                    dispatch(setIsLoading(true));
                    dispatch(setIsLoadingNew(true));

                    let selected_tracks =
                      selected.length > 0
                        ? selected.map((e) => getSingleTrack(e))
                        : tracks;

                    // await Promise.all(
                    //   selected.map((y) => {
                    //     tracks
                    //       .filter((track) => track.id === y)
                    //       .map((e) => {
                    //         selected_tracks.push(e);
                    //       });
                    //   })
                    // );

                    const new_music = newMusicTracks.map((e) =>
                      getSingleTrack(e)
                    );

                    let val = {
                      included_music,
                      contract_length: 1,
                      catelog_income,
                      new_music_income: 100,
                      selected_tracks,
                      multiple: v,
                      new_music_tracks: new_music,
                    };
                    // set_contract_length(1);
                    calcalute_tracks_estimate(val);
                  }}
                />
                <span component="div" className={classess.page__slider__text}>
                  {multiple}x
                </span>
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={{ mt: 3 }}>
          <Box>
            <Typography className={classess.page__recoupHeading}>
              Earnings
            </Typography>
          </Box>
          <Chartapexline
            options={options.option}
            series={options.series}
            height={500}
            type={"line"}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CustomizedConfig;
