// React Imports
import React, { useState } from "react";

// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/slice/modal";

// MUI Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";

// Custom MUI Imports
import {
  CustomSelect,
  CustomTextField,
} from "../../custom-mui-style/custom-mui-styles";

// Other Imports
import axios from "axios";
import { toast } from "react-toastify";
import { config as URLconfig } from "../../enviorment/enviorment";
import classess from "./style.module.scss";
import CheckoutForm from "./checkoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export default function MakeaPaymentModal({ payment, spotify_id }) {
  // Redux States
  const isOpen = useSelector((state) => state.modal.isOpen);

  // Component States
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentpayble, setpaymentpayble] = useState("");
  const [setbtndisabled] = useState(true);
  const [paymentAmount, setpaymentAmount] = useState(0);
  const [prePayment, setPrePayment] = useState(0);
  const [error, setError] = useState("");
  const [clientSecret, setclientSecret] = useState(null);

  // Dispatch
  const dispatch = useDispatch();

  // Stripe Setup
  const stripePromise = loadStripe(
    "pk_test_51MvNEdDbpxbuXxuQVusA2Qxyk9N5GB6SLwoia3NzmMohYGIMAyLUMx68KqmwaVYRSa0TIZRI8SfsW1RwRGbqDJfb006vhlC0iK"
  );

  // Functions
  const createpaymentIntend = () => {
    let payload = {
      amount: parseInt(paymentAmount),
      currency: "usd",
      spotify_id,
      type: paymentpayble,
    };
    axios
      .post(
        `${URLconfig.BASE_URL}/payments/stripe/create-payment-intend`,
        payload
      )
      .then((response) => {
        setclientSecret(response.data.data.client_secret);
        toast.success("response");
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.message);
      });
  };

  const handlePaymentMethodChange = (event) => {
    const selectedValue = event.target.value;
    const valueMapping = {
      "Artist Advance": payment?.artist_advance || 0,
      Marketing: payment?.artist_marketing_budget || 0,
    };
    const numberValue = valueMapping[selectedValue] || 0;
    setpaymentpayble(selectedValue);
    setPrePayment(numberValue);
    setpaymentAmount(numberValue);
  };

  const handleChange = (event) => {
    if (paymentpayble) {
      let value1 = parseInt(event.target.value, 10);
      let conditional_value = parseInt(prePayment);
      if (value1 > conditional_value || value1 <= 0) {
        if (paymentpayble === "Artist Advance") {
          setError(
            `Payment amount cannot be more than $( ${payment?.artist_advance} )`
          );
          setbtndisabled(true);
        }
        if (paymentpayble === "Marketing") {
          setError(
            `Payment amount cannot be more than $( ${payment?.artist_marketing_budget} )`
          );
          setbtndisabled(true);
        }
      } else {
        if (paymentMethod) {
          setbtndisabled(false);
        } else {
          setbtndisabled(true);
        }
        setError("");
      }
    } else {
      setbtndisabled(false);
      setError("");
    }
  };

  const handleMakepayment = (event) => {
    const selectedValue = event.target.value;
    const valueMapping = {
      None: true,
      Stripe: false,
      Trolley: false,
    };
    const CheckPaymentMethod = valueMapping[selectedValue];
    if (paymentpayble) {
      setbtndisabled(CheckPaymentMethod);
    }

    setPaymentMethod(selectedValue);
  };

  return (
    <Modal
      keepMounted
      open={isOpen}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box varient="div" component="div" className={classess.page}>
        <Container>
          <Box varient="div" component="div" className={classess.page__dialog}>
            <Box
              varient="div"
              component="div"
              className={classess.page__dialog__header}
            >
              <span className={classess.page__dialog__header__title}>
                Make a Payment
              </span>
            </Box>

            <Box className={classess.page__dialog__form__field_container}>
              <Box
                varient="div"
                component="div"
                className={classess.page__dialog__form__field_container__field}
              >
                <label
                  className={
                    classess.page__dialog__form__field_container__field__label
                  }
                >
                  Pay Against *
                </label>

                <CustomSelect
                  value={paymentpayble}
                  onChange={handlePaymentMethodChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Artist Advance"}>Artist Advance</MenuItem>
                  <MenuItem value={"Marketing"}>Marketing Budget</MenuItem>
                </CustomSelect>
              </Box>
              <Box
                varient="div"
                component="div"
                className={classess.page__dialog__form__field_container__field}
              >
                <label
                  className={
                    classess.page__dialog__form__field_container__field__label
                  }
                >
                  Payment Method *
                </label>
                <CustomSelect
                  value={paymentMethod}
                  onChange={handleMakepayment}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Stripe"}>Stripe</MenuItem>
                  <MenuItem value={"Trolley"}>Trolley</MenuItem>
                </CustomSelect>
              </Box>
              <Box
                varient="div"
                component="div"
                className={classess.page__dialog__form__field_container__field}
                onChange={(e) => setpaymentAmount(e.target.value)}
              >
                <label
                  className={
                    classess.page__dialog__form__field_container__field__label
                  }
                >
                  Payment Amount *
                </label>
                <>
                  <CustomTextField
                    name="Payment_Amount"
                    placeholder={`$ ${paymentAmount}`}
                    type="text"
                    min={0}
                    max={100000}
                    onChange={handleChange}
                    required
                  />
                  {error && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {error}
                    </div>
                  )}
                </>
              </Box>
            </Box>

            <Box className={classess.page__dialog__form__field_container}>
              <Box
                varient="div"
                component="div"
                className={classess.page__dialog__form__field_container__field}
              >
                <label
                  className={
                    classess.page__dialog__form__field_container__field__label
                  }
                  style={{ marginTop: "20px " }}
                >
                  Add Comments
                </label>
                <TextField
                  className={
                    classess.page__dialog__form__field_container__field__inputComment
                  }
                  multiline="true"
                  rows="5"
                  onChange={(e) => {
                    // setComment(e.target.value);
                  }}
                  placeholder="Add Comments"
                  sx={{
                    "& fieldset": {
                      border: "1px solid #5a7380",
                      borderRadius: "12px",
                      transition: "all 0.3s ease-in-out",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: "#979797",
                      "&.Mui-focused fieldset": {
                        borderColor: "var(--base-input-focus-color)",
                      },
                    },
                    "&:hover fieldset": {
                      borderColor: "var(--base-input-focus-color) !important",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              varient="div"
              component="div"
              className={classess.page__dialog__form__actions}
            >
              <Stack
                direction="row"
                gap={5}
                sx={{ width: { xs: "100%", sm: "100%", lg: "49%" } }}
              >
                <Button
                  variant="outlined"
                  type="button"
                  className={classess.page__dialog__form__actions__cancel_btn}
                  onClick={() => dispatch(closeModal())}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type=""
                  className={classess.page__dialog__form__actions__submit_btn}
                  onClick={() => createpaymentIntend()}
                >
                  Confirm Amount
                </Button>
              </Stack>
            </Box>

            {clientSecret && (
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: `${clientSecret}` }}
              >
                <CheckoutForm />
              </Elements>
            )}
          </Box>
        </Container>
      </Box>
    </Modal>
  );
}

// const handlesubmitbtn = (e, id) => {
//   e.preventDefault();
//   setLoading(true);
//   setbtndisabled(true);

//   let config = {
//     headers: {
//       authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
//     },
//   };
//   const payload = {
//     transection_id: Math.floor(Math.random() * 11022119877),
//     mode: paymentMethod,
//     amount_paid: paymentAmount,
//     status: "paid",
//     comments: comment,
//     paid_against: paymentpayble,
//   };
//   let updatePayment = {};
//   if (paymentpayble === "Artist Advance") {
//     updatePayment = {
//       artistMarketingBudget: payment?.artist_marketing_budget,
//       artist_advance: prePayment - paymentAmount,
//       last_payment_paid: paymentAmount,
//     };
//   } else {
//     updatePayment = {
//       artistMarketingBudget: prePayment - paymentAmount,
//       artist_advance: payment?.artist_advance,
//       last_payment_paid: paymentAmount,
//     };
//   }
//   // const updatePayment = {
//   //   artist_marketing_budget:marketingPay,
//   //   artist_advance:(prePayment-paymentAmount)
//   // }
//   axios
//     .post(
//       `${URLconfig.BASE_URL}/payments/${id}/transefered_payments`,
//       payload,
//       config
//     )
//     .then((res) => {
//       axios
//         .put(`${URLconfig.BASE_URL}/payments/${id}`, updatePayment, config)
//         .then((res) => {
//           // PUT request successful
//           toast.success("Payment updates");
//           window.location.reload();
//         })
//         .catch((error) => {
//           toast.error("Request Error"); //PUT
//           console.log(error);
//         });
//     })
//     .catch((error) => {
//       toast.error("Request Error"); //POST
//       console.log(error);
//     });
// };
