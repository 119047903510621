import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import classess from "./style.module.scss";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import SignStepper from "../../../containers/my-contracts/sign-stepper/SignStepper";
import axios from "axios";
import { config as URLconfig } from "../../../enviorment/enviorment";
import { useSelector } from "react-redux";
import { RiLock2Line } from "react-icons/ri";

const InitiateContract = ({
  contract_length,
  artist_advance,
  marketing_budget,
  totalFunding,
  spotifyId,
}) => {
  const [containerExpanded, setContainerExpanded] = useState([
    false,
    false,
    false,
  ]);
  const user = useSelector((state) => state.auth.user);

  const toggleExpansion = (index) => {
    const updatedExpanded = containerExpanded.map((expanded, i) =>
      i === index ? !expanded : false
    );
    setContainerExpanded(updatedExpanded);
  };
  const OnBoardButton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={async () => {
            try {
              let payload = {
                spotify_id: spotifyId,
              };
              let r1 = await axios.post(
                `${URLconfig.BASE_URL}/payments/stripe/create-account`,
                payload
              );

              let account_id = r1.data.data.account_id;

              let r2 = await axios.get(
                `${URLconfig.BASE_URL}/payments/stripe/onboarding-link/${account_id}`
              );

              let accountLink = r2.data.data.url;

              window.open(accountLink, "_blank");
            } catch (error) {
              console.log(error);
            }
          }}
          className={classess.onBoard}
        >
          On Boarding
        </Button>

        {/* <Button className={classess.onBoard}>On Boarding</Button> */}
      </Box>
    );
  };
  const data = [
    {
      name: "1. Contract",
      function: () => toggleExpansion(0),
      component: (
        <SignStepper
          contract_length={contract_length}
          artist_advance={artist_advance}
          marketing_budget={marketing_budget}
          totalFunding={totalFunding}
          nextGo={() => toggleExpansion(1)}
        />
      ),
    },
    ...(user?.subscription === "bl-user"
      ? [
          {
            name: "2. Payment",
            function: () => toggleExpansion(1),
            component: <OnBoardButton />,
          },
        ]
      : [
          {
            name: "Payment Details",
            lock: <RiLock2Line />,
            toolTip: "Unlock Pro Features",
          },
        ]),
    // {
    //   name: "2. Payment",
    //   function: () => toggleExpansion(1),
    //   component: <OnBoardButton />,
    // },
  ];

  const [letStart, setLetStart] = useState(false);
  const GetLetStart = () => {
    setLetStart(true);
  };

  return (
    <Box className={classess.page}>
      {letStart || (
        <Box className={classess.page__letStart} onClick={GetLetStart}>
          <Box></Box>
          <Box>
            <Typography className={classess.page__letStart__heading}>
              Lets get started on a contract!
            </Typography>
          </Box>
          <Box>
            <IconButton
              className={classess.page__letStart__icon}
              onClick={GetLetStart}
            >
              <IoIosArrowForward
                className={classess.page__letStart__icon__rightIcon}
              />
            </IconButton>
          </Box>
        </Box>
      )}
      {letStart && (
        <Box className={classess.page__customPanel}>
          <Box sx={{ padding: "20px" }}>
            <Typography className={classess.page__customPanel__heading}>
              Lets generate a contract
            </Typography>
          </Box>
          <Divider className={classess.page__letStart__divider} />
          <Box className={classess.page__customPanel__contractContainer}>
            <Box
              className={
                classess.page__customPanel__contractContainer__contractList
              }
            >
              {data.map((item, index) => (
                <ListItems
                  index={index}
                  containerExpanded={containerExpanded[index]}
                  name={item.name}
                  toggle_btn={item.function}
                  component={item.component}
                  lock={item.lock}
                  toolTip={item.toolTip}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const ListItems = ({
  index,
  containerExpanded,
  name,
  component,
  toggle_btn,
  lock,
  toolTip,
}) => {
  return (
    <Box
      key={index}
      className={
        classess.page__customPanel__contractContainer__contractList__list
      }
      style={{
        maxHeight: containerExpanded ? "100%" : "71px",
        border: containerExpanded
          ? "2px solid var(--accordian-contianer-border)"
          : "2px solid #192233",
        cursor: containerExpanded ? "default" : "pointer",
      }}
      onClick={containerExpanded ? null : toggle_btn}
    >
      <Box
        className={
          classess.page__customPanel__contractContainer__contractList__list__listContent
        }
      >
        <Tooltip title={toolTip} placement="top" arrow enterDelay={100}>
          <Typography
            className={
              classess.page__customPanel__contractContainer__contractList__list__listContent__heading
            }
          >
            {name}

            <span
              className={
                classess.page__customPanel__contractContainer__contractList__list__listContent__lockIcon
              }
            >
              {lock}
            </span>
          </Typography>
        </Tooltip>
        {/* <Typography
          className={
            classess.page__customPanel__contractContainer__contractList__list__listContent__heading
          }
        >
          {name}
        </Typography> */}
        <Box>
          <IconButton
            className={
              classess.page__customPanel__contractContainer__contractList__list__listContent__listContentIcon
            }
            onClick={toggle_btn}
          >
            {containerExpanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>{containerExpanded && component}</Box>
    </Box>
  );
};

export default InitiateContract;
