// React and Redux Imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// MUI Imports
import {
  Box,
  Grid,
  Avatar,
  Typography,
  Tooltip,
  Container,
  TablePagination,
} from "@mui/material";

// Custom Components and Styles Imports
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";
import classess from "./style.module.scss";
import { viewArtistUseStyles } from "../../custom-mui-style/custom-mui-styles";
import SearchIcon from "@mui/icons-material/Search";
// Assets and Utilities Imports
import { getItemToLocalStorage } from "../../services/storage";
import { config as URLconfig } from "../../enviorment/enviorment";

// External Libraries and Icons Imports
import axios from "axios";
import Lottie from "lottie-react";
import NewLoader from "../lottie-loader/Loader.json";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { countries } from "country-data";
import { addCommasToNumber, scrollTopObserver } from "../../utils/helper";

import { useParams } from "react-router-dom";
import UserInfoContainer from "../aandr-activity-list/user-Info-container/UserInfoContainer";

const AandRPreview = () => {
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const user = useSelector((state) => state.auth.user);
  const storedToken = getItemToLocalStorage("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [userArtistsList, setUserArtistsList] = useState([]);
  const [filteredArtists, setFilteredArtists] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const styles = viewArtistUseStyles();

  useEffect(() => {
    const fetchArtistUser = async (id) => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${URLconfig.BASE_URL}/artists/action/artists-by-user/${id}`,
          {
            headers: { Authorization: `Bearer ${storedToken}` },
          }
        );
        const artists = response.data.data.artists.reverse();
        setUserArtistsList(artists);
        setFilteredArtists(artists); // Initialize filteredArtists with the full list
        setSelectedUser(response.data.data.user);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching artists:", error);
        setIsLoading(false);
      }
    };

    fetchArtistUser(id);
  }, [id]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle search query change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setPage(0);
    setSearchQuery(query);
    if (!query.trim()) {
      setFilteredArtists(userArtistsList); // Set filteredArtists back to the full list
    } else {
      const filtered = userArtistsList.filter((artist) =>
        artist.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredArtists(filtered);
    }
  };

  return (
    <Container maxWidth="xxl" className={styles.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
          <UserInfoContainer />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
          <Box className={classess.page}>
            <Box
              varient="div"
              component="div"
              className={classess.page__header}
            >
              <span className={classess.page__header__title}>Artists</span>

              <Box display={"flex"} alignItems={"center"} gap={"20px"}>
                <Box
                  varient="div"
                  component="div"
                  className={classess.page__header__search}
                >
                  <SearchIcon
                    className={classess.page__header__search__seaarch_icon}
                  />
                  <input
                    className={classess.page__header__search__input}
                    placeholder="Search"
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    required
                  />
                </Box>
              </Box>
            </Box>
            {isLoading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <Lottie
                    animationData={NewLoader}
                    className={classess.page__loader}
                  />
                </Box>
              </Box>
            ) : (
              <ActivityTable
                isLoading={isLoading}
                userArtistsList={filteredArtists}
                rowsPerPage={rowsPerPage}
                totalArtist={filteredArtists?.length}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AandRPreview;

const ActivityTable = ({
  isLoading,
  userArtistsList,
  totalArtist,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const navigate = useNavigate();

  const getEmail = (email, name) => {
    if (email) {
      return email;
    }
    const newName = name.replace(/\s+/, "");
    return `${newName}@blacklionapp.xyz`;
  };

  return (
    <React.Fragment>
      <CustomTableContainer className={classess.page__table}>
        <CustomTable stickyHeader aria-label="sticky table">
          <CustomTableHead>
            <CustomTableCellHeader />
            <CustomTableCellHeader>ARTIST NAME</CustomTableCellHeader>
            <CustomTableCellHeader>COUNTRY</CustomTableCellHeader>
            <CustomTableCellHeader>EMAIL</CustomTableCellHeader>
            <CustomTableCellHeader>Earnings(Yearly)</CustomTableCellHeader>
          </CustomTableHead>

          <CustomTableBody>
            {userArtistsList.length > 0 ? (
              userArtistsList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  return (
                    <React.Fragment key={row._id}>
                      <Box sx={{ mt: "1rem" }} />
                      <CustomTableRow>
                        <CustomTableCell
                          sx={{
                            borderBottomLeftRadius: "12px",
                            borderTopLeftRadius: "12px",
                          }}
                        >
                          <Avatar
                            src={row?.avatar}
                            alt={row?.name}
                            className={classess.page__placement__artist_image}
                          />
                        </CustomTableCell>

                        <CustomTableCell>
                          <Box className={classess.page__placement}>
                            <Tooltip
                              title={row?.name}
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <span
                                onClick={() => {
                                  navigate(`/blig/view-artist/${row?._id}`);
                                  scrollTopObserver();
                                }}
                              >
                                {row?.name}
                              </span>
                            </Tooltip>
                          </Box>
                        </CustomTableCell>

                        <CustomTableCell>
                          <Box className={classess.page__country}>
                            <ReactCountryFlag
                              countryCode={row?.chartmetric?.code2}
                              svg
                            />
                            {row?.chartmetric?.code2
                              ? countries[row?.chartmetric?.code2.toUpperCase()]
                                  ?.name || "N/A"
                              : "N/A"}
                          </Box>
                        </CustomTableCell>

                        <CustomTableCell>
                          <Box className={classess.page__email}>
                            {getEmail(row?.email, row?.name)}
                          </Box>
                        </CustomTableCell>

                        <CustomTableCell
                          sx={{
                            borderTopRightRadius: "12px",
                            borderBottomRightRadius: "12px",
                          }}
                        >
                          <span className={classess.page__yearly_earning}>
                            $
                            {addCommasToNumber(
                              row?.oneyearIncome ? row?.oneyearIncome : "N/A"
                            )}
                          </span>
                        </CustomTableCell>
                      </CustomTableRow>
                    </React.Fragment>
                  );
                })
            ) : (
              <React.Fragment>
                <Box sx={{ marginTop: "1rem" }} />
                <CustomTableRow>
                  <CustomTableCell colSpan={7} sx={{ borderRadius: "12px" }}>
                    <Typography className={classess.page__notFound}>
                      No Artists Found.
                    </Typography>
                  </CustomTableCell>
                </CustomTableRow>
              </React.Fragment>
            )}
          </CustomTableBody>
        </CustomTable>
      </CustomTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        sx={{ color: "#d6d6d6", width: "100%", height: "30%" }}
        count={totalArtist}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          actions: "custom-pagination-actions",
          select: "custom-pagination-select",
          displayedRows: "custom-select-style",
          selectLabel: "custom-select-style",
        }}
        SelectProps={{
          classes: {
            select: "custom-select",
          },
        }}
      />
    </React.Fragment>
  );
};
