import React from "react";
import { CustomSelect } from "../../../custom-mui-style/custom-mui-styles";
import { Avatar, Box, MenuItem, Tooltip, Typography } from "@mui/material";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { addCommasToNumber } from "../../../utils/helper";
import ReactCountryFlag from "react-country-flag";
import { useEffect, useState } from "react";
import classess from "./style.module.scss";
import { config as URLconfig } from "../../../enviorment/enviorment";
import { getItemToLocalStorage } from "../../../services/storage";
import axios from "axios";
import Lottie from "lottie-react";
import NewLoader from "../../lottie-loader/Loader.json";
import socialon3 from "../../../assets/social/social-icon3.png";
const TrendingTracklist = () => {
  const storedToken = getItemToLocalStorage("accessToken");

  const [value, setValue] = useState("global");
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${URLconfig.BASE_URL}/artist-tracks/trading/tracks`,
            {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            }
          );
          const responseData = response.data;
          setData(responseData?.data);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [data]);
  // console.log("trackdata", data);
  // ? select dropDown
  const GlobalSelect = ({
    value,
    handleChange,
    handleOpen,
    handleClose,
    isOpen,
  }) => (
    <Box className={classess.page__bottomSec__trending__select}>
      <CustomSelect
        value={value}
        onChange={handleChange}
        style={{ width: "90px", fontWeight: 700 }}
        onOpen={handleOpen}
        onClose={handleClose}
        IconComponent={() =>
          isOpen ? (
            <IoIosArrowUp
              className={classess.page__bottomSec__trending__select__icon}
            />
          ) : (
            <IoIosArrowDown
              className={classess.page__bottomSec__trending__select__icon}
            />
          )
        }
      >
        <MenuItem style={{ fontSize: "12px", fontWeight: 700 }} value="global">
          Global
        </MenuItem>
        <MenuItem style={{ fontSize: "12px", fontWeight: 700 }} value="Day">
          Day
        </MenuItem>
        <MenuItem style={{ fontSize: "12px", fontWeight: 700 }} value="Monthly">
          Monthly
        </MenuItem>
      </CustomSelect>
    </Box>
  );
  return (
    <Box className={classess.page__bottomSec}>
      <Box className={classess.page__bottomSec__trending}>
        <Box className={classess.page__bottomSec__trending__heading}>
          Trending Now
        </Box>
        {/* <GlobalSelect
          value={value}
          handleChange={handleChange}
          handleOpen={handleOpen}
          handleClose={handleClose}
          isOpen={isOpen}
        /> */}
      </Box>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "45vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "42px", height: "42px" }}>
            <Lottie
              animationData={NewLoader}
              className={classess.page__loader}
            />
          </Box>
        </Box>
      ) : (
        <Box className={classess.page__bottomSec__list}>
          {data?.map((item, index) => (
            <Box className={classess.page__bottomSec__list__box} key={index}>
              <Box className={classess.page__bottomSec__list__box__content}>
                <Box
                  className={
                    classess.page__bottomSec__list__box__content__details
                  }
                >
                  <Avatar
                    src={item.image_url}
                    sx={{ height: "45px", width: "45px" }}
                  />

                  <Box
                    className={
                      classess.page__bottomSec__list__box__content__details__detail
                    }
                  >
                    <Typography
                      className={
                        classess.page__bottomSec__list__box__content__details__detail__name
                      }
                    >
                      <Tooltip
                        title={item.name}
                        placement="top"
                        arrow
                        enterDelay={100}
                      >
                        {item.name}
                      </Tooltip>
                    </Typography>
                    <Typography
                      className={
                        classess.page__bottomSec__list__box__content__details__detail__listners
                      }
                    >
                      {item.artist.map((artist) => " " + artist.name)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className={classess.page__bottomSec__list__box__content__icon}
                >
                  <img src={socialon3} alt="socialon3" />
                  <Typography
                    className={
                      classess.page__bottomSec__list__box__content__icon__listners
                    }
                  >
                    {addCommasToNumber(item.latest?.spotify_plays)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default TrendingTracklist;
