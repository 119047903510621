import React from "react";
import classess from "./style.module.scss";
import { Box, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { RiLock2Line } from "react-icons/ri";

const CustomTwoTabs = ({
  btn_0,
  btn_1,
  click_0,
  click_1,
  text_0,
  text_1,
  padding,
  isLoading,
}) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <Box className={classess.page}>
      <Box className={classess.page__tabs_container}>
        <button
          style={{
            backgroundColor: btn_0 ? "var(--base-button-background-color)" : "",
            padding: padding,
          }}
          onClick={click_0}
          disabled={isLoading}
        >
          {text_0}
        </button>

        {user?.subscription === "bl-user" ? (
          <button
            style={{
              backgroundColor: btn_1
                ? "var(--base-button-background-color)"
                : "",
              padding: padding,
            }}
            onClick={click_1}
            disabled={isLoading}
          >
            {text_1}
          </button>
        ) : (
          <Tooltip
            title="Unlock Pro Features"
            placement="top"
            arrow
            enterDelay={100}
          >
            <button
              style={{
                backgroundColor: btn_1
                  ? "var(--base-button-background-color)"
                  : "",
                padding: padding,
              }}
              onClick={click_1}
              disabled={true}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"5px"}
              >
                <RiLock2Line
                  style={{
                    fontSize: "15px",
                    top: "5px",
                  }}
                />
                {text_1}
              </Box>
            </button>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default CustomTwoTabs;
