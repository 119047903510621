import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const AllPlatformChart = ({ data }) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (data && data.platform_sorted_by_revenue) {
      const platForms = data.platform_sorted_by_revenue.map((item) => item);
      setValue(platForms);
    }
  }, [data]);

  const seriesData = value.slice(0, 10).flatMap((item) => item[1].sum);
  const categoriesData = value.slice(0, 10).flatMap((item) => item[0]);
  const allPlatform = {
    series: [
      {
        data: seriesData,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 500,
      },
      // colors: ["var(--base-graph-line-color)"],
      colors: [
        "#21d05d",
        "#f35269",
        "#f80100",
        "#e71d24",
        "#1874ed",
        "#ffc91c",
        "#ed4624",
        "#ff6c16",
        "#a53eff",
        "#c766f7",
      ],
      plotOptions: {
        bar: {
          distributed: true, // this line is mandatory
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
          barHeight: "90%",
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            if (val >= 1000000) {
              return "Earnings: " + "$" + (val / 1000000).toFixed(0) + "M";
            } else if (val >= 1000) {
              return "Earnings: " + "$" + (val / 1000).toFixed(0) + "K";
            } else {
              return "Earnings: " + "$" + val.toFixed(0);
            }
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: categoriesData,
        labels: {
          formatter: function (value) {
            if (value >= 1000000) {
              return Math.round(value / 1000000).toFixed(1) + "M";
            } else if (value >= 1000) {
              return Math.round(value / 1000).toFixed(1) + "K";
            } else {
              return Math.round(value);
            }
          },
        },
        tickAmount: 6,
      },
    },
  };

  return (
    <div>
      <div id="chart" className="allPlatformGraph">
        <ReactApexChart
          options={allPlatform.options}
          series={allPlatform.series}
          type="bar"
          height={500}
        />
      </div>
    </div>
  );
};

export default AllPlatformChart;
