import React from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
// import AddArtist from "../artist/add-artist/add-artist";
import MyArtistList from "../../components/my-artist-list/my-artist-list";
import SearchAddArtist from "../../components/search-add-artist/SearchAddArtist";
const MyArtist = () => {
  return (
    <Container maxWidth="xxl">
      <Grid container spacing={2} className={classess.page}>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
          <Box>
            <MyArtistList />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
          <Box>
            <SearchAddArtist />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MyArtist;
