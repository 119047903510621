// React core functionalities
import React, { useState } from "react";

// Material-UI core components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";

// Material-UI icons
import EditIcon from "@mui/icons-material/Edit";

// Custom components
import ArtistPaymentList from "../../components/ArtistPaymentList/ArtistPaymentList";

// Utility libraries
import moment from "moment";

// Assets
import foldericon from "../../../src/assets/folder.png";
import deleteicon from "../../../src/assets/delete.png";

// Styles
import classes from "./style.module.scss";
import { viewArtistUseStyles } from "../../custom-mui-style/custom-mui-styles";

const MyPayments = () => {
  // State management
  const [openPanel] = useState(false);
  const [notesPanel, setNotesPanel] = useState(null); // To toggle notes panel
  const [notesComments, setNotesComments] = useState([]); // To store notes comments

  // Custom styles
  const styles = viewArtistUseStyles();

  // Handler functions
  const handleNotesOpen = (value) => {
    setNotesPanel(value);
  };

  return (
    <Container maxWidth="xxl" className={styles.root}>
      <Grid
        container
        sm={12}
        md={12}
        lg={12}
        xl={12}
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
      >
        <Grid item sm={12} md={12} lg={12} xl={9}>
          <ArtistPaymentList
            handleNotes={handleNotesOpen}
            setNotesComments={setNotesComments}
          />
        </Grid>

        <Grid item sm={3} md={5} lg={4} xl={3} className={classes.page__notes}>
          {notesPanel != null && (
            <NotePanelComponent
              notesComments={notesComments}
              openPanel={openPanel}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default MyPayments;

const NotePanelComponent = ({ notesComments, openPanel }) => {
  return (
    <Box varient="div" component="div" className={classes.page__notes__box}>
      <Box
        varient="div"
        component="div"
        className={classes.page__notes__box__tracks}
      >
        <Box
          varient="div"
          component="div"
          className={classes.page__notes__box__tracks__adetails__header}
        >
          <span
            className={
              classes.page__notes__box__tracks__adetails__header__title
            }
          >
            Notes
          </span>
          <span>
            <IconButton
              variant="contained"
              // onClick={() => setOpenPanel(!openPanel)}
              className={
                classes.page__notes__box__tracks__adetails__header__editbtn
              }
            >
              <EditIcon
                fontSize="small"
                sx={{ fontSize: "15px" }}
                className={
                  classes.page__notes__box__tracks__adetails__header__editbtn__inner_btn
                }
              />
            </IconButton>
          </span>
        </Box>
        {notesComments.map((data) => {
          return (
            <Grid container mb={2} key={data._id}>
              <Grid sm className={classes.page__notes__notebox}>
                <Box className={classes.page__notes__notebox__chips}>
                  <span>
                    <Chip
                      className={
                        classes.page__notes__notebox__chips__chip_container
                      }
                      avatar={
                        <Avatar alt="avatar" src={data.user?.profilePicture} />
                      }
                      label={data.user.firstName + " " + data.user.lastName}
                      variant="filled"
                    />
                  </span>
                  <span>
                    <Chip
                      className={
                        classes.page__notes__notebox__chips__chip_container
                      }
                      label={moment(`${data?.createdAt}`).fromNow()}
                      variant="filled"
                    />
                  </span>
                </Box>
                <Box className={classes.page__notes__notebox__notetext}>
                  <p>{data.note}</p>
                </Box>
                {openPanel && (
                  <Grid sm={2} className={classes.page__notes__noteboxControls}>
                    <img src={foldericon} alt="folder" />
                    <img src={deleteicon} alt="delete" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
};
