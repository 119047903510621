// React imports
import React, { useEffect, useState } from "react";

// Redux imports
import { useSelector } from "react-redux";

// Material UI imports
import { Box, Typography, Avatar } from "@mui/material";
import ReactApexChart from "react-apexcharts";

// CSS Module import
import classes from "./style.module.scss";

// Asset imports
import SpotifyIcon from "../../../assets/social/social-icon3.png";
import YoutubeIcon from "../../../assets/social/social-icon2.png";
import DeezerIcon from "../../../assets/social/social-icon5.png";
import SoundCloudIcon from "../../../assets/social/social-icon4.png";
import AppleMusicIcon from "../../../assets/social/social-icon1.png";
import TiktokIcon from "../../../assets/social/social-icon8.png";
import AlertIcon from "../../../assets/AlertIcon.png";

// Axios import
import axios from "axios";
import { config as URLconfig } from "../../../enviorment/enviorment";

// Lottie animation import
import Lottie from "lottie-react";
import NewLoader from "../../../components/lottie-loader/Loader.json";

import "./style.module.scss";

const RevenueGraph = () => {
  // State declarations
  const [spotifyData, setSpotifyData] = useState(0);
  const [soundCloudData, setSoundCloudData] = useState(0);
  const [appleMusicData, setAppleMusicData] = useState(0);
  const [deezerData, setDeezerData] = useState(0);
  const [youtubeData, setYoutubeData] = useState(0);
  const [tiktokData, settiktokData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // Get artist data from Redux store
  const artist = useSelector((state) => state.artist.artist);

  // Fetch data when the component mounts or artist changes
  useEffect(() => {
    if (artist) {
      setIsLoading(true);
      axios
        .get(`${URLconfig.BASE_URL}/artist-stats/${artist?.spotify_id}`)
        .then((res) => {
          const data = res?.data?.data;
          const stats = data.stats.reduce((acc, el) => {
            acc[el.source] = el.data;
            return acc;
          }, {});

          setSpotifyData(stats.spotify?.streams_total || 0);
          setYoutubeData(stats.youtube?.video_views_total || 0);
          setSoundCloudData(stats.soundcloud?.streams_total || 0);
          setAppleMusicData(stats.apple_music?.streams_total || 0);
          setDeezerData(stats.deezer?.playlist_reach_total || 0);
          settiktokData(stats.tiktok?.views_total || 0);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [artist]);

  // Platform data configuration
  const platformData = [
    { name: "Spotify", data: spotifyData, icon: SpotifyIcon, dec: "Streams" },
    { name: "YouTube", data: youtubeData, icon: YoutubeIcon, dec: "Views" },
    {
      name: "SoundCloud",
      data: soundCloudData,
      icon: SoundCloudIcon,
      dec: "Streams",
    },
    {
      name: "Apple Music",
      data: appleMusicData,
      icon: AppleMusicIcon,
      dec: "Streams",
    },
    { name: "Deezer", data: deezerData, icon: DeezerIcon, dec: "Streams" },
    { name: "Tiktok", data: tiktokData, icon: TiktokIcon, dec: "Views" },
  ];

  const filteredPlatformData = platformData.filter((platform) => platform.data);

  // Chart options configuration
  const chartOptions = {
    series: [
      {
        name: "Platform Data",
        data: platformData
          .filter((platform) => platform.data > 0)
          .map((platform) => Math.log10(platform.data)),
      },
    ],
    chart: {
      type: "radar",
      toolbar: { show: false },
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          chartContext.tooltip.show();
        },
      },
    },
    title: { text: "", align: "center" },
    stroke: { show: false, width: 2, colors: [], dashArray: 0 },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "diagonal2",
        shadeIntensity: 1,
        opacityFrom: 0.9,
        opacityTo: 1,
        colorStops: [
          { offset: 0, color: "#FFFF17", opacity: 1 },
          { offset: 20, color: "#FFFF17", opacity: 0.9 },
          { offset: 50, color: "#FF6161", opacity: 0.9 },
          { offset: 100, color: "#AB00FF", opacity: 0.9 },
        ],
      },
    },
    xaxis: {
      labels: { show: true },
      categories: filteredPlatformData.map((platform) => platform.name),
    },
    yaxis: { show: false },
    dataLabels: { enabled: false },
    plotOptions: {
      radar: {
        size: 90,
        offsetX: 0,
        offsetY: -30,
        polygons: {
          strokeColor: "#999999",
          fill: { colors: ["#5A7380", "#5A7380"] },
        },
      },
    },
    tooltip: {
      enabled: true,
      hideEmptySeries: true,
      theme: "dark",
      highlightDataSeries: true,
      enabledOnSeries: undefined,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const platformName = filteredPlatformData[dataPointIndex].name;
        const dataValue = filteredPlatformData[dataPointIndex].data;
        const term = ["Tiktok", "YouTube"].includes(platformName)
          ? "Views"
          : "Streams";
        const formattedDataValue = new Intl.NumberFormat().format(dataValue);

        return (
          '<div style="' +
          "background-color: #FF3D81; " +
          "color: white; " +
          "padding: 15px; " +
          "border-radius: 5px;" +
          '">' +
          '<span style="' +
          "font-size: 12px;" +
          "font-weight: 500;" +
          '">' +
          formattedDataValue +
          "<br/> " +
          "</span>" +
          '<span style="' +
          "font-size: 8px;" +
          '">' +
          term +
          "</span>" +
          "</div>"
        );
      },
      style: {
        backgroundColor: "#add8e6 !important",
        fontFamily: "DM Sans",
        fontSize: "12px",
      },
      x: {
        show: false,
      },
      y: {
        formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
          const platformName = filteredPlatformData[dataPointIndex].name;
          const dataValue = filteredPlatformData[dataPointIndex].data;
          const term = ["Tiktok", "YouTube"].includes(platformName)
            ? "Views"
            : "Streams";
          return `${new Intl.NumberFormat().format(dataValue)} ${term}`;
        },
        title: { formatter: () => "" },
      },
      z: { formatter: undefined, title: "Size: " },
      marker: { show: false },
      items: { display: "flex" },
      fixed: {
        enabled: false,
        position: "BottomRight",
        offsetX: 0,
        offsetY: 0,
      },
      title: {
        formatter: (seriesName, { seriesIndex, dataPointIndex, w }) => "",
      },
    },
  };

  return (
    <Box component="div" className={classes.page}>
      <Typography className={classes.page__topHeading}>
        Total Streaming Stats
      </Typography>
      <Box component="div" className={classes.page__innerContainer}>
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            <SteamingTextLeftComponent platformData={platformData} />

            {platformData.filter((platform) => platform.data > 0).length >=
            3 ? (
              <StremingGraphRightComponent chartOptions={chartOptions} />
            ) : (
              <GraphAlertComponent />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default RevenueGraph;

// Streaming text left component
const SteamingTextLeftComponent = ({ platformData }) => {
  return (
    <Box
      component="div"
      className={classes.page__innerContainer__rightContainer}
    >
      {platformData.map((item, index) => (
        <Box
          key={index}
          component="div"
          className={
            classes.page__innerContainer__rightContainer__infoContainer
          }
        >
          <Avatar
            src={item.icon}
            alt={item.name}
            sx={{
              filter: item.data > 0 ? "none" : "grayscale(100%)",
              opacity: item.data > 0 ? "1" : "0.5",

              height: "30px",
              width: "30px",
            }}
          />
          <Box>
            <Typography
              className={
                classes.page__innerContainer__rightContainer__infoContainer__streams
              }
            >
              {item.data > 0
                ? new Intl.NumberFormat().format(item.data)
                : "Missing Data"}
            </Typography>
            <Typography
              className={
                classes.page__innerContainer__rightContainer__infoContainer__streamsSecondary
              }
            >
              {item.dec}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

// Streaming graph right component
const StremingGraphRightComponent = ({ chartOptions }) => {
  return (
    <Box
      component="div"
      className={classes.page__innerContainer__leftContainer}
    >
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type="radar"
        height={320}
      />
    </Box>
  );
};

// Graph Alert right component
const GraphAlertComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 20px 20px 20px !important",
        height: "90px",
        width: "40%",
        backgroundColor: "#192233",
        borderRadius: "10px",
        borderLeft: "3px solid #FF4757",
        marginTop: "-50px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "10px",
        }}
      >
        <img
          src={AlertIcon}
          alt="AlertIcon"
          style={{ height: "23.59px", width: "26.78px" }}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: "12px",
            textTransform: "uppercase",
            letterSpacing: "2px",
            color: "#FF4757",
            fontWeight: "900",
          }}
        >
          Alert!
        </Typography>
        <Typography
          sx={{
            fontSize: "9px",
            color: "#ffffff",
            fontWeight: "300",
          }}
        >
          This artist isn't utilising full potential of revenue.
        </Typography>
        <Typography
          sx={{
            fontSize: "9px",
            color: "#ffffff",
            fontWeight: "300",
          }}
        >
          A minimum of 3 values are required for the graph to form a shape!
        </Typography>
      </Box>
    </Box>
  );
};

// Loader component
const LoaderComponent = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "50px",
          height: "50px",
        }}
      >
        <Lottie animationData={NewLoader} className={classes.page__loader} />
      </Box>
    </Box>
  );
};
