import React from "react"; // React core functionalities
import Container from "@mui/material/Container"; // MUI layout container
import Grid from "@mui/material/Grid"; // MUI grid layout

import classes from "./style.module.scss";

// Custom MUI Styles
import { viewArtistUseStyles } from "../../custom-mui-style/custom-mui-styles";

const Notifications = () => {
  const styles = viewArtistUseStyles();

  return (
    <Container maxWidth="xxl" className={styles.root}>
      <Grid
        container
        sm={12}
        md={12}
        lg={12}
        xl={12}
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
      >
        <Grid item sm={12} md={12} lg={12} xl={9}></Grid>

        <Grid
          item
          sm={3}
          md={5}
          lg={4}
          xl={3}
          className={classes.page__notes}
        ></Grid>
      </Grid>
    </Container>
  );
};

export default Notifications;
