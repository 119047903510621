/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axios from "axios";
import { config as URLconfig } from "../../../../enviorment/enviorment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  IconButton,
} from "@mui/material";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import NewLoader from "../../../lottie-loader/Loader.json";
import Lottie from "lottie-react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TotalEarnings from "../../../../containers/graph/totalEarningsGraph/totalEarnings";
import { deleteReport, getReports } from "../../../../redux/slice/artist";
import SendContractDialog from "../../../../dialogs/send-contract/send-contract";
import AuthEnum from "../../../../enums/auth.enum";
import {
  CustomDropdown,
  CustomMenuItem,
} from "../../../../custom-mui-style/custom-mui-styles";
import { RxCross2 } from "react-icons/rx";
import Delete from "../../../../assets/deleteIcon.svg";
import UploadFileIcon from "../../../../assets/uploadFileIcon.svg";
import DownloadIcon from "../../../../assets/downloadIcon.svg";
import { ReactSVG } from "react-svg";
import DeleteConformationDialog from "../../../../dialogs/delete-conformation-dialog/delete-conformation-dialog";
import TopTracksTable from "./TopTrackChart";
import AllPlatformChart from "../../../../containers/graph/all-platform-chart/AllPlatformChart";
const SendConfig = ({ customize_funding }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [distributor, setDistributor] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const current_year = new Date().getFullYear();
  const reports = useSelector((state) => state.artist.reports);
  const artist = useSelector((state) => state.artist.artist);
  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [IsEmail, setIsEmail] = useState("");
  const [expanded, setExpanded] = useState([]);
  const authUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [deleteFile, setDeleteFile] = useState();
  const OpenDeleteModal = (id) => {
    setDeleteModal(true);
    setDeleteFile(id);
  };
  const hanldeDeleteFile = (res) => {
    setDeleteModal(false);
    if (res) {
      handleReportDelete(deleteFile);
    }
  };
  const handleReportDelete = (id) => {
    dispatch(deleteReport(id));
  };

  const onFileSelection = (e) => {
    if (distributor === "") {
      toast.warning("Please select a distributor");
    } else {
      setSelectedFile(e.target.files[0]);
    }
  };

  const sendContract = async (data) => {
    const { name, email, file, sender_name, sender_email } = data;
    try {
      // setIsLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}` + "/docu-sign",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem(AuthEnum.TOKEN)}`,
          },
          body: JSON.stringify({
            name,
            email,
            file,
            sender_name,
            sender_email,
          }),
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        toast.success(`${data.message}`, {
          hideProgressBar: true,
          closeButton: true,
          position: "bottom-center",
        });
      } else {
        toast.error(`${data.message}`, {
          hideProgressBar: true,
          closeButton: true,
          position: "bottom-center",
        });
      }
      // setIsLoading(false);
    } catch (e) {
      // setIsLoading(false);
      toast.error("Something went wrong");
    }
  };
  function convertHtmlToDoc() {
    const data = {
      name: artist?.name,
      email: IsEmail ? IsEmail : artist?.email,
      customize_funding: customize_funding?.totalFunding,
    };
    if (artist?.email || IsEmail) {
      axios({
        url: `${URLconfig.BASE_URL}/contract-gen/convert`,
        data,
        method: "POST",
        responseType: "blob",
        headers: {
          authorization: `Bearer ${localStorage.getItem(AuthEnum.TOKEN)}`,
        },
      })
        .then((response) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result.split(",")[1];
            const data2 = {
              name: artist?.name,
              email: IsEmail ? IsEmail : artist?.email,
              sender_name: authUser?.firstName + " " + authUser?.lastName,
              sender_email: authUser?.email,
              file: base64String,
            };
            sendContract(data2);
          };
          reader.readAsDataURL(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setOpen(true);
    }
  }
  const onSubmit = (e) => {
    e.preventDefault();
    setUploading(true);

    let formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("distributor", distributor);
    formData.append("artist_spotify_id", artist?.spotify_id);
    axios
      .post(`${URLconfig.BASE_URL}/upload`, formData, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(AuthEnum.TOKEN)}`,
        },
      })
      .then(() => {
        toast.success("File uploaded successfully", {
          hideProgressBar: true,
          closeButton: true,
          position: "bottom-center",
        });
        dispatch(getReports(artist.spotify_id));
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Please check your file format and selected distributor, or contact administrator for assistance.",
          {
            hideProgressBar: true,
            closeButton: true,
            position: "bottom-center",
          }
        );
      })
      .finally(() => {
        setUploading(false);
        setDistributor("");
        setSelectedFile(null);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenSelect = () => setIsOpen(true);
  const handleCloseSelect = () => setIsOpen(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded ? [...expanded, panel] : expanded?.filter((p) => p !== panel)
    );
  };

  const toggleRow = (index) => {
    setExpandedRow((prevExpandedRow) =>
      prevExpandedRow === index ? null : index
    );
  };

  // console.log("report", reports);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [selectedYear, setSelectedYear] = useState("2022");

  return (
    <React.Fragment>
      <Box className={classess.page}>
        {/* // ? Accordians or list of files */}
        <Grid item md={12} lg={12} xl={12} sm={12}>
          <Box
            varient="div"
            component="div"
            className={classess.page__accordians}
          >
            {reports.map((report, index) => (
              <Accordion
                key={index}
                expanded={expanded.includes(index)}
                onChange={handleChange(index)}
                className={classess.page__accordians__container}
              >
                <AccordionSummary
                  expandIcon={
                    <IconButton
                      className={
                        classess.page__accordians__container__summary__icons__upDown
                      }
                    >
                      <IoIosArrowDown />
                    </IconButton>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  key={report._id}
                  sx={{
                    borderRadius: expanded.includes(index)
                      ? "12px 12px 0px 0px"
                      : "12px !important",
                    borderBottom: expanded.includes(index)
                      ? "1px solid var(--accordian-contianer-border)"
                      : "none",
                    borderTop: expanded.includes(index) ? "none" : "none",
                  }}
                  className={classess.page__accordians__container__summary}
                >
                  <Typography
                    className={
                      classess.page__accordians__container__summary__name
                    }
                  >
                    {report.distributor}
                  </Typography>
                  <Box
                    className={
                      classess.page__accordians__container__summary__icons
                    }
                  >
                    <IconButton
                      className={
                        classess.page__accordians__container__summary__icons__download
                      }
                      component="a"
                      href={report.file_url}
                      download
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <ReactSVG src={DownloadIcon} />
                    </IconButton>
                    <IconButton
                      className={
                        classess.page__accordians__container__summary__icons__delete
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        OpenDeleteModal(report._id);
                      }}
                    >
                      <ReactSVG src={Delete} />
                    </IconButton>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  className={classess.page__accordians__container__details}
                >
                  <Box>
                    <TotalEarnings
                      data={{
                        insights: report.insights,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      padding: "0px 20px",
                    }}
                  >
                    {report?.insights &&
                      Object.entries(report.insights).map(([year, amount]) => (
                        <Box
                          className={
                            classess.page__accordians__container__details__amount
                          }
                        >
                          <Typography
                            className={
                              classess.page__accordians__container__details__amount__year
                            }
                          >
                            {year === current_year
                              ? "Current Year"
                              : "Last Yearly Income:"}
                            {year}
                          </Typography>
                          <Typography
                            className={
                              classess.page__accordians__container__details__amount__value
                            }
                          >
                            {amount.sum === 0
                              ? "-"
                              : "$" +
                                internationalNumberFormat.format(
                                  amount?.sum?.toFixed(0)
                                )}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                  <Box
                    sx={{
                      padding: "20px 30px 0px 10px",
                    }}
                  >
                    <AllPlatformChart
                      data={{
                        platform_sorted_by_revenue:
                          report.platform_sorted_by_revenue,
                      }}
                    />
                  </Box>
                  <TopTracksTable
                    // docId={report._id}
                    tableData={report.tracks}
                    toggleRow={toggleRow}
                    expandedRow={expandedRow}
                    // chartData={chartData}
                    internationalNumberFormat={internationalNumberFormat}
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>

        {/* // ? file uplaod button options*/}
        <Grid item md={12} lg={12} xl={12} sm={12}>
          <Box className={classess.page__fileUpload} mt={5}>
            <Box>
              <Typography className={classess.page__fileUpload__text}>
                UPLOAD ANOTHER REPORT
              </Typography>
              <Box>
                <FormControl>
                  <CustomDropdown
                    value={distributor}
                    onChange={(e) => setDistributor(e.target.value)}
                    displayEmpty
                    sx={{
                      minWidth: "200px",
                      height: "40px",
                      fontWeight: "bold",
                    }}
                    onOpen={handleOpenSelect}
                    onClose={handleCloseSelect}
                    IconComponent={() =>
                      isOpen ? (
                        <IoIosArrowUp
                          className={classess.page__fileUpload__select_icon}
                        />
                      ) : (
                        <IoIosArrowDown
                          className={classess.page__fileUpload__select_icon}
                        />
                      )
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <CustomMenuItem value="" selected>
                      Please Select
                    </CustomMenuItem>
                    {/* <CustomMenuItem value="OneRPM">OneRPM</CustomMenuItem>
                    <CustomMenuItem value="TuneCore">TuneCore</CustomMenuItem>
                    <CustomMenuItem value="CreateMusic">
                      CreateMusic
                    </CustomMenuItem> */}
                    <CustomMenuItem value="Empire">Empire</CustomMenuItem>
                    {/* <CustomMenuItem value="AscapWriter:F1">
                      Ascap Writer - F1
                    </CustomMenuItem>

                    <CustomMenuItem value="AscapWriter:F2">
                      Ascap Writer - F2
                    </CustomMenuItem> */}
                  </CustomDropdown>
                </FormControl>
              </Box>
            </Box>

            <Box mt={3.5}>
              {!selectedFile && (
                <>
                  {distributor === "" || distributor === "10" ? (
                    <Button
                      variant="contained"
                      component="label"
                      className={classess.page__fileUpload__input__btn_disable}
                      disabled
                      startIcon={<ReactSVG src={DownloadIcon} />}
                    >
                      Upload from storage
                      <input
                        hidden
                        accept=".csv, .tsv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        type="file"
                      />
                    </Button>
                  ) : (
                    <Button
                      onChange={onFileSelection}
                      variant="contained"
                      component="label"
                      className={classess.page__fileUpload__input__btn}
                      onClick={() => setSelectedFile(null)}
                      startIcon={<ReactSVG src={DownloadIcon} />}
                    >
                      Upload from storage
                      <input
                        hidden
                        accept=".csv, .tsv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        type="file"
                      />
                    </Button>
                  )}
                </>
              )}
              {selectedFile && (
                <Button
                  className={classess.page__fileUpload__input__btn_available}
                  onChange={onFileSelection}
                  variant="contained"
                  component="label"
                  onClick={() => setSelectedFile(null)}
                  endIcon={
                    <Box>
                      <RxCross2 />
                    </Box>
                  }
                >
                  <Box
                    className={
                      classess.page__fileUpload__input__btn_available__text
                    }
                  >
                    {selectedFile.name}
                  </Box>
                </Button>
              )}
            </Box>
            <Box mt={4}>
              <Box display={"flex"} flexDirection={"column"}>
                {selectedFile ? (
                  <>
                    <Button
                      onClick={(e) => onSubmit(e)}
                      className={classess.page__fileUpload__uploadBtn}
                      sx={{
                        mt: 1.5,
                      }}
                      startIcon={
                        uploading ? (
                          <Lottie
                            animationData={NewLoader}
                            className={classess.page__loader}
                          />
                        ) : (
                          <ReactSVG src={UploadFileIcon} />
                        )
                      }
                    >
                      Upload Report
                    </Button>
                    <span
                      style={{
                        fontSize: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      Microsoft Excel (.CSV, .TSV)
                    </span>
                  </>
                ) : (
                  <>
                    <Button
                      className={classess.page__fileUpload__not_uploadBtn}
                      sx={{
                        mt: 2,
                      }}
                      disabled
                      startIcon={<ReactSVG src={UploadFileIcon} />}
                    >
                      Upload Report
                    </Button>
                    <span
                      style={{
                        fontSize: "11px",
                        marginLeft: "10px",
                        marginTop: "5px",
                      }}
                    >
                      Microsoft Excel (.CSV, .TSV)
                    </span>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>

      <DeleteConformationDialog
        open={openDeleteModal}
        onClose={hanldeDeleteFile}
        name=""
        artist={""}
        delete_new_music_records=""
        deleteUploadFile={deleteFile}
      />
      <SendContractDialog
        onClose={handleClose}
        open={open}
        setIsEmail={setIsEmail}
        IsEmail={IsEmail}
        convertHtmlToDoc={convertHtmlToDoc}
      />
    </React.Fragment>
  );
};

export default SendConfig;
