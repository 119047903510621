/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import classess from "./style.module.scss";
import PreLoaderImg from "../../assets/LoginTopLogoBlack.png";

const PreLoader = () => {
  return (
    <div className={classess.page}>
      <img src={PreLoaderImg} className={classess.page__center_img} />
    </div>
  );
};

export default PreLoader;
