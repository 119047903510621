import Slider from "@mui/material/Slider";
import { makeStyles, withStyles } from "@mui/styles";
import {
  Select,
  TextField,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Tabs,
  MenuItem,
  Radio,
} from "@mui/material";

const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#00135a",
    borderRadius: "4px",
  },
};

const muiTableCellUseStyles = makeStyles({
  row: {
    // borderRadius: "12px",
    "& .MuiTableCell-root": {
      borderBottom: "none",
      backgroundColor: "#192233",
    },
  },
});

const muiTableCellUseStylesforBorderRedius = makeStyles({
  row: {
    "&  .MuiTableCell-root": {
      borderBottom: "none",
      borderRadius: "12px 12px 12px 12px ",
    },
  },
});

const FabStyles = makeStyles({
  addButton: {
    backgroundColor: "#4FFCB7 !important",
    "&:hover": {
      backgroundColor: "#4FFCB7 !important",
    },
  },
});

const dialogBodyUseStyles = makeStyles({
  dialog: {
    "& .MuiPaper-root": {
      background: "#000",
      borderRadius: 12,
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "none",
      backdropFilter: "blur(10px)",
    },
  },
});

const scrollbarUseStyles = makeStyles({
  global: {
    "*::-webkit-scrollbar": {
      width: "8px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "none",
      background: "transparent",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#00135a",
      borderRadius: "12px",
    },
  },
});

const linearProgressBarUseStyles = makeStyles(() => ({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      borderRadius: "5px !important",
      height: "6px  !important",
      backgroundColor: "white  !important",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      background:
        "transparent linear-gradient(90deg, #36A1FF 0%, #FF64B7 100%) 0% 0% no-repeat padding-box !important",
    },
  },
}));

const myArtistAddBackdropUseStyles = makeStyles(() => ({
  root: {
    "& css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
      backgroundColor: "rgba(0, 0, 0, 0.9)",
    },
  },
}));

const sideBarUseStyles = makeStyles(() => ({
  root: {
    "& .css-1ox7mnu-MuiTypography-root": {
      fontFamily: "DM Sans",
    },
  },
}));

const allArtistsUseStyles = makeStyles(() => ({
  root: {
    "& .css-y8ay40-MuiTableCell-root": {
      fontFamily: "DM Sans",
    },
    "& .css-1ex1afd-MuiTableCell-root": {
      fontFamily: "DM Sans",
    },
  },
}));

const viewArtistUseStyles = makeStyles(() => ({
  root: {
    "& .css-ahj2mt-MuiTypography-root": {
      fontFamily: "DM Sans",
    },
    "& .css-17we3i3-MuiStack-root>:not(style)+:not(style)": {
      margin: "5px 5px 5px 5px",
    },
    "& .css-y8ay40-MuiTableCell-root": {
      fontFamily: "DM Sans",
    },
    "& .css-1ex1afd-MuiTableCell-root": {
      fontFamily: "DM Sans",
    },
  },
}));

const viewFundingDashboardSelectUseStyles = makeStyles(() => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: "transparent",
      width: 150,
      height: 30,
      color: "#fff !important",
      "&::after": {
        content: "''",
        display: "block",
        height: "5px",
        borderRadius: "2px",
        background: "#4ffcb7",
        bottom: "0px",
        position: "absolute",
        width: "90%",
        transition: "all 0.9s ease-in-out !important",
      },
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
    "& .MuiStepLabel-label.Mui-disabled": {
      color: "#8e929d",
    },

    "& .MuiStep-root": {
      backgroundColor: "#222C41",
      border: "1px solid #4FFCB7",
    },
    "& .MuiStep-root.Mui-completed": {
      backgroundColor: "#4FFCB7",
      border: "1px solid #4FFCB7",
    },
    "& .MuiTabs-root": {
      padding: "10px",
      backgroundColor: "transparent",
      borderRadius: 12,
      // width: "fit-content",
      // overflowX: "auto",
    },

    "& .css-19m4u4b-MuiButtonBase-root-MuiTab-root": {
      color: "#ffffff !important",
    },
    "&  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root": {
      color: "#ffffff !important",
      minHeight: "35px",
      fontFamily: "DM Sans",
      // minWidth: '160px !important'
    },
    "&  .css-1v7vp3m-MuiStep-root .MuiStepLabel-root .Mui-completed": {
      color: "#000 !important",
    },
  },
}));

const CustomTabs = withStyles({
  root: {
    background: "rgba(255, 255, 255, 0.16) !important",
    backdropFilter: "blur(10px) !important",
    borderRadius: "12px",
    padding: "2px 10px !important",
  },
})(Tabs);

const CustomSliderWithStyles = withStyles({
  root: {
    "& .MuiSlider-rail": {
      backgroundColor: "var(--base-border-line-color)",
    },
    "& .MuiSlider-thumb": {
      backgroundColor: "#ffff",
      width: "12px",
      height: "12px",
    },
    "& .MuiSlider-track": {
      borderColor: "var(--base-tags-color)",
      backgroundColor: "var(--base-tags-color)",
      height: "2px",
    },
  },
})(Slider);

const CustomTextField = withStyles({
  root: {
    height: "48px",
    width: "100%",
    "& .MuiInputBase-input": {
      "&::placeholder": {
        color: "var(--base-input-text-color)",
        opacity: "0.6",
      },
      "&.Mui-disabled": {
        color: "ffffff", // Change the text color for disabled state
      },
    },
    "& .MuiOutlinedInput-root": {
      color: "var(--base-input-text-color)",
      height: "48px",
      background: "var(--base-input-background-color)",
      width: "100%",
      borderRadius: "12px",
      fontSize: "16px",
      "& fieldset": {
        borderRadius: "12px",
        borderColor: "var(--base-border-line-color)",
      },
      "&:hover fieldset": {
        borderColor: "var(--base-border-line-color)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid var(--base-input-focus-color)",
        transition: "all 0.2s ease-in-out",
      },
    },
    "& :-webkit-autofill": {
      height: "15px ",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      "& > fieldset": {
        borderColor: "var(--base-input-border-color) ",
      },
      "& .MuiInputBase-input": {
        color: "#ffffff", // Applying disabled text color here too
      },
    },
  },
})(TextField);

const CustomAmountTextField = withStyles({
  root: {
    height: "48px",
    // width: "100%",

    "& .MuiInputBase-input": {
      direction: "rtl", // or dir attribute is rtl in input tag
      textAlign: "start",
      "&::placeholder": {
        color: "#ffffff",
        opacity: "0.6",
      },
      "&.Mui-disabled": {
        color: "ffffff", // Change the text color for disabled state
      },
    },
    "& .MuiOutlinedInput-root": {
      color: "#ffffff",
      height: "48px",
      // background: "#000",
      background: "#222c41",
      // width: "100%",
      borderRadius: "12px",
      fontSize: "28px",
      "& fieldset": {
        border: "none",
        borderRadius: "12px",
        borderColor: "var(--base-border-line-color)",
      },
      "&:hover fieldset": {
        borderColor: "var(--base-border-line-color)",
      },
      "&.Mui-focused fieldset": {
        border: "none",
        // border: "1px solid var(--base-input-focus-color)",
        transition: "all 0.2s ease-in-out",
      },
    },
    "& :-webkit-autofill": {
      height: "15px ",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      "& > fieldset": {
        borderColor: "var(--base-input-border-color) ",
      },
      "& .MuiInputBase-input": {
        color: "#ffffff", // Applying disabled text color here too
      },
    },
  },
})(TextField);

const CustomAuthTextField = withStyles({
  root: {
    height: "40px !important",
    width: "100%",
    outline: "none",
    "& .MuiInputBase-input": {},
    "& .MuiOutlinedInput-root": {
      color: "#000000",
      height: "40px",

      background: "#ffffff",
      width: "100%",
      borderRadius: "12px",
      fontSize: "14px",
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {},
      "&.Mui-focused fieldset": {
        border: "2px solid var(--base-input-focus-color)",
      },
    },
    "& :-webkit-autofill": {
      height: "7px",
      WebkitBoxShadow: "0 0 0 1000px white inset",
      WebkitTextFillColor: "#000000",
      WebkitTransition: "background-color 5000s ease-in-out 0s",
    },

    "& .MuiInputBase-root.Mui-disabled": {
      "& > fieldset": {
        color: "white !important",
      },
    },
  },
})(TextField);

const CustomDropdown = withStyles({
  root: {
    fontSize: "14px !important",
    fontWeight: "bold important",
    borderRadius: "12px !important",
    backgroundColor: "var(--base-tags-color) !important",
    color: "var(--base-button-text-color) !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--base-input-focus-color) !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--base-input-focus-color) !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--base-input-focus-color) !important",
    },
    "&.Mui-focused .MuiSelect-select": {
      color: "var(--base-button-text-color) !important",
    },
  },
  icon: {},
  select: {
    width: "100%",
    paddingRight: "0px !important !important",
    fontWeight: "bold important",
    color: "var(--base-button-text-color) !important",
  },
})(Select);

const CustomSelectMonth = withStyles({
  root: {
    height: "30px !important",
    width: "150px !important",
    fontSize: "16px !important",
    backgroundColor: "transparent!important",
    color: "var(--base-input-text-color) !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderBottom: "1px solid #fff !important",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderRadius: "0px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderBottom: "1px solid #fff !important",
      outline: "none !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderBottom: "1px solid var(--base-input-focus-color) !important",
    },
    "&.Mui-focused .MuiSelect-select": {
      color: "none !important",
    },
  },
  icon: {
    fill: "var(--base-input-icon-color) !important",
  },
  select: {
    paddingRight: "0px  !important",
    color: "var(--base-input-text-color) !important",
    fontWeight: "500 !important ",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
})(Select);

const CustomSelect = withStyles((theme) => ({
  root: {
    height: "48px !important",
    width: "100% !important",
    fontSize: "16px !important",
    borderRadius: "12px !important",
    backgroundColor: "var(--base-input-background-color) !important",
    color: "var(--base-input-text-color) !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--base-border-line-color) !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--base-border-line-color) !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--base-input-focus-color) !important",
    },
    "&.Mui-focused .MuiSelect-select": {
      color: "var(--base-input-text-color) !important",
    },
  },
  icon: {
    fill: "var(--base-input-icon-color) !important",
  },
  select: {
    paddingRight: "0px !important",
    color: "var(--base-input-text-color) !important",
    fontWeight: "500 !important ",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  paper: scrollbarStyles,
}))(Select);

const CustomFilterSelect = withStyles({
  root: {
    height: "40px !important",
    width: "120px !important",
    fontSize: "15px !important",
    borderRadius: "12px !important",
    backgroundColor: "var(--base-select-background-color) !important",
    color: "var(--base-text-color) !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "none !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "none!important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none!important",
    },
    "&.Mui-focused .MuiSelect-select": {
      // color: "var(--base-input-text-color) !important",
    },
  },
  icon: {
    fill: "var(--base-input-icon-color) !important",
  },
  select: {
    paddingRight: "0px !important",
    color: "var(--base-text-color) !important",
    fontWeight: "500 !important ",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
})(Select);

const CustomFilterSelectArtist = withStyles({
  root: {
    height: "40px !important",
    width: "150px !important",
    fontSize: "15px !important",
    borderRadius: "12px !important",
    // backgroundColor: "#97284F !important",
    backgroundColor: "var(--base-input-focus-color) !important",
    color: "#000000 !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid var(--base-input-focus-color) !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid var(--base-input-focus-color) !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid var(--base-input-focus-color) !important",
    },
    "&.Mui-focused .MuiSelect-select": {
      color: "var(--base-input-text-color) !important",
    },
  },
  icon: {
    fill: "var(--base-input-icon-color) !important",
  },
  select: {
    paddingRight: "0px !important",
    color: "var(--base-hover-text-color) !important",
    fontWeight: "500 !important ",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
})(Select);

const CustomMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: "var(--base-input-background-color)", // normal background color
    color: "var(--base-input-text-color)", // normal text color
    "&:hover": {
      backgroundColor: "var(--base-hover-background-color) !important", // hover background color
      color: "var(--base-hover-text-color) !important", // hover text color
    },
  },
  selected: {
    backgroundColor: "var(--base-input-focus-color) !important", // hover background color
    color: "var(--base-hover-text-color)", // hover text color
    // "&:hover": {
    //   backgroundColor: "var(--base-hover-background-color)", // hover background color
    //   color: "var(--base-hover-text-color) !important", // hover text color
    // },
  },
}))(MenuItem);

const CustomMenuItemArtist = withStyles((theme) => ({
  root: {
    marginTop: "5px !important",
    fontSize: "13px !important",
    fontWeight: "700 !important",
    textTransform: "uppercase !important",
    color: "#fff !important",
    lineHeight: "10px !important",
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "var(--base-hover-background-color) !important", // hover background color
      color: "var(--base-hover-text-color) !important", // hover text color
    },
  },
  selected: {
    backgroundColor: "var(--base-input-focus-color) !important", // hover background color
    color: "var(--base-hover-text-color)", // hover text color
  },
  disabled: {
    backgroundColor: "transparent !important", // red background color for disabled items
    color: "var(--base-hover-text-color)", // disabled text color
  },
}))(MenuItem);

const CustomEmailSelect = withStyles({
  root: {
    height: "45px !important",
    width: "100% !important",
    fontSize: "16px !important",
    borderRadius: "12px !important",
    backgroundColor: "var(--base-input-background-color) !important",
    color: "var(--base-tags-color) !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--base-input-focus-color) !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--base-input-focus-color) !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--base-input-focus-color) !important",
    },
    "&.Mui-focused .MuiSelect-select": {
      color: "var(--base-tags-color) !important",
    },
  },
  icon: {
    fill: "var(--base-input-icon-color) !important",
  },
  select: {
    paddingRight: "0px !important !important",
    color: "var(--base-tags-color) !important",
    fontWeight: "500 !important ",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
})(Select);

const CustomEmailMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: "var(--base-input-background-color)", // normal background color
    color: "var(--base-input-color)", // normal text color
    "&:hover": {
      // backgroundColor: "var(--base-hover-background-color) !important", // hover background color
      // color: "var(--base-hover-text-color) !important", // hover text color
    },
  },
  selected: {
    // backgroundColor: "var(--base-input-focus-color) !important", // hover background color
    // color: "var(--base-hover-text-color)", // hover text color
  },
}))(MenuItem);

const CustomFormSelect = withStyles({
  root: {
    width: "100%",
    height: "48px !important",
    fontSize: "16px !important",
    borderRadius: "12px !important",
    backgroundColor: "var(--base-input-background-color)",
    border: "1px solid var(--base-border-line-color)",
    "&:focus-within": {
      borderColor: "var(--base-input-focus-color) !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  icon: {
    // fill: "red !important",
  },

  select: {
    color: "var(--base-input-text-color) !important",
  },
})(Select);

const CustomCheckbox = withStyles({
  root: {
    color: "var(--checkbox-uncheck-color) !important",
    "&.Mui-checked": {
      color: "var(--checkbox-check-color) !important",
    },
  },
})(Checkbox);

const CustomRadioButton = withStyles({
  root: {
    color: "var(--checkbox-check-color) !important",
    "&.Mui-checked": {
      color: "var(--checkbox-check-color) !important",
    },
  },
})(Radio);

//////////////////////////////////////Table//////////////////////////////////////
const CustomTableContainer = withStyles((theme) => ({
  root: {
    // width: "100%",
    backgroundColor: "var(--base-container-backgroung-color) !important",
    // overflowX: "auto",
    ".MuiTableContainer-root": {},
  },
}))(TableContainer);

const CustomTable = withStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {},
  },
}))(Table);

const CustomTableBody = withStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {},
  },
}))(TableBody);

const CustomTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none !important",
    // backgroundColor: "red !important",
    backgroundColor: "var(--base-inner-container-background-color) !important",
  },
}))(TableCell);

const CustomTableCellHeader = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid var(--base-border-line-color) !important",
    backgroundColor: "var(--base-container-backgroung-color) !important",
    color: "var(--base-tags-color) !important",
    textTransform: "uppercase",
    fontSize: "12px !important",
  },
}))(TableCell);

const CustomTableHead = withStyles((theme) => ({
  root: {
    position: "sticky",
    zIndex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "1px solid var(--base-border-line-color) !important",
  },
}))(TableHead);

const CustomTableRow = withStyles((theme) => ({
  root: {
    // backgroundColor: "var(--base-inner-container-background-color) !important",
    height: "70px",
    borderBottom: "none !important",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // borderRadius: "12px !important",
    "&:first-child": {
      borderTopLeftRadius: "12px !important",
      borderTopRightRadius: "12px !important",
    },
    "&:last-child": {
      borderBottomLeftRadius: "12px !important",
      borderBottomRightRadius: "12px !important",
    },
  },
}))(TableRow);

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      overflow: "auto",
    },
    classes: {
      root: scrollbarStyles, // Applying custom scrollbar styles
    },
  },
};

function EnhancedSelect(props) {
  return <CustomSelect {...props} MenuProps={menuProps} />;
}

const CustomTableContainerUpload = withStyles((theme) => ({
  root: {
    // width: "100%",
    backgroundColor: "var(--base-inner-container-background-color) !important",
    padding: "20px",
    ".MuiTableContainer-root": {},
  },
}))(TableContainer);

const CustomTableCellHeaderUpload = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid var(--accordian-table-bottom-border) !important",
    backgroundColor: "transparent !important",
    color: "var(--base-tags-color) !important",
    textTransform: "uppercase",
    fontSize: "11px !important",
    paddingBottom: "5px !important",
  },
}))(TableCell);
const CustomTableCellUpload = withStyles((theme) => ({
  root: {
    color: "#fff !important",
    fontSize: "15px !important",
    fontWeight: "700 !important",
    borderBottom: "1px solid var(--accordian-table-bottom-border) !important",
    backgroundColor: "transparent !important",
    textTransform: "capitalize !important",
  },
}))(TableCell);

export {
  muiTableCellUseStyles,
  linearProgressBarUseStyles,
  myArtistAddBackdropUseStyles,
  viewFundingDashboardSelectUseStyles,
  CustomSliderWithStyles,
  dialogBodyUseStyles,
  scrollbarUseStyles,
  FabStyles,
  viewArtistUseStyles,
  sideBarUseStyles,
  allArtistsUseStyles,
  muiTableCellUseStylesforBorderRedius,
  CustomAuthTextField,
  CustomFilterSelectArtist,
  CustomMenuItemArtist,
  CustomTextField,
  CustomDropdown,
  CustomSelect,
  CustomFormSelect,
  CustomFilterSelect,
  CustomTableContainer,
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
  CustomCheckbox,
  CustomTabs,
  CustomMenuItem,
  CustomEmailSelect,
  CustomEmailMenuItem,
  CustomRadioButton,
  CustomSelectMonth,
  CustomAmountTextField,
  EnhancedSelect,
  CustomTableContainerUpload,
  CustomTableCellHeaderUpload,
  CustomTableCellUpload,
};
