/* eslint-disable react-hooks/exhaustive-deps */

// React and Hooks
import React, { useEffect, useState } from "react";

// Material-UI components
import {
  Avatar,
  Box,
  Chip,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

// Router
import { useNavigate, useParams } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getTrackByID } from "../../redux/slice/artist";

// Styling
import classes from "./style.module.scss";

// Assets
import BackIcon from "../../assets/BackIcon.png";
import youtubeIcon from "../../assets/social/social-icon2.png";
import spotifyIcon from "../../assets/social/social-icon3.png";
import deezerIcon from "../../assets/social/social-icon5.png";
import tiktokIcon from "../../assets/social/social-icon8.png";
import Soundcloud from "../../assets/social/social-icon4.png";
import Threedotmenu from "../../assets/threedotmenu.png";
import GrowthArrowUp from "../../assets/growthArrowUp.png";
import NewLoader from "../../components/lottie-loader/Loader.json";
import { FaArrowLeft } from "react-icons/fa6";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

// Libraries
import axios from "axios";
import { MotionConfig, motion } from "framer-motion";
import Lottie from "lottie-react";

// Environment Config
import { config as URLconfig } from "../../enviorment/enviorment";

// Helper Functions
import { addCommasToNumber } from "../../utils/helper";

// Graphs
import GenreGraph from "../graph/streamGraph/graph";
import TiktokGraph from "./Graph/TiktokGraph";
import SpotifyGraph from "./Graph/SpotifyGraph";
import YoutubeGraph from "./Graph/YoutubeGraph";
import DeezerGraph from "./Graph/DeezerGraph";
import SoundCloudGraph from "./Graph/SoundCloudGraph";

// Local Storage Service
import { getItemToLocalStorage } from "../../services/storage";

// Enums
import AuthEnum from "../../enums/auth.enum";
import CustomTwoTabs from "../../components/custom-two-tabs/custom-two-tabs";

// Component
const Track = () => {
  // Router hooks
  const { id } = useParams();
  const navigate = useNavigate();

  // Redux hooks
  const dispatch = useDispatch();
  const SingleTrackData = useSelector((state) => state.artist.track);
  const load = useSelector((state) => state.artist.status);
  // State hooks
  const [track_funding_est, Settrack_funding_est] = useState(0);
  const [TotalYearlyInome, setTotalYearlyInome] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [Spotifystate, setSpotifystate] = useState({});
  const [youtubeState, setyoutubeState] = useState({});
  const [openMenu, setOpenMenu] = useState(null);

  // Number formatter
  const internationalNumberFormat = new Intl.NumberFormat("en-US");

  // Genre data processing
  const genress = SingleTrackData?.genres;
  // const genresArray = genress ? genress.split(",") : [];
  const [activeTab, setActiveTab] = useState(1);

  // Function to Handle Tab Click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  // Toggle menu function
  const toggleMenu = (menu) => {
    setOpenMenu((prevState) => (prevState === menu ? null : menu));
  };

  // API calls and calculations
  const calculate_tract_estimate = async (data, artist_funding_data) => {
    try {
      let options = {
        headers: {
          authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
        },
      };
      const new_music_tracks = [];
      const val = {
        included_music: artist_funding_data?.included_music,
        contract_length: artist_funding_data?.contract_length,
        catelog_income: artist_funding_data?.catelog_income,
        new_music_income: artist_funding_data?.new_music_income,
        selected_tracks: [data],
        new_music_tracks,
      };
      if (artist_funding_data) {
        const response = await axios.post(
          `${URLconfig.BASE_URL}/artist-funding/${SingleTrackData?.spotify_artist_id}/customize`,
          val,
          options
        );
        Settrack_funding_est(response.data.data.funding);
      }
      setIsLoading(true);
    } catch (error) {
      console.log("Error Of Customize Funding " + error);
    }
  };

  const calculate_tract_yearly_income = async (track_id) => {
    try {
      let options = {
        headers: {
          authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
        },
      };
      const data = { tracksId: [track_id] };
      const response = await axios.post(
        `${URLconfig.BASE_URL}/artist-funding/${SingleTrackData?.spotify_artist_id}/MonthlyCalculation`,
        data,
        options
      );
      const income = response.data.data;
      delete income["2023-10"];
      const totalIncome = Object.values(income).reduce(
        (acc, item) => acc + item.income,
        0
      );
      setTotalYearlyInome(totalIncome);
    } catch (error) {
      console.log("Error Of Customize Funding " + error);
    }
  };

  const createdataforSpotify = (data) => {
    const timestamps = data.map((item) =>
      new Date(item?.timestp || item?.date || new Date()).getTime()
    );
    const minTimestamp = Math.min(...timestamps);
    const maxTimestamp = Math.max(...timestamps);
    const chartData = data.map((item) => {
      const timestamp = item?.timestp || item?.date;
      const isValidTimestamp =
        timestamp && !isNaN(new Date(timestamp).getTime());

      return [
        isValidTimestamp ? new Date(timestamp).getTime() : null,
        Math.round(
          item?.daily_diff !== undefined
            ? item?.daily_diff
            : item?.streams_total || 0
        ),
      ];
    });
    const minDate = new Date(minTimestamp).toISOString().split("T")[0];
    const maxDate = new Date(maxTimestamp).toISOString().split("T")[0];
    const chartConfig = {
      data: chartData,
      minDate,
      maxDate,
    };
    setSpotifystate(chartConfig);
  };
  const createdataforyoutube = (data) => {
    const timestamps = data.map((item) =>
      new Date(item?.timestp || item?.date || new Date()).getTime()
    );
    const minTimestamp = Math.min(...timestamps);
    const maxTimestamp = Math.max(...timestamps);
    const chartData = data.map((item) => {
      const timestamp = item?.timestp || item?.date;
      const isValidTimestamp =
        timestamp && !isNaN(new Date(timestamp).getTime());

      return [
        isValidTimestamp ? new Date(timestamp).getTime() : null,
        Math.round(
          item?.diff !== undefined ? item?.diff : item?.video_views_total || 0
        ),
      ];
    });
    const minDate = new Date(minTimestamp).toISOString().split("T")[0];
    const maxDate = new Date(maxTimestamp).toISOString().split("T")[0];
    const chartConfig = {
      data: chartData,
      minDate,
      maxDate,
    };
    setyoutubeState(chartConfig);
  };

  const getArtistFundingMetaData = async (spotify_id) => {
    let endpoint = `${URLconfig.BASE_URL}/artist-funding/${spotify_id}`;
    const artist_funding_response = await axios.get(endpoint);
    const artist_funding_data = artist_funding_response.data["data"];
    calculate_tract_estimate(SingleTrackData, artist_funding_data);
  };

  // Initial fetch of track data
  useEffect(() => {
    dispatch(getTrackByID({ id: id }));
  }, [id]);

  // Calculations after fetching track data
  useEffect(() => {
    calculate_tract_yearly_income(id);
    getArtistFundingMetaData(SingleTrackData?.spotify_artist_id);
    if (SingleTrackData?.historic?.spotify) {
      createdataforSpotify(SingleTrackData?.historic?.spotify);

      if (SingleTrackData?.historic?.youtube.length > 0) {
        createdataforyoutube(SingleTrackData?.historic?.youtube);
      }
    }
  }, [SingleTrackData]);
  const detailData = {
    detail1: [
      {
        text: "Artist",
        value: "Brandz",
        count: true,
      },
      {
        text: "YEARLY ESTIMATE",
        value: `$ ${addCommasToNumber(TotalYearlyInome)}`,
      },
      {
        text: "FUNDING ESTIMATE",
        value: `$ ${internationalNumberFormat.format(track_funding_est)}`,
        borderRight: "none !important",
      },
    ],
    detail2: [
      {
        text: "OWNERSHIP PERCENTAGE",
        value: `${
          SingleTrackData?.stream_income_share === null
            ? null
            : SingleTrackData?.stream_income_share
        }%`,
      },
      {
        text: "ISRC CODE",
        value: SingleTrackData?.isrc === null ? null : SingleTrackData?.isrc,
      },
      {
        text: "TRACK TYPE",
        value:
          SingleTrackData?.track_type === null
            ? null
            : SingleTrackData?.track_type,
        borderRight: "none !important",
      },
    ],
  };
  return (
    <Container maxWidth="xxl">
      <Grid container spacing={2} className={classes.page}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          xl={9}
          className={classes.page__details}
        >
          <Box
            varient="div"
            component="div"
            className={classes.page__details__box}
          >
            <Box
              varient="div"
              component="div"
              className={classes.page__details__box__tracks}
            >
              {/* Top header */}
              <Box
                varient="div"
                component="div"
                className={classes.page__details__box__tracks__header}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Box
                    className={
                      classes.page__details__box__tracks__header__back_icon
                    }
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <FaArrowLeft />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <Avatar
                        variant="square"
                        src={SingleTrackData?.track_img}
                        className={
                          classes.page__details__box__tracks__header__avatar
                        }
                      />
                    </Box>
                    <Box>
                      {load === "loading" ? (
                        <Loader />
                      ) : (
                        <>
                          <Typography
                            className={
                              classes.page__details__box__tracks__header__title
                            }
                          >
                            {SingleTrackData?.title}
                          </Typography>
                          <Chip
                            label={
                              SingleTrackData?.genres
                                ? SingleTrackData?.genres
                                : "N/A"
                            }
                            className={
                              classes.page__details__box__tracks__header__chip
                            }
                          />
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <Typography
                    className={classes.page__details__box__tracks__header__text}
                  >
                    RELEASE DATE
                  </Typography>
                  <Typography
                    className={classes.page__details__box__tracks__header__date}
                  >
                    {SingleTrackData?.release_date}
                  </Typography>
                </Box>
              </Box>
              {/* Middle detail */}
              <Box className={classes.page__details__box__tracks__details}>
                <Box
                  className={
                    classes.page__details__box__tracks__details__detail1
                  }
                >
                  {detailData?.detail1?.map((item, index) => (
                    <Box
                      key={index}
                      variant="div"
                      component="div"
                      className={
                        classes.page__details__box__tracks__details__detail1__container
                      }
                      sx={{
                        borderRight: item?.borderRight,
                      }}
                    >
                      <span
                        className={
                          classes.page__details__box__tracks__details__detail1__container__text
                        }
                      >
                        {item?.text}
                      </span>
                      {load === "loading" ? (
                        <Loader />
                      ) : (
                        <span
                          className={
                            classes.page__details__box__tracks__details__detail1__container__value
                          }
                        >
                          {item?.value}
                          {item?.count &&
                            SingleTrackData?.artists?.length > 0 && (
                              <div
                                className={
                                  classes.page__details__box__tracks__details__detail1__container__count
                                }
                              >
                                +{SingleTrackData?.artists?.length}
                              </div>
                            )}
                        </span>
                      )}
                    </Box>
                  ))}
                </Box>

                <Box
                  className={
                    classes.page__details__box__tracks__details__detail1
                  }
                  sx={{
                    borderBottom: "none !important",
                  }}
                >
                  {detailData?.detail2?.map((item, index) => (
                    <Box
                      key={index}
                      variant="div"
                      component="div"
                      className={
                        classes.page__details__box__tracks__details__detail1__container
                      }
                      sx={{
                        borderRight: item?.borderRight,
                      }}
                    >
                      <span
                        className={
                          classes.page__details__box__tracks__details__detail1__container__text
                        }
                      >
                        {item?.text}
                      </span>
                      {load === "loading" ? (
                        <Loader />
                      ) : (
                        <span
                          className={
                            classes.page__details__box__tracks__details__detail1__container__value
                          }
                        >
                          {item?.value ? item?.value : "N/A"}
                        </span>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>

              {/* Bottom charts */}
              <Box
                className={classes.page__details__box__tracks__stream_container}
              >
                {SingleTrackData === null ||
                SingleTrackData === "" ||
                isLoading === false ? (
                  <Box
                    varient="div"
                    component="div"
                    className={classes.page__loader_container}
                  >
                    <Loader />
                  </Box>
                ) : (
                  <>
                    <Box
                      className={
                        classes.page__details__box__tracks__stream_container__header
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          className={
                            classes.page__details__box__tracks__stream_container__header__heading
                          }
                        >
                          Chart History
                        </Box>
                        {/* <Box>
                          <CustomTwoTabs
                            text_0={"STREAMS"}
                            text_1={"EARNINGS"}
                            btn_0={activeTab === 1}
                            btn_1={activeTab === 2}
                            click_0={() => handleTabClick(1)}
                            click_1={() => handleTabClick(2)}
                            padding={"10px 28px"}
                          />
                        </Box> */}
                        <Box
                          className={
                            classes.page__details__box__tracks__stream_container__header__streams
                          }
                        >
                          streams
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      className={
                        classes.page__details__box__tracks__stream_container__box_container
                      }
                    >
                      {activeTab === 1 && (
                        <>
                          <CustomBux
                            icon={spotifyIcon}
                            title={"Spotify"}
                            streams={SingleTrackData?.spotify_streams_total}
                            open={openMenu === "spotify"}
                            onclick={() => toggleMenu("spotify")}
                            graph={<SpotifyGraph data={Spotifystate} />}
                          />
                          <CustomBux
                            icon={youtubeIcon}
                            title={"YouTube"}
                            streams={SingleTrackData?.youtube_video_views_total}
                            open={openMenu === "youtube"}
                            onclick={() => toggleMenu("youtube")}
                            graph={<YoutubeGraph data={youtubeState} />}
                          />

                          <CustomBux
                            icon={tiktokIcon}
                            title={"Tiktok"}
                            streams={SingleTrackData?.tiktok_views_total}
                            open={openMenu === "tiktok"}
                            onclick={() => toggleMenu("tiktok")}
                            graph={<TiktokGraph />}
                          />

                          <CustomBux
                            icon={deezerIcon}
                            title={"Deezer"}
                            streams={SingleTrackData?.deezer_reach_total}
                            open={openMenu === "deezer"}
                            onclick={() => toggleMenu("deezer")}
                            graph={<DeezerGraph />}
                          />
                          <CustomBux
                            icon={Soundcloud}
                            title={"Soundcloud"}
                            streams={"536742"}
                            open={openMenu === "soundcloud"}
                            onclick={() => toggleMenu("soundcloud")}
                            graph={<SoundCloudGraph />}
                          />
                        </>
                      )}
                      {activeTab === 2 && "Earnings graph here"}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Track;
const Loader = () => {
  return (
    <Box
      sx={{
        width: "40px",
        height: "40px",
      }}
    >
      <Lottie
        animationData={NewLoader}
        className={classes.page__loader_container__loader}
      />
    </Box>
  );
};
const CustomBux = ({ icon, title, streams, open, onclick, graph }) => {
  return (
    <MotionConfig>
      <motion.div
        className={
          classes.page__details__box__tracks__stream_container__box_container__box
        }
        style={{
          overflow: "hidden",
        }}
        initial={{ height: "80px" }}
        animate={{
          height: open ? "500px" : "80px",
        }}
        transition={{ duration: 0.3 }}
      >
        <Box
          className={
            classes.page__details__box__tracks__stream_container__box_container__box__header
          }
        >
          <Box
            className={
              classes.page__details__box__tracks__stream_container__box_container__box__header__icon_title_container
            }
          >
            <img
              src={icon}
              alt="Icon"
              className={
                classes.page__details__box__tracks__stream_container__box_container__box__header__icon_title_container__icon
              }
            />

            <span
              className={
                classes.page__details__box__tracks__stream_container__box_container__box__header__icon_title_container__title
              }
            >
              {title}
            </span>
          </Box>
          <Box
            className={
              classes.page__details__box__tracks__stream_container__box_container__box__header__icon_container
            }
          >
            <Box
              className={
                classes.page__details__box__tracks__stream_container__box_container__box__streams_container
              }
            >
              {" "}
              <img
                src={GrowthArrowUp}
                alt="growth icon"
                style={{ width: "10px", height: "7.92" }}
              />
              <span
                className={
                  classes.page__details__box__tracks__stream_container__box_container__box__streams_container__title
                }
              >
                {addCommasToNumber(streams)}
              </span>
            </Box>
            {open ? (
              <IoIosArrowDown
                className={
                  classes.page__details__box__tracks__stream_container__box_container__box__header__icon_container__menu_icon
                }
                onClick={onclick}
              />
            ) : (
              <IoIosArrowForward
                className={
                  classes.page__details__box__tracks__stream_container__box_container__box__header__icon_container__menu_icon
                }
                onClick={onclick}
              />
            )}
          </Box>
        </Box>
        <Box
          className={
            classes.page__details__box__tracks__stream_container__box_container__box__buttom_container
          }
        >
          <span
            className={
              classes.page__details__box__tracks__stream_container__box_container__box__buttom_container__heading
            }
          >
            STREAMS
          </span>
        </Box>
        <Box
          className={
            classes.page__details__box__tracks__stream_container__box_container__box__country_data
          }
        >
          <Box
            className={
              classes.page__details__box__tracks__stream_container__box_container__box__country_data__graph
            }
          >
            {graph}
          </Box>
        </Box>
      </motion.div>
    </MotionConfig>
  );
};
