import React, { useEffect, useRef, useState } from "react";
import classess from "./style.module.scss";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import NewLoader from "../../../lottie-loader/Loader.json";
import Lottie from "lottie-react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import DownloadIcon from "../../../../assets/downloadIcon.svg";
import ReactCountryFlag from "react-country-flag";
import WorldIcon from "../../../../assets/buttonsicons/world.svg";

const DealSummary = ({
  totalFunding,
  isLoading,
  totalTracks,
  contract_length,
  publishEst,
  artist_advance,
  marketing_budget,
  RECOUPMENTPERIOD,
  RECOUPMENTPERIOD_IN_ENG,
  showPublishValue,
  new_music_estimiate,
  SingleCount,
  AlbumCount,
  EpCount,
  downloadPDF,
  isPending,
  showDownloadBtn,
  catelog_income,
  CountryWiseEst,
  tabValue,
}) => {
  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  const selectedTracksCount = useSelector(
    (state) => state.artist.selectedTracksCount
  );
  const status = useSelector((state) => state.artist.status);
  const isLoadingNew = useSelector((state) => state.artist.isLoadingNew);
  const [worldList, setWorldList] = useState();
  const [isIconActive, setIsIconActive] = useState(false);
  const ShowWorldList = () => {
    setWorldList(!worldList);
    setIsIconActive(!isIconActive);
  };
  const advanceValue = internationalNumberFormat.format(artist_advance) || "0";
  const marketingValue =
    internationalNumberFormat.format(marketing_budget) || "0";
  const advanceAmount = parseFloat(advanceValue.replace(/\D/g, ""));
  const marketingAmount = parseFloat(marketingValue.replace(/\D/g, ""));
  const sum = advanceAmount + marketingAmount;
  const costValue = "$" + internationalNumberFormat.format(sum);
  const countryRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (countryRef.current && !countryRef.current.contains(event.target)) {
        setWorldList(false);
        setIsIconActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const masterData = {
    marter1: [
      {
        heading: "overall EARNINGS",
        value: "$" + internationalNumberFormat.format(totalFunding),
        isLoading: isLoading,
        color: "#1BFB5E",
      },
      {
        heading: "Label EARNINGS",
        value: status === "succeeded" ? "N/A" : <Loader />,
      },
      {
        heading: "Artist EARNINGS",
        value: status === "succeeded" ? "N/A" : <Loader />,
      },
      {
        heading: "Roi",
        value:
          status === "succeeded" ? (
            "$" +
            (artist_advance === 0 || marketing_budget === 0
              ? 0
              : internationalNumberFormat.format(
                  Math.max(
                    totalFunding -
                      (parseInt(artist_advance) + parseInt(marketing_budget)),
                    0
                  )
                ))
          ) : (
            <Loader />
          ),
      },
      {
        heading: "RECOUPMENT PERIOD",
        value:
          status === "succeeded" ? (
            RECOUPMENTPERIOD + " Months ~ " + RECOUPMENTPERIOD_IN_ENG
          ) : (
            <Loader />
          ),
      },
    ],
    master2: [
      {
        heading: "cost",
        value: status === "succeeded" ? costValue : <Loader />,
        color: "#FF4757",
      },
      {
        heading: "Advance",
        value: "$" + advanceValue,
      },
      {
        heading: "Marketing",
        value: "$" + marketingValue,
      },
      {
        heading: tabValue === 2 ? null : "term",
        value:
          tabValue === 2 ? null : status === "succeeded" ? (
            `${contract_length} Year(s)`
          ) : (
            <Loader />
          ),
        // value:
        // status === "succeeded" ? `${contract_length} Year(s)` : <Loader />,
      },
      {
        heading: "selected Tracks",
        value:
          status === "succeeded" ? (
            selectedTracksCount + " of " + totalTracks
          ) : (
            <Loader />
          ),
      },
    ],
    newMusic: [
      {
        heading: "Earnings",
        value:
          new_music_estimiate < 0
            ? 0
            : "$" + internationalNumberFormat.format(new_music_estimiate),
        isLoading: isLoadingNew,
        // border: "none",
        // borderRight: "1px solid #5a7380",
      },
      {
        heading: "single",
        value: SingleCount,
      },
      {
        heading: "album",
        value: AlbumCount,
      },
      {
        heading: "eps",
        value: EpCount,
      },
    ],
  };
  const publishData = [
    {
      heading: "OVERALL EARNINGS",
      value:
        "$ " +
        internationalNumberFormat.format(
          publishEst.reduce((total, currentValue) => total + currentValue, 0)
        ),
      isLoading: isLoading,
      border: "none !important",
    },
  ];
  return (
    <Box className={classess.page}>
      <Box className={classess.page__container}>
        <Box className={classess.page__container__border_bottom}>
          <Box
            className={classess.page__container__top}
            sx={{
              padding: showDownloadBtn ? "16px 20px" : "20px",
            }}
          >
            <Typography className={classess.page__container__top__heading}>
              Deal Summary
            </Typography>
            {showDownloadBtn && (
              <Button
                className={classess.page__container__top__btn}
                startIcon={<ReactSVG src={DownloadIcon} />}
                onClick={downloadPDF}
              >
                {isPending ? "loading..." : "Memo"}
              </Button>
            )}
          </Box>
        </Box>
        <Box className={classess.page__container__new_layout}>
          <Box className={classess.page__container__new_layout__shares}>
            <Box
              className={classess.page__container__new_layout__shares__label}
            >
              <Typography
                className={
                  classess.page__container__new_layout__shares__heading
                }
              >
                Label share
              </Typography>
              <Typography
                className={classess.page__container__new_layout__shares__value}
              >
                {status === "succeeded" ? (
                  100 - catelog_income + ".00%"
                ) : (
                  <Loader />
                )}
              </Typography>
            </Box>
            <Box
              className={classess.page__container__new_layout__shares2__artist}
            >
              <Typography
                className={
                  classess.page__container__new_layout__shares2__heading
                }
              >
                Artist share
              </Typography>
              <Typography
                className={classess.page__container__new_layout__shares2__value}
              >
                {status === "succeeded" ? catelog_income + ".00%" : <Loader />}
              </Typography>
            </Box>
          </Box>
          <Box className={classess.page__container__new_layout__masters}>
            <Typography
              className={classess.page__container__new_layout__masters__heading}
            >
              masters
            </Typography>
          </Box>
          <Box className={classess.page__container__new_layout__details}>
            {masterData?.marter1.map((item, index) => (
              <DetailBox
                heading={item.heading}
                value={item.value}
                color={item.color}
                isLoading={item.isLoading}
                index={index}
                countryRef={countryRef}
                ShowWorldList={ShowWorldList}
                isIconActive={isIconActive}
                worldList={worldList}
                CountryWiseEst={CountryWiseEst}
                internationalNumberFormat={internationalNumberFormat}
              />
            ))}
          </Box>
          <Divider
            className={classess.page__container__new_layout__details__divider}
          />
          <Box className={classess.page__container__new_layout__details}>
            {masterData?.master2?.map((item, index) => (
              <DetailBox
                heading={item.heading}
                value={item.value}
                color={item.color}
                key={index}
              />
            ))}
          </Box>

          {!new_music_estimiate &&
          !SingleCount &&
          !AlbumCount &&
          !EpCount ? null : tabValue === 2 ? null : (
            <NewMusic masterData={masterData} newMusic={masterData.newMusic} />
          )}

          {showPublishValue ? (
            <React.Fragment>
              <Box className={classess.page__container__new_layout__masters}>
                <Typography
                  className={
                    classess.page__container__new_layout__masters__heading
                  }
                  sx={{
                    color: "var(--deal-summary-active-text-color) !important",
                    backgroundColor:
                      "var(--deal-summary-active-background) !important",
                  }}
                >
                  publishing
                </Typography>
              </Box>
              <Box
                className={`${classess.page__container__new_layout__details} ${classess.page__container__new_layout__details__publishing}`}
              >
                {publishData?.map((item, index) => (
                  <DetailBox
                    heading={item.heading}
                    value={item.value}
                    isLoading={item.isLoading}
                    border={item.border}
                    key={index}
                  />
                ))}
              </Box>
            </React.Fragment>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const CountriesList = ({
  countryRef,
  ShowWorldList,
  isIconActive,
  worldList,
  CountryWiseEst,
  internationalNumberFormat,
}) => {
  return (
    <Box ref={countryRef} className={classess.page__drop}>
      <Tooltip
        title="Earning by countries"
        placement="top"
        arrow
        enterDelay={100}
      >
        <IconButton
          onClick={ShowWorldList}
          sx={{
            padding: "0px",
          }}
        >
          <ReactSVG
            src={WorldIcon}
            alt="WorldIcon"
            style={{
              filter: isIconActive ? "grayscale(100%)" : "",
              fill: "var(--second-button-border-color)",
              width: "15px",
              height: "15px",
              margin: "-10px 5px 0px 0px",
            }}
            beforeInjection={(svg) => {
              svg.setAttribute("viewBox", "0 0 25 25"); // Adjust the viewBox attribute to resize the SVG icon
            }}
          />
        </IconButton>
      </Tooltip>
      {worldList && (
        <>
          <Box className={classess.page__drop__shape}>
            <Box className={classess.page__drop__worldList}>
              <Box>
                <Typography className={classess.page__drop__worldList__heading}>
                  EARNINGS BY COUNTRIES
                </Typography>
                <Box className={classess.page__drop__worldList__list}>
                  {CountryWiseEst?.map((item) =>
                    item?.earnings === 0 ? null : (
                      <Box
                        key={item?.countryCode}
                        className={classess.page__drop__worldList__list__items}
                      >
                        <Box
                          className={
                            classess.page__drop__worldList__list__items__flag
                          }
                        >
                          <ReactCountryFlag
                            countryCode={item?.countryCode}
                            svg
                          />
                          <Typography
                            className={
                              classess.page__drop__worldList__list__items__flag__text
                            }
                          >
                            {item?.countryCode}
                          </Typography>
                        </Box>
                        <Typography
                          className={
                            classess.page__drop__worldList__list__items__text
                          }
                        >
                          $
                          {internationalNumberFormat.format(
                            Math.round(item?.earnings)
                          )}
                        </Typography>
                      </Box>
                    )
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const Loader = () => {
  return (
    <Box>
      <Lottie animationData={NewLoader} className={classess.page__loader} />
    </Box>
  );
};
const NewMusic = ({ masterData, newMusic, color }) => {
  return (
    <React.Fragment>
      <Box
        className={classess.page__container__new_layout__masters}
        sx={{
          backgroundColor: "#1D59A0 !important",
        }}
      >
        <Typography
          className={classess.page__container__new_layout__masters__heading}
        >
          New music
        </Typography>
      </Box>
      <Box
        className={classess.page__container__new_layout__details}
        flexWrap={"nowrap"}
      >
        <Box width={"100%"}>
          {masterData?.newMusic?.slice(0, 1)?.map((item, index) => (
            <DetailBox
              heading={item.heading}
              value={item.value}
              color={item.color}
              isLoading={item.isLoading}
              border={item.border}
              borderRight={item.borderRight}
            />
          ))}
        </Box>
        <Box
          className={classess.page__container__new_layout__details__new_music}
        >
          {masterData.newMusic?.slice(1)?.map((item, index) => (
            <Box>
              <Typography
                className={
                  classess.page__container__new_layout__details__container__main__heading
                }
              >
                {item.heading}
              </Typography>
              <Typography
                className={
                  classess.page__container__new_layout__details__container__main__value
                }
                sx={{
                  color: color ? color : "#fff",
                  fontSize: "18px",
                }}
              >
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </React.Fragment>
  );
};
const DetailBox = ({
  heading,
  value,
  isLoading,
  countryRef,
  ShowWorldList,
  isIconActive,
  worldList,
  CountryWiseEst,
  internationalNumberFormat,
  index,
  color,
  border,
  borderRight,
}) => {
  return (
    <Box
      className={classess.page__container__new_layout__details__container}
      sx={{
        border: border,
        borderRight: borderRight,
        flexBasis: heading === "publishing" ? "100% !important" : "",
      }}
    >
      <Box
        className={
          classess.page__container__new_layout__details__container__main
        }
      >
        <Typography
          className={
            classess.page__container__new_layout__details__container__main__heading
          }
        >
          {heading}
        </Typography>
        <Typography
          className={
            classess.page__container__new_layout__details__container__main__value
          }
          sx={{
            fontSize: heading === "RECOUPMENT PERIOD" ? "17px" : "18px",
            color: color ? color : "#fff",
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Box display={"flex"} gap={"10px"} alignContent={"center"}>
              {index === 0 && (
                <CountriesList
                  countryRef={countryRef}
                  ShowWorldList={ShowWorldList}
                  isIconActive={isIconActive}
                  worldList={worldList}
                  CountryWiseEst={CountryWiseEst}
                  internationalNumberFormat={internationalNumberFormat}
                />
              )}
              <Box>{value}</Box>
            </Box>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default DealSummary;
