import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import classess from "./style.module.scss";
import Stack from "@mui/material/Stack";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/slice/modal";
// import { styled } from "@mui/material/styles";
// import InputBase from "@mui/material/InputBase";
import { config as URLconfig } from "../../enviorment/enviorment";
import Container from "@mui/material/Container";
// import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import TrackGraph from "./graph/TrackGraph";
import Lottie from "lottie-react";
import NewLoader from "../lottie-loader/Loader.json";
import AreaSplineChart from "../AreaSplineChart";
import CustomTwoTabs from "../custom-two-tabs/custom-two-tabs";
ChartJS.register(...registerables);

//Syling of Range Button
// const BootstrapInput = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3),
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 10,
//     position: "relative",
//     backgroundColor: "#00cd98",
//     border: "1px solid #00cd98",
//     fontSize: 16,
//     padding: "10px 26px 10px 12px",
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     "&:focus": {
//       borderRadius: 4,
//       borderColor: "#80bdff",
//       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
//     },
//   },
// }));
export default function TrackChartPopup() {
  const track = useSelector((state) => state.modal.data);
  // const [historic, setHistoric] = useState(null);
  const isOpen = useSelector((state) => state.modal.isOpen);
  const [select, setSelect] = useState(13);
  const [title, set_title] = useState();
  const dispatch = useDispatch();
  const [spotifyData, setSpotifyData] = useState([]);
  const [youtubeData, setyoutubeData] = useState([]);
  const [yAxisMaximum, setyAxisMaximum] = useState(0);
  const [monthName, setMonthName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [spotifyWeeklySums, setSpotifyWeeklySums] = useState([]);
  const [youtubeWeeklySums, setYoutubeWeeklySums] = useState([]);
  const [type, setType] = useState("monthly");
  const [activeTab, setActiveTab] = useState(1);

  // const handleChange = (event) => {
  //   setSelect(event.target.value);
  //   getData(track?.id, event.target.value);
  // };
  useEffect(() => {
    set_title(track?.title);
    getData(track?.id, 13);
  }, [track]);

  //get data for create chart Analytic for single track
  async function getData(id, selector) {
    try {
      var dates = [];
      // var newdates;
      var spotify_data = [];
      var youtube_data = [];
      // var titok_data = [];
      var data;
      // var sts;
      setIsLoading(true);

      const datalocal = await axios.get(
        `${URLconfig.BASE_URL}/artist-tracks/${id}/track`
      );

      let historic = datalocal.data.data.historic;

      let spotifyWeekly = await groupByWeekAndSum(
        historic.spotify,
        "streams_total"
      );
      let youtubeWeekly = await groupByWeekAndSum(
        historic.youtube,
        "video_views_total"
      );

      let sp = [];
      let yt = [];

      for (let s in spotifyWeekly) {
        sp.push(spotifyWeekly[s]);
      }
      setSpotifyWeeklySums(sp.slice(-50));

      for (let y in youtubeWeekly) {
        yt.push(youtubeWeekly[y]);
      }
      setYoutubeWeeklySums(yt.slice(-50));

      // for (let i in spotifyWeekly) {
      //   sp.push(spotifyWeekly[i]);
      // }
      // setSpotifyWeeklySums(sp.slice(-50));

      // for (let i in youtubeWeekly) {
      //   yt.push(youtubeWeekly[i]);
      // }
      // setYoutubeWeeklySums(yt.slice(-50));

      const s_Date = datalocal.data.data.historic.spotify.slice(-365);
      const y_Date = datalocal.data.data.historic.youtube.slice(-365);
      // const t_Date = datalocal.data.data.historic.tiktok;
      const dataByMonth = {};
      const dataByMonth_youtube = {};
      // const dataByMonth_tiktok = {};

      //one year data convert into monthly streaming spotify
      for (const item of s_Date) {
        const date = new Date(item.date);
        const month = date.getMonth() + 1; // January is 0, so add 1 to get 1-based month number
        const year = date.getFullYear();
        const key = `${year}-${month}`;
        if (!dataByMonth[key]) {
          dataByMonth[key] = {
            streams_total: 0,
            date: 0,
            count: 0,
          };
        }
        dataByMonth[key].streams_total += item.streams_total || 0;
        dataByMonth[key].count++;
        dataByMonth[key].date = `${year}-${month}`;
      }
      //one year data convert into monthly Youtube views
      for (const item of y_Date) {
        const date = new Date(item.date);
        const month = date.getMonth() + 1; // January is 0, so add 1 to get 1-based month number
        const year = date.getFullYear();
        const key = `${year}-${month}`;
        if (!dataByMonth_youtube[key]) {
          dataByMonth_youtube[key] = {
            video_views_total: 0,
            count: 0,
            date: 0,
          };
        }
        dataByMonth_youtube[key].video_views_total +=
          item.video_views_total || 0;
        dataByMonth_youtube[key].count++;
        dataByMonth_youtube[key].date = `${year}-${month}`;
      }

      const dataByMonthArray = Object.entries(dataByMonth).map(
        ([key, value]) => ({
          month: key,
          streams_total: value.streams_total,
          count: value.count,
        })
      );
      const monthsArray = dataByMonthArray?.map((data) => data.month);
      const streamsTotalArray = dataByMonthArray?.map(
        (data) => data.streams_total
      );
      //object convert to for youtube
      const dataByMonthYoutube = Object.entries(dataByMonth_youtube).map(
        ([key, value]) => ({
          month: key,
          video_views_total: value.video_views_total,
          count: value.count,
        })
      );

      const youtubeDates = dataByMonthYoutube.map((data) => data.month);
      // Find the months missing in dataByMonthYoutube
      const monthsToAdd = Object.keys(dataByMonth).filter((month) => {
        return !dataByMonthYoutube.some((data) => data.month === month);
      });
      monthsToAdd.forEach((month) => {
        if (!youtubeDates.includes(month)) {
          const dataFromDataByMonth = dataByMonth[month];
          if (dataFromDataByMonth) {
            dataByMonthYoutube.unshift(dataFromDataByMonth);
          }
        }
      });

      const totalYoutubeViews = dataByMonthYoutube.map(
        (data) => data.video_views_total
      );

      for (let i = 0; i < s_Date.length; i++) {
        dates.push(s_Date[i].date);
        spotify_data.push(s_Date[i]?.streams_total || 0);
      }

      for (let i = 0; i < y_Date.length; i++) {
        youtube_data.push(y_Date[i]?.video_views_total || 0);
      }
      const newYoutubeView = totalYoutubeViews.map((value) => value ?? 0);
      const newStreamstotal = streamsTotalArray.map((value) => value ?? 0);
      newStreamstotal.pop();
      newYoutubeView.pop();
      // Get the last three elements of the modified array
      const lastThreeSpotify = newStreamstotal.slice(-3);
      const sum = lastThreeSpotify.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const lastThreeYoutube = newYoutubeView.slice(-3);
      const ytsum = lastThreeYoutube.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      newStreamstotal.push(sum / lastThreeSpotify.length);
      newYoutubeView.push(ytsum / lastThreeYoutube.length);

      setSpotifyData(newStreamstotal);
      setyoutubeData(newYoutubeView);

      const maxNumberone = Math?.max(...streamsTotalArray);
      const maxNumbertwo = Math?.max(...totalYoutubeViews);
      if (maxNumbertwo > maxNumberone) {
        setyAxisMaximum(maxNumbertwo);
      } else {
        setyAxisMaximum(maxNumberone);
      }
      setMonthName(monthsArray);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const options = {
    series: [
      {
        name: "Spotify",
        data: spotifyData.map((value) => Math.round(value)),
      },
      {
        name: "Youtube",
        data: youtubeData.map((value) => Math.round(value)),
      },
    ],
    option: {
      chart: {
        height: 455,
        type: "area",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: true,
        },
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      forecastDataPoints: {
        count: 1,
      },
      colors: ["#1DB954", "#FF0000"],
      legend: {
        show: true,
        labels: {
          colors: "#FFF",
        },
        position: "top",
        itemMargin: {
          horizontal: 10,
        },
      },
      stroke: {
        width: 3,
        curve: "straight",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.4,
          opacityTo: 0,
        },
      },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        type: "Monthly",
        categories: monthName,
        tickAmount: 10,
      },
      yaxis: {
        min: 0,
        max: Math.round(yAxisMaximum),
        showAlways: false,
        labels: {
          formatter: function (value) {
            if (value >= 1000000) {
              return Math.round(value / 1000000).toFixed(1) + "M";
            } else if (value >= 1000) {
              return Math.round(value / 1000).toFixed(1) + "K";
            } else {
              return Math.round(value);
            }
          },
        },
      },
      title: {
        style: {
          fontSize: "16px",
          color: "white",
        },
      },
    },
  };

  // Function to group records by week number and sum up streams_total for each week

  function groupByWeekAndSum(records, metric) {
    const weekSums = records.reduce((acc, record) => {
      const date = new Date(record.date);
      // Assuming the week starts on Sunday, adjust accordingly if needed
      const weekNumber = getWeekNumber(date);
      if (!acc[weekNumber]) {
        acc[weekNumber] = {
          total: 0,
          date: record.date,
        };
      }
      acc[weekNumber].total += record[metric];

      return acc;
    }, {});

    return weekSums;
  }

  // Helper function to get the week number of a date
  // This can be adjusted if your week starts on a different day
  function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return `${d.getUTCFullYear()}-W${weekNo}`;
  }

  const series = [
    {
      name: "Spotify",
      data: spotifyWeeklySums,
    },
    {
      name: "YouTube",
      data: youtubeWeeklySums,
    },
  ];
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      <Modal
        keepMounted
        open={isOpen}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box varient="div" component="div" className={classess.page}>
          <Container>
            <Box
              varient="div"
              component="div"
              className={classess.page__dialog}
            >
              <Box
                varient="div"
                component="div"
                className={classess.page__dialog__header}
              >
                <span className={classess.page__dialog__header__title}>
                  Track Analytics :{title}{" "}
                </span>
              </Box>
              <Box
                sx={{
                  // width: "250px",
                  mt: 2,
                }}
              >
                <CustomTwoTabs
                  text_0={"Monthly"}
                  text_1={"Weekly"}
                  btn_0={activeTab == 1}
                  btn_1={activeTab == 2}
                  click_0={() => handleTabClick(1)}
                  click_1={() => handleTabClick(2)}
                  padding={"8px 30px"}
                />
              </Box>
              {/* <button
                onClick={() => setType("monthly")}
                disabled={type === "monthly" ? true : false}
              >
                Monthly
              </button>
              <button
                onClick={() => setType("weekly")}
                disabled={type === "weekly" ? true : false}
              >
                Weekly
              </button> */}

              {isLoading ? (
                <Box
                  sx={{
                    height: "515px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "42px", height: "42px" }}>
                    <Lottie
                      animationData={NewLoader}
                      className={classess.page__loader}
                    />
                  </Box>
                </Box>
              ) : (
                <>
                  {activeTab === 1 && (
                    <TrackGraph
                      options={options.option}
                      series={options.series}
                      height={500}
                      type={"area"}
                    />
                  )}

                  {activeTab === 2 && (
                    <AreaSplineChart
                      series={series}
                      height={500}
                      type={"area"}
                    />
                  )}
                </>
              )}

              <Box
                varient="div"
                component="div"
                className={classess.page__dialog__actions}
              >
                <Stack
                  direction="row"
                  gap={5}
                  sx={{
                    marginTop: "20px",
                    width: { xs: "auto", sm: "auto", lg: "auto" },
                  }}
                >
                  <Button
                    variant="contained"
                    type="button"
                    className={classess.page__dialog__actions__cancel_btn}
                    onClick={() => dispatch(closeModal())}
                  >
                    Close
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Container>
        </Box>
      </Modal>
    </div>
  );
}
