import React, { useState } from "react";
import classess from "./style.module.scss";
import { Box } from "@mui/material";
import TabsSearch from "./tabs-search/tabs-search";
import TrendingNowList from "./trending-now-list/trending-now-list";
import TrendingTracklist from "./trending-now-list/trending-tracks-list";

const SearchAddArtist = () => {
  const [tabs, setTabs] = useState(1);
  // console.log("tabs", tabs);
  return (
    <Box className={classess.page}>
      <TabsSearch setTabs={setTabs} />
      {tabs === 1 && <TrendingNowList />}
      {tabs === 2 && <TrendingTracklist />}
      {/* {!tabs ? <TrendingNowList /> : <TrendingTracklist />} */}
    </Box>
  );
};

export default SearchAddArtist;
