import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import classess from "./style.module.scss";

const Graph = ({ data }) => {
  const [YoutubeState, setYoutubeState] = useState({
    series: [],
    options: {
      chart: {
        id: "Youtubechart2",
        type: "area",
        height: 350,
        foreColor: "#FF0000",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#FF0000"],
      stroke: {
        width: 3,
        curve: "straight",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      // markers: {
      //   size: 3,
      //   colors: ["#222c41"],
      //   strokeColor: "#4ffcb7",
      //   strokeWidth: 3,
      // },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
      },
    },

    //   Second Graph Starts

    seriesLine: [],
    optionsLine: {
      chart: {
        id: "Youtubechart1",
        height: 130,
        type: "bar",
        foreColor: "#ccc",
        brush: {
          target: "Youtubechart2",
          enabled: true,
        },
        selection: {
          enabled: true,
          fill: {
            color: "#222c41",
            opacity: 0.4,
          },
          xaxis: {
            min: new Date().getTime(), // default to now if data is missing
            max: new Date().getTime(),
          },
        },
      },
      colors: ["#FF0000"],
      stroke: {
        width: 2,
      },
      grid: {
        borderColor: "#444",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
      },
      //   fill: {
      //     type: "gradient",
      //     gradient: {
      //       opacityFrom: 0.91,
      //       opacityTo: 0.1,
      //     },
      //   },
    },
  });

  useEffect(() => {
    if (data?.data) {
      setYoutubeState((prevState) => ({
        ...prevState,
        series: [
          {
            name: "Streams",
            data: data?.data,
          },
        ],
        seriesLine: [
          {
            data: data?.data,
          },
        ],
        optionsLine: {
          ...prevState.optionsLine,
          chart: {
            ...prevState.optionsLine.chart,
            selection: {
              ...prevState.optionsLine.chart.selection,
              xaxis: {
                min: new Date(data?.minDate).getTime(),
                max: new Date(data?.maxDate).getTime(),
              },
            },
          },
        },
      }));
    }
  }, [data]);
  if (!data?.data) {
    return <div>Loading...</div>; // Or any other fallback content
  }

  return (
    <div id="wrapper" className={classess.wrapper}>
      <div id="chart-line2">
        <ReactApexChart
          options={YoutubeState.options}
          series={YoutubeState.series}
          type="line"
          height={230}
        />
      </div>
      <div id="chart-line" className={classess.chart_line}>
        <ReactApexChart
          options={YoutubeState.optionsLine}
          series={YoutubeState.seriesLine}
          type="area"
          height={130}
        />
      </div>
    </div>
  );
};

export default Graph;

// Remove the following lines from this component and manage rendering in the parent component.
// const domContainer = document.querySelector('#app');
// ReactDOM.render(React.createElement(ApexChart), domContainer);
