/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { HiDotsVertical } from "react-icons/hi";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import {
  CustomTable,
  CustomTableBody,
  CustomTableCellUpload,
  CustomTableCellHeaderUpload,
  CustomTableContainerUpload,
  CustomTableHead,
  CustomTableRow,
} from "../../../../custom-mui-style/custom-mui-styles";
import youtubeIcon from "../../../../assets/social/YoutubeIconSqure.png";
import spotifyIcon from "../../../../assets/social/social-icon3.png";
import appleMusic from "../../../../assets/social/social-icon1.png";
import tiktokIcon from "../../../../assets/social/social-icon8.png";
import CustomTwoTabs from "../../../custom-two-tabs/custom-two-tabs";
import MonthlyChart from "../../../../containers/graph/upload-charts/MonthlyChart";
import YearlyChart from "../../../../containers/graph/upload-charts/YearlyChart";

const TopTracksTable = ({
  tableData,
  toggleRow,
  expandedRow,
  internationalNumberFormat,
  activeTab,
  handleTabClick,
}) => {
  return (
    <Box className={classess.page__table}>
      <CustomTableContainerUpload>
        <Typography className={classess.page__table__heading}>
          Top {tableData && Object.keys(tableData).length} Tracks
        </Typography>
        <CustomTable stickyHeader={true} aria-label="sticky table">
          <CustomTableHead>
            <CustomTableRow
              sx={{
                height: "10px",
              }}
            >
              <CustomTableCellHeaderUpload></CustomTableCellHeaderUpload>
              <CustomTableCellHeaderUpload>Title</CustomTableCellHeaderUpload>
              <CustomTableCellHeaderUpload>
                EARNINGS TO DATE
              </CustomTableCellHeaderUpload>

              <CustomTableCellHeaderUpload>LABEL</CustomTableCellHeaderUpload>
              <CustomTableCellHeaderUpload>
                PERFORMANCE
              </CustomTableCellHeaderUpload>
            </CustomTableRow>
          </CustomTableHead>
          <CustomTableBody>
            {/* rows data here */}

            {tableData &&
              typeof tableData === "object" &&
              Object.entries(tableData).map((item, index) => (
                <React.Fragment>
                  <CustomTableRow
                    sx={{
                      height: "20px",
                    }}
                    key={index}
                  >
                    <CustomTableCellUpload
                      sx={{
                        padding: "10px 0px 10px 20px",
                        width: "30px",
                        borderBottom:
                          expandedRow === index ? "none !important" : "",
                        backgroundColor:
                          expandedRow === index
                            ? "var(--accordian-table-expand-row) !important"
                            : "transparent !important",
                      }}
                    >
                      <Avatar
                        src={item.titleImg}
                        sx={{
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </CustomTableCellUpload>
                    <CustomTableCellUpload
                      sx={{
                        borderBottom:
                          expandedRow === index ? "none !important" : "",
                        backgroundColor:
                          expandedRow === index
                            ? "var(--accordian-table-expand-row) !important"
                            : "transparent !important",
                      }}
                    >
                      <Tooltip
                        title={item[0]}
                        placement="top"
                        arrow
                        enterDelay={100}
                      >
                        <Typography className={classess.page__table__title}>
                          {item[0]}
                        </Typography>
                      </Tooltip>
                    </CustomTableCellUpload>
                    <CustomTableCellUpload
                      sx={{
                        borderBottom:
                          expandedRow === index ? "none !important" : "",
                        backgroundColor:
                          expandedRow === index
                            ? "var(--accordian-table-expand-row) !important"
                            : "transparent !important",
                      }}
                    >
                      $
                      {internationalNumberFormat.format(
                        item[1]?.sum?.toFixed(0)
                      )}
                    </CustomTableCellUpload>

                    <CustomTableCellUpload
                      sx={{
                        borderBottom:
                          expandedRow === index ? "none !important" : "",
                        backgroundColor:
                          expandedRow === index
                            ? "var(--accordian-table-expand-row) !important"
                            : "transparent !important",
                      }}
                    >
                      {item[1]?.label ? item[1]?.label : "N/A"}
                    </CustomTableCellUpload>

                    <CustomTableCellUpload
                      sx={{
                        borderBottom:
                          expandedRow === index ? "none !important" : "",
                        backgroundColor:
                          expandedRow === index
                            ? "var(--accordian-table-expand-row) !important"
                            : "transparent !important",
                      }}
                      onClick={() => toggleRow(index)}
                    >
                      <IconButton
                        className={classess.page__table__icons__upDown}
                        sx={{
                          backgroundColor:
                            expandedRow === index
                              ? "#ffffff !important"
                              : "var(--base-action-icon-background) !important",
                        }}
                      >
                        {expandedRow === index ? (
                          <PiDotsThreeOutlineFill />
                        ) : (
                          <HiDotsVertical />
                        )}
                      </IconButton>
                    </CustomTableCellUpload>
                  </CustomTableRow>

                  {/* show after click */}
                  {expandedRow === index && (
                    <CustomTableCellUpload
                      colSpan={7}
                      sx={{
                        paddingBottom:
                          expandedRow === index ? "20px !important" : "",
                        backgroundColor:
                          expandedRow === index
                            ? "var(--accordian-table-expand-row) !important"
                            : "transparent !important",
                      }}
                    >
                      <Box className={classess.page__table__img_container}>
                        <Box>
                          <CustomTwoTabs
                            text_0={"Monthly"}
                            text_1={"Yearly"}
                            btn_0={activeTab === 1}
                            btn_1={activeTab === 2}
                            click_0={() => handleTabClick(1)}
                            click_1={() => handleTabClick(2)}
                            padding={"8px 20px"}
                          />
                        </Box>
                        {activeTab === 1 && (
                          <Box display={"flex"} gap={"10px"}>
                            <Box
                              className={
                                classess.page__table__img_container__img
                              }
                            >
                              <img src={youtubeIcon} width={28} />
                              <div
                                className={
                                  classess.page__table__img_container__img__youtube_line
                                }
                              ></div>
                            </Box>
                            <Box
                              className={
                                classess.page__table__img_container__img
                              }
                            >
                              <img src={spotifyIcon} width={28} />
                              <div
                                className={
                                  classess.page__table__img_container__img__spotify_line
                                }
                              ></div>
                            </Box>
                            <Box
                              className={
                                classess.page__table__img_container__img
                              }
                            >
                              <img src={tiktokIcon} width={28} />
                              <div
                                className={
                                  classess.page__table__img_container__img__tiktok_line
                                }
                              ></div>
                            </Box>
                            <Box
                              className={
                                classess.page__table__img_container__img
                              }
                            >
                              <img src={appleMusic} width={28} />
                              <div
                                className={
                                  classess.page__table__img_container__img__apple_line
                                }
                              ></div>
                            </Box>
                          </Box>
                        )}
                      </Box>

                      {/* Graph here Monthly */}
                      {activeTab === 1 && <MonthlyChart item={item[1]} />}

                      {/* Graph here Yearly */}
                      {activeTab === 2 && <YearlyChart item={item} />}
                    </CustomTableCellUpload>
                  )}
                </React.Fragment>
              ))}
          </CustomTableBody>
        </CustomTable>
      </CustomTableContainerUpload>
    </Box>
  );
};

export default TopTracksTable;
