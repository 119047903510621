import React from "react";
import classess from "./style.module.scss";
import DashboardWelcomeCards from "../../components/dashboard-welcome-cards/dashboard-welcome-cards";
import PendingApproval from "../../components/pending-approval/pending-approval";
import AandrActivity from "../../components/aandr-activity/aandr-activity";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const Home = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Container maxWidth="xxl" className="graphToolsHide">
      <Grid container spacing={2} className={classess.page}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {/* Dashboard Top Cards Container  */}
          {user.role === "admin" ? (
            <DashboardWelcomeCards />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "675px !important",
                width: "100% important",
                backgroundColor: "#222C41",
                borderRadius: "12px",
                border: "1px solid #5a7380",
              }}
            >
              <Typography
                sx={{
                  color: "#ff3d81",
                  fontWeight: "900",
                  fontSize: "50px",
                }}
              >
                A&R Dashboard Yet to Design
              </Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
          {/* A&RActivity  */}
          {user.role === "admin" ? <AandrActivity /> : null}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
          {/* A&RActivity  */}

          {user.role === "admin" ? <PendingApproval /> : null}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
