/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import classess from "./style.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { addCommasToNumber } from "../../../utils/helper";
// import moment from "moment";
// import { useDispatch, useSelector } from "react-redux";
import NewLoader from "../../lottie-loader/Loader.json";
import Lottie from "lottie-react";
import { getItemToLocalStorage } from "../../../services/storage";
import axios from "axios";
import { config as URLconfig } from "../../../enviorment/enviorment";
// import { getArtist } from "../../../redux/slice/artist";

const UserInfoContainer = () => {
  const { id } = useParams();

  // const navigate = useNavigate();
  // const status = useSelector((state) => state.artist.status);
  // const artist = useSelector((state) => state.artist.artists);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [view, setView] = useState([]);
  const [revenueAmount, setRevenueAmount] = useState("");
  const [deals, setDeals] = useState("");
  // const dispatch = useDispatch();

  const storedToken = getItemToLocalStorage("accessToken");

  const fetchartistUserActivity = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${URLconfig.BASE_URL}/users/${id}/activity`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );

      let rowRoiAmount = 0;
      let a = response.data.data;
      a.forEach((data) => {
        rowRoiAmount += parseInt(data.ROI) || 0;
      });

      setRevenueAmount(rowRoiAmount);
      setDeals(response.data.data.length);
      setIsLoading(false);
      // console.log("response", response);
    } catch (error) {
      console.error("Error fetching contracts:", error);
    } finally {
    }
  };

  const fetchartistUser = async (id) => {
    setIsLoadingInfo(true);
    try {
      const response = await axios.get(
        `${URLconfig.BASE_URL}/artists/action/artists-by-user/${id}`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      const userData = response.data.data;
      // console.log("response", response.data.data);
      const recentViewIds = userData.user.recent_views.map((item) => item);

      // Find the artist names corresponding to the recent view IDs
      const matchingArtists = userData.artists.filter((artists) =>
        recentViewIds.includes(artists._id)
      );
      setView(matchingArtists);
      // Now you have the matching artist data, you can do whatever you want with it
      // console.log("Matching Artists:", matchingArtists);

      setSelectedUser(userData);
    } catch (error) {
      console.error("Error fetching contracts:", error);
    } finally {
      setIsLoadingInfo(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchartistUser(id);
      fetchartistUserActivity(id);
    }
  }, [id]);

  const data = [
    {
      heading: "Role",
      value: isLoadingInfo ? (
        <Loader />
      ) : selectedUser?.user?.role === "user" ? (
        "A&R"
      ) : null,
    },
    {
      heading: "Recent View",
      value: isLoadingInfo ? <Loader /> : view.map((item) => item.name),
      paddingLeft: "15",
    },
    {
      heading: "TOTAL ARTISTS",
      value: isLoadingInfo ? <Loader /> : selectedUser?.artists?.length,
      paddingLeft: "15",
    },
    {
      heading: "DEALS",
      value: isLoadingInfo ? <Loader /> : deals,
      paddingLeft: "15",
    },
    {
      heading: "REVENUE (EST.)",
      value: isLoadingInfo ? (
        <Loader />
      ) : (
        "$ " + addCommasToNumber(revenueAmount)
      ),
      paddingLeft: "15",
      borderRight: "none",
    },
  ];

  return (
    <Box className={classess.page}>
      <Box className={classess.page__container}>
        <Avatar
          className={classess.page__container__img}
          src={selectedUser?.user?.profilePicture}
          alt={selectedUser?.name}
          variant="square"
        />

        <Box className={classess.page__container__details}>
          <Box className={classess.page__container__details__top}>
            <Box className={classess.page__container__details__top__name}>
              <Typography
                className={
                  classess.page__container__details__top__name__artist_name
                }
              >
                {isLoadingInfo ? (
                  <Loader />
                ) : selectedUser && selectedUser.user ? (
                  selectedUser.user.firstName + " " + selectedUser.user.lastName
                ) : (
                  ""
                )}
              </Typography>
            </Box>
          </Box>
          <Box className={classess.page__container__details__bottom}>
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <DetailsBox
                  heading={item.heading}
                  value={item.value}
                  subPara={item.subPara}
                  isLoading={item.isLoading}
                  borderRight={item.borderRight}
                  padding={item.paddingLeft}
                />
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfoContainer;

const Loader = () => {
  return (
    <Box sx={{ width: "30px", height: "30px" }}>
      <Lottie animationData={NewLoader} className={classess.page__loader} />
    </Box>
  );
};

const DetailsBox = ({
  heading,
  value,
  padding,
  borderRight,
  subPara,
  isLoading,
}) => {
  return (
    <Box
      className={classess.page__container__details__bottom__box}
      style={{
        borderRight: borderRight,
      }}
    >
      <Box
        className={classess.page__container__details__bottom__box__detail}
        style={{
          paddingLeft: `${padding}px`,
        }}
      >
        <Typography
          className={
            classess.page__container__details__bottom__box__detail__heading
          }
        >
          {heading} :
        </Typography>
        <Typography
          className={
            classess.page__container__details__bottom__box__detail__value
          }
        >
          {isLoading ? <Loader /> : value}
        </Typography>
        {subPara && (
          <Typography
            className={
              classess.page__container__details__bottom__box__detail__subPara
            }
          >
            {subPara}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
