// Importing necessary libraries and components

// React and hooks
import React, { useEffect, useState } from "react";

// Redux for state management
import { useSelector } from "react-redux";

// React Router for navigation
import { useNavigate } from "react-router-dom";

// Axios for API requests
import axios from "axios";

// MUI components
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Stack,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

// MUI icons
import CachedIcon from "@mui/icons-material/Cached";

// Importing CSS module for styling
import classes from "./style.module.scss";

// Importing API utilities and configurations
import { searchArtist } from "../../api/spotify.api";
import { config as URLconfig } from "../../enviorment/enviorment";

const RecommendCollaborations = () => {
  // State declarations
  const artist = useSelector((state) => state.artist.artist);
  const [isLoading, setIsLoading] = useState(false);
  const [collaborations, setCollaborations] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  // Function to refresh insights
  const refresh = () => {
    setIsLoading(true);
    const payload = {
      spotify_id: `${artist?.spotify_id}`,
    };
    axios
      .put(`${URLconfig.BASE_URL}/artists/action/smartinsights`, payload)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // useEffect hook for fetching and setting collaborations
  useEffect(() => {
    if (artist?.collaborations && artist?.collaborations.length > 0) {
      let NotFoundImage = "https://via.placeholder.com/600x400?text=Not+Found";
      let newCollaborations = [];
      const promises = artist.collaborations.map((e) => {
        return searchArtist(e["title"])
          .then((res) => {
            const item = res.artists.items[0];
            let obj = {
              title: e["title"],
              whyis: e["description"],
              avatar: item?.images[0]?.url || NotFoundImage,
            };
            newCollaborations.push(obj);
          })
          .catch((err) => {
            console.log("Err: ", err);
          });
      });

      Promise.all(promises).finally(() => {
        setCollaborations(newCollaborations);
        setLoader(false);
      });
    } else {
      setLoader(false);
    }
  }, [artist]);

  return (
    <Box component="div" variant="div" className={classes.page}>
      <Box component="div" variant="div" className={classes.page__banner}>
        <Box
          component="div"
          variant="div"
          className={classes.page__banner__header}
        >
          <span className={classes.page__banner__header__insights_title}>
            Smart Insights
          </span>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Button
              className={classes.page__banner__header__compareBtn}
              onClick={() => navigate("/blig/compare-artist")}
            >
              Compare
            </Button>
            <LoadingButton
              className={classes.page__banner__header__refresh_btn}
              disabled={isLoading}
              loading={isLoading}
              onClick={() => refresh()}
              loadingPosition="start"
              startIcon={isLoading === false ? <CachedIcon /> : null}
              sx={{
                opacity: isLoading === true ? "0.5" : 1,
              }}
            >
              {isLoading ? "Please wait" : "Refresh"}
            </LoadingButton>
          </Box>
        </Box>

        <Box
          variant="div"
          component="div"
          className={classes.page__banner__main_container}
        >
          {loader ? (
            <span>Loading</span>
          ) : (
            <Stack
              direction={{ xs: "row", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 3 }}
              className={classes.page__banner__main_container__stack_container}
            >
              {collaborations?.length > 0 ? (
                collaborations.map((artist, index) => {
                  return (
                    <Card
                      className={
                        classes.page__banner__main_container__stack_container__card_container
                      }
                      key={artist?.title}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="123"
                          image={
                            artist["avatar"]
                              ? artist["avatar"]
                              : "https://via.placeholder.com/600x400?text=Not+Found"
                          }
                          alt="artist"
                        />
                        <CardContent
                          className={
                            classes.page__banner__main_container__stack_container__card_container__card_content
                          }
                        >
                          <Box>
                            <span
                              component="div"
                              className={
                                classes.page__banner__main_container__stack_container__card_container__card_content__title
                              }
                            >
                              {artist?.title}
                            </span>
                          </Box>
                          <Box>
                            <span
                              className={
                                classes.page__banner__main_container__stack_container__card_container__card_content__description
                              }
                            >
                              {artist?.whyis}

                              <Box
                                className={
                                  classes.page__banner__button_container
                                }
                              >
                                <Button
                                  className={
                                    classes.page__banner__button_container__btn
                                  }
                                  onClick={() =>
                                    window.open(
                                      `https://open.spotify.com/search/${artist?.title}`
                                    )
                                  }
                                  variant="contained"
                                  type="button"
                                >
                                  View
                                </Button>
                              </Box>
                            </span>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  );
                })
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    color: "#fff",
                    flexFlow: "column",
                    alignItems: "center",
                  }}
                >
                  {loader ? (
                    <CircularProgress size={40} color="secondary" />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        color: "#fff",
                        flexFlow: "column",
                        alignItems: "center",
                      }}
                    >
                      <span sx={{ color: "#fff" }}>
                        Smart Insight Not Available
                      </span>
                    </Box>
                  )}
                </Box>
              )}
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RecommendCollaborations;
