/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./App.scss";
import Navigation from "./navigation/navigation";
import { me } from "./redux/slice/auth";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PreLoader from "./components/pre-loader/pre-loader";
import {
  destroySpotifySession,
  _fetchToken,
} from "./utils/spotifyApiServiceHandler";
import { getItemFromCurrentSession } from "./services/session";
import AuthEnum from "./enums/auth.enum";
import { config as URLconfig } from "./enviorment/enviorment";
import axios from "axios";
import useArtist from "./hooks/useArtist";
import ArtistPreLoader from "./components/pre-loader/artist-loader";
import { GridLayoutProvider } from "./containers/artist/edit-dashboard/GridLayoutContext";
import ResponsiveMessageModal from "./components/modal/Modal";
import "react-lazy-load-image-component/src/effects/blur.css";

import ToggleButtonForTheme from "./components/ToggleButtonForTheme/ToggleButtonForTheme";

function App() {
  const dispatch = useDispatch();
  // const [isDark, setIsDark] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const userStatus = useSelector((state) => state.auth.status);
  const [isLoading, setIsLoading] = useState(true);
  const artistService = useArtist();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showMessage, setShowMessage] = useState(screenWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);

      if (newScreenWidth < 600) {
        setShowMessage(true);
      } else {
        setShowMessage(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Check screen width on initial load
    if (screenWidth < 600) {
      setShowMessage(true);
    }
  }, [screenWidth]);

  useEffect(() => {
    const token = getItemFromCurrentSession(AuthEnum.TOKEN);
    if (token) {
      fetchSpotifyToken();
      dispatch(me());
    } else {
      setIsLoading(false);
      const urlPrefix = location.pathname.split("/")[1];
      if (urlPrefix === "blig") {
        destroySpotifySession();
        navigate("/login");
      }
    }
  }, []);

  useEffect(() => {
    if (userStatus === "succeeded" || userStatus === "failed") {
      setIsLoading(false);
    }
  }, [userStatus]);

  useEffect(() => {
    if (userStatus === "succeeded") {
      if (!user) {
        navigate("/login");
      } else {
        const urlPrefix = location.pathname.split("/")[1];
        if (urlPrefix !== "blig" && user.role !== "artist") {
          navigate("/blig/home");
        } else if (urlPrefix !== "blig" && user.role === "artist") {
          axios
            .get(
              `${URLconfig.BASE_URL}/artists/filter/get-artist-by-spotify/${user.meta_data?.spotify_id}`
            )
            .then(async (artist) => {
              if (artist.data.data) {
                // navigate(`/blig/view-artist/${artist.data.data._id}`);
                navigate(`/blig/artist-deals`);
              } else {
                let payload = {
                  name: user.meta_data?.name,
                  spotify_id: user.meta_data?.spotify_id,
                  avatar: user.meta_data?.avatar,
                  genres: user.meta_data?.genres,
                };
                await artistService.post(payload);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          if (location.search) {
            navigate(`${location.pathname}${location.search}`);
          } else {
            navigate(location.pathname);
          }
        }
      }
    }

    // else if (userStatus === "failed") {
    //   destroySpotifySession();
    //   navigate("/login");
    // }
  }, [user, userStatus]);

  const fetchSpotifyToken = async () => await _fetchToken();

  if (artistService.isLoading) {
    return <ArtistPreLoader />;
  }

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div
    // data-theme={isDark ? "dark" : "light"}
    >
      <GridLayoutProvider>
        <div className="responsive_mobile">
          {isLoading ? (
            <PreLoader />
          ) : (
            <Navigation />
            // <PreLoader />
          )}
        </div>

        <div>
          {showMessage && (
            <ResponsiveMessageModal
              heading={"Message"}
              message={
                "Can not access application on mobile view. Use laptop or desktop to use the application."
              }
              open={showMessage}
              // onClose={CloseModal}
            />
          )}
        </div>
      </GridLayoutProvider>
      {/* <ToggleButtonForTheme
        isChecked={isDark}
        handleChange={() => setIsDark(!isDark)}
      /> */}
    </div>
  );
}

export default App;

// const CloseModal = () => {
//   setShowMessage(false);
// };
// const [expireTime, setExpireTime] = useState("");

// useEffect(() => {
//   getExpireTimeById();
// }, []);

// useEffect(() => {
//   const resetToken = async () => {
//     const response = await fetch(
//       `${process.env.REACT_APP_BACKEND_API}/auth/reset-token`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//         },
//         body: JSON.stringify({ expireTime: expireTime }),
//       }
//     );
//     const data = await response.json();
//     localStorage.setItem("accessToken", data.data);
//   };

//   if (expireTime) {
//     resetToken();
//   }
// }, [expireTime, location]);

// useEffect(() => {
//   setInterval(() => {
//     const token = getItemFromCurrentSession(AuthEnum.TOKEN);
//     if (token && isJwtExpired(token)) {
//       dispatch(logout());
//       navigate("/login");
//     }
//   }, 3000);
// }, []);
//comminted

// const getExpireTimeById = async () => {
//   const response = await fetch(
//     `${process.env.REACT_APP_BACKEND_API}/setting/64106cb98f8da03d6a6d6b07`
//   );
//   const data = await response.json();
//   setExpireTime(data.data[0].expireTime);
// };
