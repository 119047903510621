import React from "react";
import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import classess from "./style.module.scss";
import { ReactSVG } from "react-svg";
import BackArrow from "../../assets/back-arrow.svg";
// import EyeIcon from "../../assets/eye.svg";
import SignatureIcon from "../../assets/signatureIcon.svg";
import ClouddownIcon from "../../assets/clouddownIcon.svg";
import {
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableCellHeader,
  CustomTableContainer,
  CustomTableHead,
  CustomTableRow,
} from "../../custom-mui-style/custom-mui-styles";
const dataList = [
  {
    heading: "TENURE",
    name: "3 years",
  },
  {
    heading: "A&R",
    name: "Tracy Carter",
  },
  {
    heading: "TYPE",
    name: "License",
  },
  {
    heading: "STATUS",
    name: "Pending",
  },
  {
    heading: "EXCLUSIVITY",
    name: "Non Exclusive",
  },
  {
    heading: "TRACKS",
    name: "05",
  },
  {
    heading: "START DATE",
    name: "01.06.2024",
  },
  {
    heading: "END DATE",
    name: "01.06.2027",
  },
  {
    heading: "CREATION DATE",
    name: "12.22.2023",
  },
  {
    heading: "CONTRACT AMOUNT",
    name: "$750,000.00",
  },
  {
    heading: "ARTIST ADVANCE",
    name: "$250,000.00",
  },
  {
    heading: "MARKETING ADVANCE",
    name: "$150,000.00",
  },
];

const tableData = [
  {
    title: "Radioactive",
    earingdate: "$123,000",
    isrc: "US-UM7-12-01074",
    label: "Universal",
    ownership: "80%",
    releasedate: "2012",
  },
  {
    title: "Radioactive",
    earingdate: "$123,000",
    isrc: "US-UM7-12-01074",
    label: "Universal",
    ownership: "80%",
    releasedate: "2012",
  },
  {
    title: "Radioactive",
    earingdate: "$123,000",
    isrc: "US-UM7-12-01074",
    label: "Universal",
    ownership: "80%",
    releasedate: "2012",
  },
  {
    title: "Radioactive",
    earingdate: "$123,000",
    isrc: "US-UM7-12-01074",
    label: "Universal",
    ownership: "80%",
    releasedate: "2012",
  },
  {
    title: "Radioactive",
    earingdate: "$123,000",
    isrc: "US-UM7-12-01074",
    label: "Universal",
    ownership: "80%",
    releasedate: "2012",
  },
  {
    title: "Radioactive",
    earingdate: "$123,000",
    isrc: "US-UM7-12-01074",
    label: "Universal",
    ownership: "80%",
    releasedate: "2012",
  },
  {
    title: "Radioactive",
    earingdate: "$123,000",
    isrc: "US-UM7-12-01074",
    label: "Universal",
    ownership: "80%",
    releasedate: "2012",
  },
];
const ArtistContract = ({ openSign, changeBg }) => {
  return (
    <Box className={classess.page}>
      <Box className={classess.page__topSec}>
        <Box className={classess.page__topSec__container}>
          <IconButton className={classess.page__topSec__container__icon_back}>
            <ReactSVG src={BackArrow} />
          </IconButton>
          <Typography className={classess.page__topSec__container__heading}>
            Contract name
          </Typography>
        </Box>
        <Box display={"flex"} gap={"10px"}>
          <Button
            className={classess.page__topSec__container__button}
            startIcon={<ReactSVG src={SignatureIcon} />}
            onClick={openSign}
            disabled={changeBg === true}
            sx={{
              backgroundColor: changeBg === true ? "#A0214E !important" : "",
            }}
          >
            Sign
          </Button>{" "}
          <Button
            className={classess.page__topSec__container__button}
            startIcon={<ReactSVG src={ClouddownIcon} />}
          >
            Download
          </Button>
        </Box>
      </Box>
      <Box className={classess.page__middle}>
        {dataList.map((item, index) => (
          <React.Fragment key={index}>
            <ConDetails heading={item.heading} name={item.name} />
          </React.Fragment>
        ))}
      </Box>

      <Box className={classess.page__tracks}>
        <Box className={classess.page__tracks__container}>
          <Typography className={classess.page__tracks__container__heading}>
            Selected Tracks
          </Typography>
          <TableList tableData={tableData} />
        </Box>
      </Box>
    </Box>
  );
};

const ConDetails = ({ heading, name }) => {
  return (
    <Box className={classess.page__conDetails}>
      <Typography className={classess.page__conDetails__heading}>
        {heading}
      </Typography>
      <Typography className={classess.page__conDetails__value}>
        {name}
      </Typography>
    </Box>
  );
};

const TableList = ({ tableData }) => {
  return (
    <CustomTableContainer className={classess.page__table}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableHead>
          <CustomTableCellHeader></CustomTableCellHeader>
          <CustomTableCellHeader>TITLE</CustomTableCellHeader>

          <CustomTableCellHeader>EARNINGS TO DATE</CustomTableCellHeader>

          <CustomTableCellHeader>ISRC</CustomTableCellHeader>

          <CustomTableCellHeader>LABEL</CustomTableCellHeader>

          <CustomTableCellHeader>OWNERSHIP %</CustomTableCellHeader>
          <CustomTableCellHeader>RELEASE DATE</CustomTableCellHeader>
        </CustomTableHead>

        <CustomTableBody>
          {tableData?.map((item, index) => (
            <React.Fragment key={index}>
              <CustomTableRow
                sx={{
                  backgroundColor: "#222c41 !important",
                }}
              >
                <CustomTableCell
                  sx={{
                    borderBottom: "1px solid #5a7380 !important",
                    maxWidth: "50px",
                  }}
                >
                  <Avatar
                    src=""
                    sx={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </CustomTableCell>
                <CustomTableCell
                  sx={{
                    borderBottom: "1px solid #5a7380 !important",
                  }}
                >
                  <Typography className={classess.page__table__row}>
                    {item.title}
                  </Typography>
                </CustomTableCell>
                <CustomTableCell
                  sx={{
                    borderBottom: "1px solid #5a7380 !important",
                  }}
                >
                  <Typography className={classess.page__table__row}>
                    {item.earingdate}
                  </Typography>
                </CustomTableCell>
                <CustomTableCell
                  sx={{
                    borderBottom: "1px solid #5a7380 !important",
                  }}
                >
                  <Typography className={classess.page__table__row}>
                    {item.isrc}
                  </Typography>
                </CustomTableCell>
                <CustomTableCell
                  sx={{
                    borderBottom: "1px solid #5a7380 !important",
                  }}
                >
                  <Typography className={classess.page__table__row}>
                    {item.label}
                  </Typography>
                </CustomTableCell>{" "}
                <CustomTableCell
                  sx={{
                    borderBottom: "1px solid #5a7380 !important",
                  }}
                >
                  <Typography className={classess.page__table__row}>
                    {" "}
                    {item.ownership}{" "}
                  </Typography>
                </CustomTableCell>
                <CustomTableCell
                  sx={{
                    borderBottom: "1px solid #5a7380 !important",
                  }}
                >
                  <Typography className={classess.page__table__row}>
                    {" "}
                    {item.releasedate}{" "}
                  </Typography>
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
          <Box mt={2} />
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};
export default ArtistContract;
