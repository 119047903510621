import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

import classess from "./style.module.scss";
const MonthlyChart = ({ item }) => {
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    const init = async () => {
      const x = await Promise.all(
        Object.entries(item)
          .filter((item) => item[0] !== "sum")
          .slice(-1)
      );
      setSelectedYear(x[0][0]);
    };
    if (item) {
      init();
    }
  }, [item]);

  if (selectedYear === null) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }

  return (
    <>
      <select
        value={selectedYear}
        onChange={(e) => setSelectedYear(e.target.value)}
        className={classess.page__select}
      >
        {Object.entries(item)
          .filter((item) => item[0] !== "sum")
          .map((item) => (
            <option>{item[0]}</option>
          ))}
      </select>
      <div id="chart">
        <ReactApexChart
          options={{
            chart: {
              height: 455,
              type: "area",
              foreColor: "#ccc",
              toolbar: {
                autoSelected: "pan",
                show: true,
              },
              zoom: {
                enabled: true,
              },
              animations: {
                enabled: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#ff4c4c", "#34bf49", "#ffffff", "#00f7ef"],
            stroke: {
              width: 3,
              curve: "straight",
            },
            title: {
              text: "",
              align: "left",
            },
            grid: {
              borderColor: "#ffffff20",
              clipMarkers: false,
              yaxis: {
                lines: {
                  show: true,
                },
              },
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            fill: {
              type: "gradient",
              gradient: {
                shade: "dark",
                gradientToColors: ["#ff4c4c", "#34bf49", "#ffffff", "#00f7ef"],
                type: "horizontal",
                stops: [0, 0],
                enabled: true,
                opacityFrom: 1,
                opacityTo: 1,
              },
            },
            xaxis: {
              categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
            },

            yaxis: {
              labels: {
                formatter: function (value) {
                  if (value >= 1000000) {
                    return "$" + Math.round(value / 1000000).toFixed(1) + "M";
                  } else if (value >= 1000) {
                    return "$" + Math.round(value / 1000).toFixed(1) + "K";
                  } else {
                    return "$" + Math.round(value);
                  }
                },
              },
            },
          }}
          series={[
            {
              data: Object.entries(item[selectedYear])
                .slice(0, -1)
                .map((abc) =>
                  abc[1]["YouTube"]?.earnings ? abc[1]["YouTube"]?.earnings : 0
                ),
              name: "Youtube",
            },
            {
              data: Object.entries(item[selectedYear])
                .slice(0, -1)
                .map((abc) =>
                  abc[1]["Spotify"]?.earnings ? abc[1]["Spotify"]?.earnings : 0
                ),
              name: "Spotify",
            },
            {
              data: Object.entries(item[selectedYear])
                .slice(0, -1)
                .map((abc) =>
                  abc[1]["Apple Music"]?.earnings
                    ? abc[1]["Apple Music"]?.earnings
                    : 0
                ),
              name: "Apple Music",
            },
            {
              data: Object.entries(item[selectedYear])
                .slice(0, -1)
                .map((abc) =>
                  abc[1]["TikTok"]?.earnings ? abc[1]["TikTok"]?.earnings : 0
                ),
              name: "TikTok",
            },
          ]}
          type="line"
          height={350}
        />
      </div>
    </>
  );
};

export default MonthlyChart;
