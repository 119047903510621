// React and Redux Imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// MUI Imports
import {
  Box,
  Grid,
  Avatar,
  IconButton,
  Typography,
  Stack,
  MenuItem,
  Button,
  Tooltip,
  Container,
  TablePagination,
  TextField,
} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment/InputAdornment";

// import SearchIcon from "@material-ui/icons/Search";

// Custom Components and Styles Imports
import { CustomSelect } from "../../custom-mui-style/custom-mui-styles";
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
  CustomTextField,
} from "../../custom-mui-style/custom-mui-styles";
import { viewArtistUseStyles } from "../../custom-mui-style/custom-mui-styles";

import classess from "./style.module.scss";

// Assets and Utilities Imports
import { getItemToLocalStorage } from "../../services/storage";
import { config as URLconfig } from "../../enviorment/enviorment";
import EyeIcon from "../../assets/eye.svg";
import MessageIcon from "../../assets/message.svg";

// External Libraries and Icons Imports
import axios from "axios";
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";
import NewLoader from "../lottie-loader/Loader.json";
import { useNavigate } from "react-router-dom";
import UserInfoContainer from "./user-Info-container/UserInfoContainer";
import SearchIcon from "@mui/icons-material/Search";

const AandRActivityList = () => {
  const user = useSelector((state) => state.auth.user);
  const storedToken = getItemToLocalStorage("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activities, setActivities] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchActivity = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${URLconfig.BASE_URL}/users/activity`,
          {
            headers: { Authorization: `Bearer ${storedToken}` },
          }
        );
        setActivities(response.data.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchActivity();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredActivities = activities.filter((activity) =>
    (activity.user.firstName + " " + activity.user.lastName)
      .toLowerCase()
      .includes(searchTerm)
  );

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <Box className={classess.page}>
      <Box className={classess.page__container__topSec}>
        <Box
          varient="div"
          component="div"
          className={classess.page__container__header}
        >
          <span className={classess.page__container__header__title}>
            A&R List
          </span>
          <Box
            varient="div"
            component="div"
            className={classess.page__container__header__search}
          >
            <SearchIcon
              className={classess.page__container__header__search__seaarch_icon}
            />
            <input
              className={classess.page__container__header__search__input}
              placeholder="Search"
              type="search"
              onInput={handleSearch}
              required
            />
          </Box>

          {/* <CustomTextField
            className={classess.page__container__header__search__input}
            placeholder="Search"
            type="search"
            onInput={handleSearch}
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    className={
                      classess.page__container__header__search__seaarch_icon
                    }
                  />
                </InputAdornment>
              ),
            }}
          /> */}
        </Box>
      </Box>

      <ActivityTable
        isLoading={isLoading}
        activities={filteredActivities}
        rowsPerPage={rowsPerPage}
        totalArtist={filteredActivities.length}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default AandRActivityList;

const ActivityTable = ({
  isLoading,
  activities,
  totalArtist,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <CustomTableContainer className={classess.page__container__table}>
        {isLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "50px",
                height: "50px",
              }}
            >
              <Lottie
                animationData={NewLoader}
                className={classess.page__container__loader}
              />
            </Box>
          </Box>
        ) : (
          <CustomTable stickyHeader aria-label="sticky table">
            <CustomTableHead>
              <CustomTableCellHeader>
                {/* <Avatar
                  alt={"Img"}
                  sx={{
                    height: 50,
                    width: 50,
                    border: "2px solid var(--picture-border-color)",
                  }}
                /> */}
              </CustomTableCellHeader>
              <CustomTableCellHeader>NAME</CustomTableCellHeader>

              <CustomTableCellHeader>LAST VIEWED</CustomTableCellHeader>

              <CustomTableCellHeader>TOTAL ARTISTS</CustomTableCellHeader>

              <CustomTableCellHeader>DEALS</CustomTableCellHeader>

              <CustomTableCellHeader>REVENUE (EST.)</CustomTableCellHeader>

              <CustomTableCellHeader>ACTIONS</CustomTableCellHeader>
            </CustomTableHead>

            <CustomTableBody>
              {activities.length > 0 ? (
                activities
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((activity, index) => {
                    let rowRoiAmountTotal = 0;
                    activity.additionalData.forEach((data) => {
                      rowRoiAmountTotal += parseInt(data.ROI) || 0;
                    });
                    let rowRoiAmount = rowRoiAmountTotal;

                    return (
                      <React.Fragment key={activity.user._id}>
                        <Box sx={{ mt: "1rem" }} />
                        <CustomTableRow>
                          <CustomTableCell
                            sx={{
                              // width: "50px !important",
                              borderBottomLeftRadius: "12px",
                              borderTopLeftRadius: "12px",
                            }}
                          >
                            <Tooltip
                              title={
                                activity.user.firstName +
                                " " +
                                activity.user.lastName
                              }
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <Avatar
                                src={activity?.user?.profilePicture}
                                alt={activity.user.firstName}
                                sx={{
                                  height: 50,
                                  width: 50,
                                  border:
                                    "2px solid var(--picture-border-color)",
                                }}
                              />
                            </Tooltip>
                          </CustomTableCell>

                          <CustomTableCell>
                            <Tooltip
                              title={
                                activity.user.firstName +
                                " " +
                                activity.user.lastName
                              }
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <Typography
                                className={classess.page__container__name}
                              >
                                {activity.user.firstName +
                                  " " +
                                  activity.user.lastName}
                              </Typography>
                            </Tooltip>
                          </CustomTableCell>

                          <CustomTableCell>
                            <Tooltip
                              title={activity?.recent_views_artist?.name}
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <Typography
                                className={classess.page__container__heading1}
                              >
                                {activity?.recent_views_artist?.name}
                              </Typography>
                            </Tooltip>
                          </CustomTableCell>

                          <CustomTableCell>
                            <Typography
                              className={classess.page__container__heading2}
                            >
                              {activity.user.artists.length}
                            </Typography>
                          </CustomTableCell>

                          <CustomTableCell>
                            <Typography
                              className={classess.page__container__heading2}
                            >
                              {activity.additionalData.length}
                            </Typography>
                          </CustomTableCell>

                          <CustomTableCell>
                            <Typography
                              className={classess.page__container__heading2}
                            >
                              {rowRoiAmount > 0
                                ? "$" + rowRoiAmount.toLocaleString()
                                : "$0"}
                            </Typography>
                          </CustomTableCell>

                          <CustomTableCell
                            sx={{
                              borderBottomRightRadius: "12px",
                              borderTopRightRadius: "12px",
                            }}
                          >
                            <Stack direction="row" spacing={1}>
                              <IconButton
                                className={classess.page__container__actionIcon}
                                onClick={() =>
                                  navigate(
                                    `/blig/aandractivity/${activity?.user?._id}`
                                  )
                                }
                              >
                                <ReactSVG src={EyeIcon} />
                              </IconButton>
                            </Stack>
                          </CustomTableCell>
                        </CustomTableRow>
                      </React.Fragment>
                    );
                  })
              ) : (
                <React.Fragment>
                  <Box sx={{ marginTop: "1rem" }} />
                  <CustomTableRow>
                    <CustomTableCell colSpan={7} sx={{ borderRadius: "12px" }}>
                      <Typography
                        className={classess.page__container__notFound}
                      >
                        No Activity found.
                      </Typography>
                    </CustomTableCell>
                  </CustomTableRow>
                </React.Fragment>
              )}
            </CustomTableBody>
          </CustomTable>
        )}
      </CustomTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        sx={{ color: "#d6d6d6", width: "100%", height: "20%" }}
        count={totalArtist}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          actions: "custom-pagination-actions",
          select: "custom-pagination-select",
          displayedRows: "custom-select-style",
          selectLabel: "custom-select-style",
        }}
        SelectProps={{
          classes: {
            select: "custom-select",
          },
        }}
      />
    </React.Fragment>
  );
};
