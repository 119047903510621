import React from "react";
import ReactApexChart from "react-apexcharts";

const YearlyChart = ({ item }) => {
  return (
    <div id="chart">
      <ReactApexChart
        options={{
          chart: {
            height: 455,
            type: "area",
            foreColor: "#ccc",
            toolbar: {
              autoSelected: "pan",
              show: true,
            },
            zoom: {
              enabled: true,
            },
            animations: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["var(--base-graph-line-color)"],
          stroke: {
            width: 3,
            curve: "straight",
          },
          title: {
            text: "",
            align: "left",
          },
          grid: {
            borderColor: "#ffffff20",
            clipMarkers: false,
            yaxis: {
              lines: {
                show: true,
              },
            },
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              gradientToColors: ["var(--base-graph-line-color)"],
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
            },
          },
          xaxis: {
            categories: Object.entries(item[1])
              .filter((item) => item[0] !== "sum")
              .map((item) => item[0]),
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                if (value >= 1000000) {
                  return Math.round(value / 1000000).toFixed(1) + "M";
                } else if (value >= 1000) {
                  return Math.round(value / 1000).toFixed(1) + "K";
                } else {
                  return Math.round(value);
                }
              },
            },
          },
        }}
        series={[
          {
            data: Object.entries(item[1])
              .slice(0, -1)
              .map((item) => item[1]?.sum),
            name: "Earnings",
          },
        ]}
        type="line"
        height={350}
      />
    </div>
  );
};

export default YearlyChart;
