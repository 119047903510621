import React from "react";
import classes from "./style.module.scss";

import {
  Container,
  Grid,
  Box,
  Typography,
  Avatar,
  IconButton,
  Stack,
  Button,
  Tooltip,
} from "@mui/material";

// Custom Components and Styles Imports
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";
import { ReactSVG } from "react-svg";
import FilterIcon from "../../assets/filterIcon.svg";

const FinanceDeals = () => {
  return <MyDealsTable />;
};

const MyDealsTable = () => {
  return (
    <Box className={classes.page__tableContainer}>
      <Box className={classes.page__tableContainer__header}>
        <Typography className={classes.page__tableContainer__header__heading}>
          Deals
        </Typography>

        <Button
          className={classes.page__tableContainer__header__button}
          startIcon={<ReactSVG src={FilterIcon} />}
        >
          Sort By
        </Button>
      </Box>
      <CustomTableContainer className={classes.page__tableContainer__table}>
        <CustomTable stickyHeader aria-label="sticky table">
          <CustomTableHead>
            <CustomTableCellHeader
              sx={{ border: "none !important" }}
            ></CustomTableCellHeader>
            <CustomTableCellHeader sx={{ border: "none !important" }}>
              ARTIST NAME
            </CustomTableCellHeader>

            <CustomTableCellHeader sx={{ border: "none !important" }}>
              STATUS
            </CustomTableCellHeader>

            <CustomTableCellHeader sx={{ border: "none !important" }}>
              CREATION DATE
            </CustomTableCellHeader>

            <CustomTableCellHeader sx={{ border: "none !important" }}>
              LAST PAID AMOUNT
            </CustomTableCellHeader>

            <CustomTableCellHeader sx={{ border: "none !important" }}>
              PAYMENT DATE
            </CustomTableCellHeader>

            <CustomTableCellHeader sx={{ border: "none !important" }}>
              NEXT PAYMENT DATE
            </CustomTableCellHeader>

            <CustomTableCellHeader
              sx={{ border: "none !important" }}
            ></CustomTableCellHeader>
          </CustomTableHead>

          <CustomTableBody>
            <React.Fragment>
              <Box sx={{ mb: "1rem" }} />
              <CustomTableRow>
                <CustomTableCell
                  sx={{
                    maxWidth: 50,
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                >
                  <Tooltip
                    title={"Artist Image"}
                    placement="top"
                    arrow
                    enterDelay={100}
                  >
                    <Avatar
                      // src={}
                      alt={"Artists Image"}
                      onClick={() => {
                        // navigate(`/blig/track/${track?.id}`);
                      }}
                    />
                  </Tooltip>
                </CustomTableCell>

                <CustomTableCell>
                  <Tooltip
                    title={"DEAL AMOUNT"}
                    placement="top"
                    arrow
                    enterDelay={100}
                  >
                    <Typography className={classes.page__tableContainer__name}>
                      {"Brandz"}
                    </Typography>
                  </Tooltip>
                </CustomTableCell>

                <CustomTableCell>
                  <Stack direction="row" spacing={1}>
                    <Typography
                      className={classes.page__tableContainer__heading2}
                    >
                      .
                    </Typography>
                    <Typography
                      className={classes.page__tableContainer__heading2}
                    >
                      {"PAID"}
                    </Typography>
                  </Stack>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography
                    className={classes.page__tableContainer__heading1}
                  >
                    {"12.12.2023"}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography
                    className={classes.page__tableContainer__heading1}
                  >
                    {"$250,000"}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography
                    className={classes.page__tableContainer__heading1}
                  >
                    {"12.16.2023"}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography
                    className={classes.page__tableContainer__heading1}
                  >
                    {"01.16.2024"}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell
                  sx={{
                    borderBottomRightRadius: "12px",
                    borderTopRightRadius: "12px",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Tooltip
                      title={"View"}
                      placement="top"
                      arrow
                      enterDelay={100}
                    >
                      <Button className={classes.page__tableContainer__button}>
                        VIEW DETAILS
                      </Button>
                    </Tooltip>

                    <Tooltip
                      title={"View"}
                      placement="top"
                      arrow
                      enterDelay={100}
                    >
                      <Button className={classes.page__tableContainer__button}>
                        MAKE PAYMENT
                      </Button>
                    </Tooltip>
                  </Stack>
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          </CustomTableBody>
        </CustomTable>
      </CustomTableContainer>
    </Box>
  );
};

export default FinanceDeals;
