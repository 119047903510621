// React and Redux imports
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import ReactDOMServer from "react-dom/server";
import ReactCountryFlag from "react-country-flag";
import { Chart } from "react-google-charts";
import Lottie from "lottie-react";

// MUI imports
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

// Local imports
import classes from "./style.module.scss";
import { config as URLconfig } from "../../../../src/enviorment/enviorment";
import { countries } from "country-data";
import NewLoader from "../../../components/lottie-loader/Loader.json";

// Asset imports
import SpotifyIcon from "../../../assets/social/social-icon3.png";
import YoutubeIcon from "../../../assets/social/social-icon2.png";
import SoundCloudIcon from "../../../assets/social/social-icon4.png";
import TikTokIcon from "../../../assets/social/social-icon8.png";

const BubbleMaps = () => {
  const artist = useSelector((state) => state.artist.artist);
  const [selectedSource, setSelectedSource] = useState("spotify");
  const [allSourcesData, setAllSourcesData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Event handlers
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelect = (source) => {
    setSelectedSource(source);
    handleClose();
  };

  // Helper functions
  const getFlagSvgString = (countryCode) => {
    return ReactDOMServer.renderToStaticMarkup(
      <Box>
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          style={{
            width: "35px",
            height: "35px",
            // borderRadius: "50%",
          }}
        />
      </Box>
    );
  };

  function processPlatformData(dataArray, valueKey) {
    return dataArray.reduce((acc, item) => {
      const country_code = item.country_code;
      const value = item[valueKey];
      acc[country_code] = (acc[country_code] || 0) + value;
      return acc;
    }, {});
  }

  const fetchAudienceData = async (platform) => {
    if (!artist?.spotify_id) {
      console.error("No Spotify ID available for the artist");
      return;
    }
    setIsLoading(true);

    try {
      const { data } = await axios.get(
        `${URLconfig.BASE_URL}/audience/${artist?.spotify_id}`
      );

      let platformData = {};
      // console.log("data", data);

      switch (platform) {
        case "spotify":
          platformData = processPlatformData(
            data.data.audience[0]?.data?.monthly_listeners,
            "current_listeners"
          );
          break;
        case "youtube":
          platformData = processPlatformData(
            data.data.audience[2]?.data?.views,
            "current_views"
          );
          break;
        case "tiktok":
          platformData = processPlatformData(
            data.data.audience[4]?.data?.followers,
            "followers_total"
          );
          break;
        case "soundcloud":
          platformData = processPlatformData(
            data.data.audience[8]?.data?.followers,
            "followers_total"
          );
          break;
        default:
          console.error("Invalid source");
          return;
      }

      setAllSourcesData((prevState) => ({
        ...prevState,
        [platform]: platformData,
      }));
    } catch (error) {
      console.error(`Fetching ${platform} stats error`, error);
    }

    setIsLoading(false);
  };

  // useEffect to fetch data when artist ID changes
  useEffect(() => {
    if (artist?.spotify_id) {
      platforms.forEach((platform) => {
        fetchAudienceData(platform);
      });
    }
  }, [artist?.spotify_id]);

  // Constants
  const platforms = ["spotify", "youtube", "tiktok", "soundcloud"];
  const platformIcons = {
    spotify: SpotifyIcon,
    youtube: YoutubeIcon,
    soundcloud: SoundCloudIcon,
    tiktok: TikTokIcon,
  };
  const platformColorPalettes = {
    spotify: ["#97FFCE", "#30D588", "#1BFB5E"],
    youtube: ["#FFBCC2", "#FF7A85", "#FF4757"],
    deezer: ["#FAD3E3", "#FA6C8D", "#F50B5A"],
    soundcloud: ["#ECB68A", "#FF943D", "#FF7300"],
    tiktok: ["#ADCFF2", "#6BA3DE", "#1A6DC4"],
  };
  const platformWords = {
    spotify: "Listeners",
    tiktok: "Followers",
    soundcloud: "Followers",
    deezer: "Followers",
    youtube: "Views",
  };

  const renderIcon = () => {
    switch (selectedSource) {
      case "spotify":
        return <img src={SpotifyIcon} alt="Spotify Icon" />;
      case "youtube":
        return <img src={YoutubeIcon} alt="Youtube Icon" />;
      default:
        return null;
    }
  };
  // Chart data and options
  const data = [
    ["Country", "Listeners/Followers", { role: "tooltip", p: { html: true } }],
    ...Object.entries(allSourcesData[selectedSource] || {})
      .filter(([, value]) => value)
      .map(([code, value]) => {
        const formattedValue = value.toLocaleString();
        const wordForPlatform =
          `Monthly ${platformWords[selectedSource]}` || "Monthly Listeners";
        console.log(platformWords[selectedSource]);
        const flagSvgString = getFlagSvgString(code);
        const customTooltipHtml = `
          <div style="background-color: #214646; border-radius: 5px; color: #fff; width: 200px;">
            <div style="display: flex; flex-direction: row;gap:10px">
            <div >${flagSvgString}</div>
            <div style="display: flex; flex-direction: column;">
            <div style="font-size: 18px; font-weight:bold">${
              countries[code]?.name
            }</div>
            <div style="display: flex; gap:5px;">
            <div>
                <img src=${
                  selectedSource === "spotify"
                    ? SpotifyIcon
                    : selectedSource === "tiktok"
                    ? TikTokIcon
                    : selectedSource === "soundcloud"
                    ? SoundCloudIcon
                    : selectedSource === "youtube"
                    ? YoutubeIcon
                    : null
                }
                style=
                 "width:16px;
                  height:16px;
                  margin-top:2px"
                /> 
            </div>
            <div>
            <div style="font-size: 14px;font-weight:600">${formattedValue}</div>
            <div style="font-size: 10px;">${wordForPlatform}</div>
            </div>
            </div>
            </div>
            </div>
          </div>
        `;

        return [code, value, customTooltipHtml];
      }),
  ];

  const geoOptions = {
    backgroundColor: "transparent",
    colorAxis: { colors: platformColorPalettes[selectedSource] || ["#5A7380"] },
    datalessRegionColor: "#5A7380",
    defaultColor: "#5A7380",
    tooltip: {
      isHtml: true,
      // trigger: "selection",
    },
  };

  const getTotalViews = (platform) => {
    const data = allSourcesData[platform];
    return data
      ? Object.values(data).reduce((total, current) => total + current, 0)
      : 0;
  };

  return (
    <Box className={classes.page}>
      <Box className={classes.page__container}>
        <Box className={classes.page__container__topBanner}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            disableElevation
            onClick={handleClick}
            // endIcon={<KeyboardArrowDownIcon />}
            style={{
              color: "var(--second-button-color)",
              borderColor: "var(--second-button-border-color)",
              borderWidth: "2px",
              borderStyle: "solid",
              backgroundColor: "var(--second-button-background-color)",
              fontFamily: "DM Sans, sans-serif",
              fontWeight: "bold",
              borderRadius: "12px",
              // width: "200px ",
              "&:hover": {
                backgroundColor: "var(--second-button-background-color)",
                borderColor: "var(--second-button-border-color)",
              },
              "&.MuiButton-outlined": {
                borderWidth: "2px",
              },
            }}
          >
            <img
              src={platformIcons[selectedSource]}
              alt={selectedSource}
              style={{ marginRight: "8px", height: "24px" }}
            />
            <Typography
              sx={{
                fontWeight: "900",
              }}
            >
              Sort By
            </Typography>
          </Button>
          <StyledMenu
            id="simple-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {platforms
              .filter((platform) => getTotalViews(platform) > 0)
              .map((platform) => (
                <MenuItem
                  disableRipple
                  key={platform}
                  onClick={() => handleSelect(platform)}
                  style={{
                    justifyContent: "space-between",
                    color: "white",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={platformIcons[platform]}
                      alt={platform}
                      style={{
                        marginRight: "8px",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    {platform}
                  </div>
                  {selectedSource === platform && (
                    <CheckIcon style={{ color: "var(--base-icon-color)" }} />
                  )}
                </MenuItem>
              ))}
          </StyledMenu>
        </Box>

        <Box className={classes.page__container__chartContainer}>
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <ChartComponent data={data} geoOptions={geoOptions} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BubbleMaps;

// Loading component
const LoadingComponent = () => {
  return (
    <Box
      sx={{
        width: "50px",
        height: "50px",
      }}
    >
      <Lottie animationData={NewLoader} className={classes.page__loader} />
    </Box>
  );
};

// Chart component
const ChartComponent = ({ data, geoOptions }) => {
  return (
    <Chart
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data[selection[0].row + 1];
            // console.log("Selected : " + region[0]);
          },
        },
      ]}
      chartType="GeoChart"
      data={data}
      options={geoOptions}
      style={{
        height: "280px",
        width: "100% !important",
      }}
    />
  );
};

// Styled menu component
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: "100px",
    color: "white",
    backgroundColor: "var(--second-button-color)",
    border: "2px solid var(--second-button-border-color)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
