import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import classess from "./style.module.scss";
import Grid from "@mui/material/Grid";
import ReactApexChart from "react-apexcharts";
import CustomTwoTabs from "../../../components/custom-two-tabs/custom-two-tabs";

const TotalEarnings = ({ data }) => {
  const [month, setMonth] = useState(true);
  const [year, setYear] = useState(false);
  const [monthlyEstimate, setMonthlyEstimate] = useState([]);
  const [yearlyEstimate, setYearlyEstimate] = useState([]);
  const [chartDataforMonthly, setchartDataforMonthly] = useState([]);
  const [chartDataforYearly, setchartDataforYearly] = useState([]);
  // const [check, setCheck] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (data && data.insights) {
      const insights = Object.entries(data.insights).map(
        ([year, monthlyData]) => {
          return Object.entries(monthlyData)
            .map((item) => {
              const monthNum = item[0];
              if (monthNum !== "sum") {
                const date = new Date();
                date.setFullYear(year);
                date.setMonth(monthNum);
                const amount = item[1].sum;
                // Format the date as desired
                const month = date.toLocaleString("default", {
                  month: "short",
                });
                const formattedDate = `${month}-${date.getFullYear()}`;
                return {
                  amount,
                  date: formattedDate,
                };
              }
            })
            .slice(0, -1);
        }
      );
      const insightsYearly = Object.entries(data.insights).map(
        ([year, monthlyData]) => {
          const lastMonthEntry = Object.entries(monthlyData).pop(); // Get the last entry

          const date = new Date();
          date.setFullYear(year);
          const amount = lastMonthEntry;
          return {
            year,
            amount,
          };
        }
      );
      const monthlyGraphData = insights.flatMap((item) =>
        Object.entries(item).map((xyz) => {
          return {
            Month: xyz[1]?.date,
            Amount: Math.round(xyz[1]?.amount, 2),
          };
        })
      );
      const YearlyGraphData = insightsYearly.flatMap((item) => {
        return {
          year: item.year,
          amount: Math.round(item.amount[1], 2),
        };
      });
      setMonthlyEstimate(monthlyGraphData);
      setYearlyEstimate(YearlyGraphData);
    }
  }, [data]);
  // console.log("monthlyEstimate", monthlyEstimate);
  useEffect(() => {
    const apexChartData = monthlyEstimate.map((dataPoint) => [
      dataPoint.Month,
      dataPoint.Amount,
    ]);
    const apexChartDatayearly = yearlyEstimate.map((dataPoint) => ({
      x: dataPoint.year,
      y: dataPoint.amount,
    }));
    setchartDataforMonthly(apexChartData);
    setchartDataforYearly(apexChartDatayearly);
  }, [monthlyEstimate]);
  // const mapMonths = (item) => ({
  //   Month: item.date,
  //   Amount: Math.round(item.amount, 2),
  // });

  // const mapYears = (item) => ({
  //   name: item.year,
  //   Yearly: Math.round(item.amount, 2),
  // });

  // const handleMonth = () => {
  //   setMonth(true);
  //   setYear(false);
  // };

  // const handleYear = () => {
  //   setYear(true);
  //   setMonth(false);
  // };

  const [yAxisMaximum] = useState(0);

  // console.log("chartDataforMonthly", chartDataforMonthly);
  const monthlyoptions = {
    series: [
      {
        name: "Earning $",
        data: chartDataforMonthly,
      },
    ],
    option: {
      chart: {
        id: "chart1",
        height: 455,
        type: "line",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: true,
        },
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      colors: ["var(--base-graph-line-color)"],
      stroke: {
        width: 3,
        curve: "straight",
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["var(--base-graph-line-color)"],
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          format: "MMM",
        },
      },
      xaxis: {
        type: "datetime",
        tickAmount: 10,
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: function (value) {
            if (value >= 1000000) {
              return Math.round(value / 1000000).toFixed(1) + "M";
            } else if (value >= 1000) {
              return Math.round(value / 1000).toFixed(1) + "K";
            } else {
              return Math.round(value);
            }
          },
        },
        // tickAmount: 4,
      },
    },
  };

  const yealyoptions = {
    series: [
      {
        name: "Earning $",
        data: chartDataforYearly,
      },
    ],
    option: {
      chart: {
        id: "chart2",
        height: 455,
        type: "line",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: true,
        },
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      colors: ["var(--base-graph-line-color)"],
      stroke: {
        width: 3,
        curve: "straight",
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
          type: "datetime",
          tickAmount: 3,
          tickPlacement: "on",
          labels: {
            formatter: function (value, timestamp, index) {
              const date = new Date(value);
              return date.getFullYear().toString(); // Display only the year
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["var(--base-graph-line-color)"],
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          format: "yyyy",
        },
      },
      // xaxis: {
      //   type: "datetime",
      //   min: new Date("01 Jan 2020").getTime(),
      //   max: new Date("01 Jan 2023").getTime(),
      //   // tickAmount: 3,
      // },
      yaxis: {
        // min: 0,
        tickAmount: yAxisMaximum,
        labels: {
          formatter: function (value) {
            if (value >= 1000000) {
              return Math.round(value / 1000000).toFixed(1) + "M";
            } else if (value >= 1000) {
              return Math.round(value / 1000).toFixed(1) + "K";
            } else {
              return Math.round(value);
            }
          },
        },
      },
    },
  };
  // console.log("yAxisMaximum", yAxisMaximum);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <Box
        component="div"
        variant="div"
        className={classess.page__banner}
        mt={2}
        p={3}
      >
        <Box
          variant="div"
          component="div"
          className={classess.page__banner__conatiner}
          sx={{ alignItems: "flex-start" }}
        >
          <Box
            variant="div"
            component="div"
            pb={1}
            className={classess.page__banner__conatiner__button_wrapper}
          >
            {/* <Button
              onClick={handleMonth}
              className={
                classess.page__banner__conatiner__button_wrapper__button
              }
            >
              Monthly
            </Button>
            <Button
              onClick={handleYear}
              className={
                classess.page__banner__conatiner__button_wrapper__button
              }
            >
              Yearly
            </Button> */}
            <CustomTwoTabs
              text_0={"Monthly"}
              text_1={"Yearly"}
              btn_0={activeTab === 1}
              btn_1={activeTab === 2}
              click_0={() => handleTabClick(1)}
              click_1={() => handleTabClick(2)}
              padding={"8px 20px"}
            />
          </Box>

          {activeTab === 1 && (
            <Grid sx={{ width: "100%" }}>
              <ReactApexChart
                options={monthlyoptions.option}
                series={monthlyoptions.series}
                height={500}
                type={"line"}
              />
            </Grid>
          )}

          {activeTab === 2 && (
            <Grid sx={{ width: "100%" }}>
              <ReactApexChart
                options={yealyoptions.option}
                series={yealyoptions.series}
                height={500}
                type={"line"}
              />
              {/* <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                  width={500}
                  height={300}
                  data={yearlyEstimate}
                  syncId="Yearly Earnings"
                  margin={{
                    top: 10,
                    right: 5,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient id="Yearly" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#4FFCB7" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#4FFCB7" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="0 1" />
                  <XAxis
                    dataKey="name"
                    tick={{ fill: "white", fontSize: 14 }}
                  />
                  <YAxis
                    tick={{ fill: "white", fontSize: 14 }}
                    label={{
                      fill: "white",
                      fontSize: 14,
                      value: "USD ($)",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="Yearly"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#Yearly)"
                  />
                </AreaChart>
              </ResponsiveContainer> */}
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TotalEarnings;
