import React, { useState } from "react";
import Box from "@mui/material/Box";
import classess from "./style.module.scss";
import CmgLogo from "../../assets/cmg-logo.png";
import BlacklionIcon from "../../assets/LoginTopLogo.png";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import ArtistLoginForm from "../artist-login-form/artist-login-form";
import LabelLoginForm from "../label-login-form/label-login-form";
import { CustomTabs } from "../../custom-mui-style/custom-mui-styles";
const LoginFormContainer = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box varient="div" component="div" className={classess.page}>
      <Box className={classess.customForm}>
        <Box varient="div" component="div" className={classess.page__logo}>
          <img
            src={BlacklionIcon}
            alt="logo"
            className={classess.page__logo__img}
          />
          {/* <img src={CmgLogo} alt="logo" className={classess.page__logo__img} /> */}
        </Box>
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box>
              <CustomTabs
                onChange={handleChange}
                centered
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
              >
                <Tab
                  className={`${classess.page__tabs_container__tab} ${
                    value === "1"
                      ? classess.page__tabs_container__tab__activeTabEmp
                      : ""
                  }`}
                  label="Employee Login"
                  value="1"
                />

                <Tab
                  className={`${classess.page__tabs_container__tab} ${
                    value === "2"
                      ? classess.page__tabs_container__tab__activeTabArt
                      : ""
                  }`}
                  label="Artist Login"
                  value="2"
                />
              </CustomTabs>
              <div className={classess.page__horizontalLine} />
            </Box>

            {/* Employee login */}
            <TabPanel value="1" sx={{ p: 0 }}>
              <div>
                <LabelLoginForm />
              </div>
            </TabPanel>

            {/* Artist login */}
            <TabPanel value="2" sx={{ p: 0 }}>
              <div>
                <ArtistLoginForm />
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginFormContainer;
