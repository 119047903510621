// React and hooks imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Material-UI components imports
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Grid,
  Button,
  Stack,
  TextField,
  Typography,
  IconButton,
  Avatar,
  Modal,
  Tooltip,
  Skeleton,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search"; // MUI icon for search action

// Utility imports
import axios from "axios";
import { toast } from "react-toastify";

// Assets and styles imports
import classess from "./style.module.scss";
import pdfImage from "../../../src/assets/pdf-image.png";
import EyeIcon from "../../assets/buttonsicons/EyeIcon.png";
import DownloadIcon from "../../assets/buttonsicons/DownloadIcon.png";
import EditIcon from "../../assets/buttonsicons/EditIcon.png";

// Custom components and utilities
import { CustomTextField } from "../../custom-mui-style/custom-mui-styles";
import { config as URLconfig } from "../../enviorment/enviorment";
import AuthEnum from "../../enums/auth.enum";
import { getItemToLocalStorage } from "../../services/storage";

import {
  CustomTableContainer,
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";

const ArtistPaymentList = ({ props, handleNotes, setNotesComments }) => {
  // Component states
  const [visibility, setVisibility] = useState(false);
  const [comments, setComments] = useState(false);
  const [list, setList] = useState([]);
  const [note, setNote] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  // Redux state
  const user = useSelector((state) => state.auth.user);

  // Hooks
  const navigate = useNavigate();

  // Convert Number Format
  const internationalNumberFormat = new Intl.NumberFormat("en-US");

  // Handler functions
  const handleToggle = (id) => {
    setVisibility(visibility === id ? null : id);
    setComments(false);
  };

  const handleToggleForComment = (id) => {
    setVisibility(false);
    setComments(comments === id ? null : id);
    if (handleNotes === 0) {
      handleNotes(id);
    } else {
      handleNotes(comments === id ? null : id);
    }
    setNotesComments(list[id]?.notes);
  };

  const noteHandler = (id) => {
    if (note.trim() === "") {
      setButtonClicked(true);
      return;
    }
    const payload = {
      note,
    };
    let config = {
      headers: {
        authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
      },
    };

    axios
      .post(`${URLconfig.BASE_URL}/payments/${id}/notes`, payload, config)
      .then((res) => {
        toast.success("Success");
        setNotesComments((prevComments) => [res.data.data, ...prevComments]);
        setNote("");
        setButtonClicked(false);
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleDownload = (contractFile) => {
    const link = document.createElement("a");
    link.href = contractFile;
    link.download = contractFile;
    link.click();
  };

  // useEffect for initial data fetch
  useEffect(() => {
    let isSubscribed = true;
    axios({
      url: `${URLconfig.BASE_URL}/payments`,
      method: "GET",
      headers: {
        authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
      },
    })
      .then((response) => {
        if (isSubscribed) {
          setIsLoading(false);
          setList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      isSubscribed = false;
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "199px",
    bgcolor: "#222C41",
    borderRadius: "12px",
    boxShadow: 24,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    setPage(0);
  };

  const sortList = list?.filter((item) =>
    item?.artist_name?.toLowerCase()?.includes(searchTerm)
  );
  return (
    <React.Fragment>
      <Box varient="div" component="div" className={classess.page}>
        <Box className={classess.page__container}>
          <Box
            varient="div"
            component="div"
            className={classess.page__container__topHeader}
          >
            <span className={classess.page__container__topHeader__title}>
              My Payments
            </span>

            <Box
              varient="div"
              component="div"
              className={classess.page__container__topHeader__search}
            >
              <SearchIcon
                className={
                  classess.page__container__topHeader__search__seaarch_icon
                }
              />
              <input
                className={classess.page__container__topHeader__search__input}
                placeholder="Search"
                type="search"
                onInput={handleSearch}
                required
              />
            </Box>
          </Box>
          <Box
            component="div"
            varient="div"
            className={classess.page__container__list}
          >
            {isLoading ? (
              <SkeletonLoaderComponent />
            ) : (
              <ArtistPaymentListComponent
                list={sortList}
                internationalNumberFormat={internationalNumberFormat}
                handleToggle={handleToggle}
                user={user}
                handleToggleForComment={handleToggleForComment}
                visibility={visibility}
                handleDownload={handleDownload}
                comments={comments}
                setNote={setNote}
                note={note}
                buttonClicked={buttonClicked}
                noteHandler={noteHandler}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                listCount={list?.length}
              />
            )}
          </Box>
        </Box>
      </Box>
      <CustomModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        style={style}
      />
    </React.Fragment>
  );
};

export default ArtistPaymentList;

const SkeletonLoaderComponent = () => {
  return (
    <CustomTableContainer className={classess.page__container__list__table}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableHead>
          <CustomTableRow>
            <CustomTableCellHeader />
            <CustomTableCellHeader>ARTIST NAME</CustomTableCellHeader>
            <CustomTableCellHeader>CREATED ON</CustomTableCellHeader>
            <CustomTableCellHeader>SUBMITTED BY</CustomTableCellHeader>
            <CustomTableCellHeader>STATUS</CustomTableCellHeader>
            <CustomTableCellHeader>ACTION</CustomTableCellHeader>
          </CustomTableRow>
        </CustomTableHead>
        <CustomTableBody>
          {Array.from({ length: 7 }).map((_, index) => (
            <React.Fragment key={index}>
              <Box sx={{ m: "1rem" }} />
              <CustomTableRow>
                <CustomTableCell
                  sx={{
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                >
                  <Skeleton variant="circular" width={40} height={40} />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell
                  sx={{
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};

const ArtistPaymentListComponent = ({
  list,
  internationalNumberFormat,
  handleToggle,
  user,
  handleToggleForComment,
  visibility,
  handleDownload,
  comments,
  setNote,
  note,
  buttonClicked,
  noteHandler,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  listCount,
}) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <CustomTableContainer className={classess.page__container__list__table}>
        <CustomTable stickyHeader aria-label="sticky table">
          <CustomTableHead>
            <CustomTableRow>
              <CustomTableCellHeader />
              <CustomTableCellHeader>ARTIST NAME</CustomTableCellHeader>
              <CustomTableCellHeader>LAST PAYMENT</CustomTableCellHeader>
              <CustomTableCellHeader>CREATED ON</CustomTableCellHeader>
              <CustomTableCellHeader>CREATED BY</CustomTableCellHeader>
              <CustomTableCellHeader>ACTION</CustomTableCellHeader>
            </CustomTableRow>
          </CustomTableHead>

          {list[0] === undefined ? (
            <span
              className={classess.page__container__list__table__no_contracts}
            >
              No Payment
            </span>
          ) : (
            list
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((items, index) => {
                return (
                  <CustomTableBody>
                    {/* <React.Fragment> */}
                    <Box sx={{ m: "1rem" }} />
                    {/* </React.Fragment> */}
                    <CustomTableRow>
                      <CustomTableCell
                        sx={{
                          maxWidth: { xs: 150, lg: 50, sm: 150 },
                          borderTopLeftRadius: "12px",
                          borderBottomLeftRadius: "12px",
                        }}
                      >
                        <Avatar
                          src={items?.artist.avatar}
                          alt={items?.name}
                          className={
                            classess.page__container__list__table__row__artist_image
                          }
                        />
                      </CustomTableCell>

                      <CustomTableCell>
                        <Tooltip
                          title="View Artist"
                          placement="top"
                          arrow
                          enterDelay={100}
                        >
                          <span
                            className={
                              classess.page__container__list__table__row__name
                            }
                          >
                            <a
                              className={
                                classess.page__container__list__table__row__name__href
                              }
                              rel="noopener noreferrer"
                              href={"/blig/view-artist/" + items?.artist_id}
                              target="_blank"
                            >
                              {items?.artist_name}
                            </a>
                          </span>
                        </Tooltip>
                      </CustomTableCell>

                      <CustomTableCell
                        sx={{
                          color: "#ffffff",
                        }}
                      >
                        $
                        {internationalNumberFormat.format(
                          items?.last_payment_paid
                        )}
                      </CustomTableCell>

                      <CustomTableCell>
                        <span
                          className={
                            classess.page__container__list__table__row__created
                          }
                        >
                          {new Date(items?.createdAt).toLocaleDateString({
                            weekday: "short",
                            year: "numeric",
                            month: "2-digit",
                            day: "numeric",
                          })}
                        </span>
                      </CustomTableCell>

                      <CustomTableCell>
                        <span
                          className={
                            classess.page__container__list__table__row__created_by
                          }
                        >
                          {items?.created_by}
                        </span>
                      </CustomTableCell>

                      <CustomTableCell
                        sx={{
                          borderTopRightRadius: "12px",
                          borderBottomRightRadius: "12px",
                        }}
                      >
                        <span
                          className={
                            classess.page__container__list__table__row__action
                          }
                        >
                          <Stack spacing={1} direction="row">
                            <Tooltip
                              title="View Payments"
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <IconButton
                                style={{
                                  backgroundColor:
                                    "var(--base-action-icon-background)",
                                  height: "30px",
                                  width: "30px",
                                }}
                                onClick={() =>
                                  navigate(`/blig/payment/${items?._id}`)
                                }
                              >
                                <img
                                  src={EyeIcon}
                                  alt="Eye"
                                  style={{
                                    height: "12px",
                                    width: "15.96px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="Download Documents"
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <IconButton
                                style={{
                                  backgroundColor:
                                    "var(--base-action-icon-background)",
                                  height: "30px",
                                  width: "30px",
                                }}
                                onClick={() => handleToggle(index)}
                              >
                                <img
                                  src={DownloadIcon}
                                  alt="Download Icon"
                                  style={{
                                    height: "13.91px",
                                    width: "16px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            {user.role === "admin" ? (
                              <Tooltip
                                title="Add Notes"
                                placement="top"
                                arrow
                                enterDelay={100}
                              >
                                <IconButton
                                  style={{
                                    backgroundColor:
                                      "var(--base-action-icon-background)",
                                    height: "30px",
                                    width: "30px",
                                  }}
                                  onClick={() => handleToggleForComment(index)}
                                >
                                  <img
                                    src={EditIcon}
                                    alt="Edit"
                                    style={{
                                      height: "12.02px",
                                      width: "12.02px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <IconButton
                                style={{
                                  backgroundColor: "#dddddd",
                                  height: "30px",
                                  width: "30px",
                                }}
                                desibled={true}
                              >
                                <img
                                  src={EditIcon}
                                  alt="Edit"
                                  style={{
                                    height: "12.02px",
                                    width: "12.02px",
                                  }}
                                />
                              </IconButton>
                            )}
                          </Stack>
                        </span>
                      </CustomTableCell>
                    </CustomTableRow>

                    {visibility === index && (
                      <React.Fragment>
                        <TableRow>
                          <TableCell colSpan={6} sx={{ borderBottom: 0 }}>
                            <form>
                              <Grid container spacing={3}>
                                <Grid item xs>
                                  <label
                                    className={
                                      classess.page__container__list__table__label
                                    }
                                  >
                                    Valuation
                                  </label>
                                  <CustomTextField
                                    value={`$${items?.valuation}`}
                                    name="name"
                                    type="text"
                                    required
                                  />
                                </Grid>
                                <Grid item xs>
                                  <label
                                    className={
                                      classess.page__container__list__table__label
                                    }
                                  >
                                    Artist Advance
                                  </label>
                                  <CustomTextField
                                    value={`$${items?.artist_advance}`}
                                    name="name"
                                    type="text"
                                    required
                                  />
                                </Grid>
                                <Grid item xs>
                                  <label
                                    className={
                                      classess.page__container__list__table__label
                                    }
                                  >
                                    Marketing Budget
                                  </label>
                                  <CustomTextField
                                    value={`$${items?.artist_marketing_budget}`}
                                    name="name"
                                    type="text"
                                    required
                                  />
                                </Grid>
                                <Grid item xs>
                                  <label
                                    className={
                                      classess.page__container__list__table__label
                                    }
                                  >
                                    No. of Songs
                                  </label>

                                  <CustomTextField
                                    value={items?.Selected_tracks.length}
                                    name="name"
                                    type="text"
                                    required
                                  />
                                </Grid>
                              </Grid>
                            </form>

                            <Grid
                              container
                              spacing={3}
                              sx={{ marginTop: "5px" }}
                            >
                              <Grid item xs>
                                <label
                                  className={
                                    classess.page__container__list__table__bank_info_wrapper__name
                                  }
                                >
                                  Bank Information
                                </label>
                                <div
                                  className={
                                    classess.page__container__list__table__bank_info_wrapper
                                  }
                                >
                                  <label
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__label
                                    }
                                  >
                                    Bank Name
                                  </label>
                                  <br />
                                  <CustomTextField
                                    value={`${items?.bank_name}`}
                                    readOnly="true"
                                  />
                                  <br />
                                  <br />
                                  <label
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__label
                                    }
                                  >
                                    Account Holder's Name
                                  </label>
                                  <br />
                                  <CustomTextField
                                    value={`${items?.bank_holder_name}`}
                                    readOnly="true"
                                  />
                                  <br />
                                  <br />
                                  <label
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__label
                                    }
                                  >
                                    Bank Account Number
                                  </label>
                                  <br />

                                  <CustomTextField
                                    value={`${items?.bank_account_number}`}
                                    readOnly="true"
                                  />
                                  <br />
                                  <br />
                                  <label
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__label
                                    }
                                  >
                                    SWIFT/BIC Code
                                  </label>
                                  <br />

                                  <CustomTextField
                                    value={`${items?.swift_or_BIC_code}`}
                                    readOnly="true"
                                  />
                                  <br />
                                  <br />
                                  <label
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__label
                                    }
                                  >
                                    IBAN
                                  </label>
                                  <br />

                                  <CustomTextField
                                    value={`${items?.iban}`}
                                    readOnly="true"
                                  />
                                  <br />
                                  <br />
                                  <label
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__label
                                    }
                                  >
                                    Routing Number
                                  </label>
                                  <br />

                                  <CustomTextField
                                    value={`${items?.routing_number}`}
                                    readOnly="true"
                                  />
                                  <br />
                                  <br />
                                  <label
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__label
                                    }
                                  >
                                    Bank Address
                                  </label>
                                  <br />
                                  <Box
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__input__bank_address
                                    }
                                  >
                                    <CustomTextField
                                      value={`${items?.bank_address}`}
                                      readOnly="true"
                                      sx={{ marginTop: "10px" }}
                                    />
                                    <CustomTextField
                                      value={`${items?.city}`}
                                      readOnly="true"
                                      sx={{ marginTop: "10px" }}
                                    />
                                    <CustomTextField
                                      value={`${items?.zip}`}
                                      readOnly="true"
                                      sx={{ marginTop: "10px" }}
                                    />
                                    <CustomTextField
                                      value={`${items?.country}`}
                                      readOnly="true"
                                      sx={{ marginTop: "10px" }}
                                    />
                                  </Box>
                                </div>
                              </Grid>
                              <Grid item xs>
                                <label
                                  className={
                                    classess.page__container__list__table__bank_info_wrapper__name
                                  }
                                >
                                  WE8/W9 Form
                                </label>
                                <div
                                  className={
                                    classess.page__container__list__table__bank_info_wrapper
                                  }
                                >
                                  <div
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__box
                                    }
                                  >
                                    <img
                                      src={pdfImage}
                                      className={
                                        classess.page__container__list__table__bank_info_wrapper__image
                                      }
                                      alt=""
                                    />
                                    <Button
                                      variant="contained"
                                      className={
                                        classess.page__container__list__table__bank_info_wrapper__image__downloadbtn
                                      }
                                      onClick={() =>
                                        handleDownload(items?.w8_ben_file)
                                      }
                                    >
                                      Download
                                    </Button>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs>
                                <label
                                  className={
                                    classess.page__container__list__table__bank_info_wrapper__name
                                  }
                                >
                                  ID Front
                                </label>
                                <div
                                  className={
                                    classess.page__container__list__table__bank_info_wrapper
                                  }
                                >
                                  <div
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__box
                                    }
                                  >
                                    <img
                                      src={items?.artist_photo_id_front}
                                      className={
                                        classess.page__container__list__table__bank_info_wrapper__image
                                      }
                                      style={{ width: "60%" }}
                                      alt=""
                                    />

                                    <Button
                                      variant="contained"
                                      className={
                                        classess.page__container__list__table__bank_info_wrapper__image__downloadbtn
                                      }
                                      onClick={() =>
                                        handleDownload(
                                          items?.artist_photo_id_front
                                        )
                                      }
                                    >
                                      Download
                                    </Button>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs>
                                <label
                                  className={
                                    classess.page__container__list__table__bank_info_wrapper__name
                                  }
                                >
                                  ID Back
                                </label>
                                <div
                                  className={
                                    classess.page__container__list__table__bank_info_wrapper
                                  }
                                >
                                  <div
                                    className={
                                      classess.page__container__list__table__bank_info_wrapper__box
                                    }
                                  >
                                    <img
                                      src={items?.artist_photo_id_back}
                                      className={
                                        classess.page__container__list__table__bank_info_wrapper__image
                                      }
                                      style={{ width: "60%" }}
                                      alt=""
                                    />

                                    <Button
                                      variant="contained"
                                      className={
                                        classess.page__container__list__table__bank_info_wrapper__image__downloadbtn
                                      }
                                      onClick={() =>
                                        handleDownload(
                                          items?.artist_photo_id_back
                                        )
                                      }
                                    >
                                      Download
                                    </Button>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )}
                    {comments === index && (
                      <TableRow>
                        <TableCell colSpan={6} sx={{ borderBottom: 0 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} md={10}>
                              <FormControl
                                className={
                                  classess.page__container__list__table__brow__comment_row_container
                                }
                              >
                                <TextField
                                  required
                                  placeholder="Add comment"
                                  rows={6}
                                  multiline="true"
                                  value={note}
                                  onChange={(e) => setNote(e.target.value)}
                                  sx={{
                                    "& fieldset": {
                                      border: "1px solid #5a7380",
                                      borderRadius: "12px",
                                      transition: "all 0.3s ease-in-out",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      backgroundColor: "#192233",
                                      borderRadius: "12px",
                                      color: "#979797",
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          "var(--base-input-focus-color)",
                                      },
                                    },
                                    "&:hover fieldset": {
                                      borderColor:
                                        "var(--base-input-focus-color) !important",
                                    },
                                  }}
                                />
                                {buttonClicked && note.trim() === "" && (
                                  <Typography variant="caption" color="error">
                                    Please enter a comment.
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <Button
                                variant="contained"
                                className={
                                  classess.page__container__list__table__brow__comment_row_container__button
                                }
                                onClick={() => noteHandler(items?._id)}
                              >
                                Add Comments
                              </Button>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </CustomTableBody>
                );
              })
          )}
        </CustomTable>
      </CustomTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        sx={{ color: "#d6d6d6", width: "100%", height: "20%" }}
        count={listCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          actions: "custom-pagination-actions",
          select: "custom-pagination-select",
          displayedRows: "custom-select-style",
          selectLabel: "custom-select-style",
        }}
        SelectProps={{
          classes: {
            select: "custom-select",
          },
        }}
      />
    </React.Fragment>
  );
};

const CustomModal = ({ modalOpen, closeModal, style }) => {
  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className={classess.modalCss}>
        <Box className={classess.modalCss__heading}>Action </Box>
        <Box
          sx={{
            pt: 3,
            pl: 3,
            pr: 3,
            pb: 1,
            color: "white",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          ARTIST ID NOT FOUND
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 6,
          }}
        >
          <Button className={classess.modalCss__button} onClick={closeModal}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
