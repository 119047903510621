// React and Redux imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Axios for HTTP requests
import axios from "axios";

// Material-UI and custom components imports
import { Box, IconButton, Typography, Button } from "@mui/material";
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../../custom-mui-style/custom-mui-styles";
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";

// Style, assets and utility imports
import classess from "./style.module.scss";
import { getItemToLocalStorage } from "../../../services/storage";
import { config as URLconfig } from "../../../enviorment/enviorment";
import NewLoader from "../../lottie-loader/Loader.json";
import EyeIcon from "../../../assets/eye.svg";

const ContractsTable = () => {
  const user = useSelector((state) => state.auth.user);
  const storedToken = getItemToLocalStorage("accessToken");

  const navigate = useNavigate();

  const [contracts, setContracts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchContracts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${URLconfig.BASE_URL}/contracts?page=0&limit=10`,
          {
            headers: { Authorization: `Bearer ${storedToken}` },
          }
        );
        setContracts(response.data.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContracts();
  }, []);
  return (
    <Box className={classess.page}>
      <ContractTableComponent isLoading={isLoading} contracts={contracts} />
      <Button
        onClick={() => navigate("/blig/contracts")}
        className={classess.page__button}
      >
        View All Contracts
      </Button>
    </Box>
  );
};

const ContractTableComponent = ({ isLoading, contracts }) => {
  const navigate = useNavigate();

  return (
    <CustomTableContainer className={classess.page__table}>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "50px",
              height: "50px",
            }}
          >
            <Lottie
              animationData={NewLoader}
              className={classess.page__loader}
            />
          </Box>
        </Box>
      ) : (
        <CustomTable stickyHeader aria-label="sticky table">
          <CustomTableHead>
            <CustomTableCellHeader>NAME</CustomTableCellHeader>

            <CustomTableCellHeader>Artist</CustomTableCellHeader>

            <CustomTableCellHeader>Initiated By</CustomTableCellHeader>

            <CustomTableCellHeader>INITIATED ON</CustomTableCellHeader>

            <CustomTableCellHeader>ACTIONS</CustomTableCellHeader>
          </CustomTableHead>

          <CustomTableBody>
            {contracts?.length > 0 ? (
              contracts?.slice(0, 10)?.map((contract) => (
                <React.Fragment key={contract._id}>
                  <Box sx={{ m: "1rem" }} />
                  <CustomTableRow>
                    <CustomTableCell
                      sx={{
                        borderTopLeftRadius: "12px",
                        borderBottomLeftRadius: "12px",
                      }}
                    >
                      <span className={classess.page__heading1}>
                        {contract?._id?.substring(0, 8).toUpperCase()}
                      </span>
                    </CustomTableCell>

                    <CustomTableCell>
                      <Typography className={classess.page__heading1}>
                        {contract.artist_name ? contract.artist_name : ""}
                      </Typography>
                    </CustomTableCell>

                    <CustomTableCell>
                      <Typography className={classess.page__heading2}>
                        {contract.artist_representative_name
                          ? contract.artist_representative_name
                          : ""}
                      </Typography>
                    </CustomTableCell>

                    <CustomTableCell>
                      <Typography className={classess.page__heading2}>
                        {contract.createdAt
                          ? new Date(contract.createdAt).toLocaleDateString()
                          : ""}
                      </Typography>
                    </CustomTableCell>

                    <CustomTableCell
                      sx={{
                        borderBottomRightRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    >
                      <IconButton
                        className={classess.page__actionIcon}
                        onClick={() =>
                          navigate(`/blig/contracts/${contract?._id}`)
                        }
                      >
                        <ReactSVG src={EyeIcon} />
                      </IconButton>
                    </CustomTableCell>
                  </CustomTableRow>
                </React.Fragment>
              ))
            ) : (
              <React.Fragment>
                <Box sx={{ marginTop: "1rem" }} />

                <CustomTableRow>
                  <CustomTableCell colSpan={7} sx={{ borderRadius: "12px" }}>
                    <Typography className={classess.page__notFound}>
                      No contracts found.
                    </Typography>
                  </CustomTableCell>
                </CustomTableRow>
              </React.Fragment>
            )}
          </CustomTableBody>
        </CustomTable>
      )}
    </CustomTableContainer>
  );
};
export default ContractsTable;
