// React Imports
import React, { useEffect, useState } from "react";

// Router Imports
import { useParams } from "react-router-dom";

// MUI Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { Tooltip } from "@mui/material";

// Icon Imports
import PostAddIcon from "@mui/icons-material/PostAdd";
import { RiLock2Line } from "react-icons/ri";

// Style Imports
import classess from "./style.module.scss";

// Service and Config Imports
import { getItemToLocalStorage } from "../../services/storage";
import { config as URLconfig } from "../../enviorment/enviorment";
import axios from "axios";

// Redux Imports
import { useSelector } from "react-redux";

// Component Imports
import ContractHistory from "../../components/ContractHistory/ContractHistory";
import AuthEnum from "../../enums/auth.enum";
const PreviewContract = () => {
  // States
  const { id } = useParams();
  const [contract, setContract] = useState(null);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  // Functions
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(value);
  };

  const updateNotes = (newContract) => {
    setContract((prevContract) => ({
      ...prevContract,
      notes: [...prevContract.notes, newContract?.data],
    }));
  };

  // useEffect for fetching contract data
  useEffect(() => {
    let isApiSubscribed = true;
    const token = getItemToLocalStorage(AuthEnum.TOKEN);
    const authConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${URLconfig.BASE_URL}/contracts/${id}`, authConfig)
      .then((res) => {
        if (isApiSubscribed) {
          setContract(res.data.data);
        }
      });

    return () => {
      isApiSubscribed = false;
    };
  }, [id]);
  return (
    <Container maxWidth="xxl">
      <Grid container spacing={2} className={classess.page}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          xl={9}
          className={classess.page__details}
        >
          <Box
            varient="div"
            component="div"
            className={classess.page__details__box}
          >
            <Box
              varient="div"
              component="div"
              className={classess.page__details__box__adetails}
            >
              <Box
                varient="div"
                component="div"
                className={classess.page__details__box__adetails__header}
              >
                <Box
                  varient="div"
                  component="div"
                  className={
                    classess.page__details__box__adetails__header__img_title_container
                  }
                >
                  <Avatar
                    className={
                      classess.page__details__box__adetails__header__img_title_container__image
                    }
                    src={contract?.artist?.avatar}
                    alt={contract?.name}
                  />
                  <span
                    className={
                      classess.page__details__box__adetails__header__img_title_container__title
                    }
                  >
                    {contract?.artist_name}
                  </span>
                </Box>

                {user?.subscription === "bl-user" ? (
                  <Button
                    sx={{ color: "#000" }}
                    startIcon={<PostAddIcon />}
                    className={
                      classess.page__details__box__adetails__header__amendbtn
                    }
                    onClick={handleOpen}
                  >
                    Add Revisions
                  </Button>
                ) : (
                  <Tooltip
                    title={"Unlock Pro Features"}
                    placement="top"
                    arrow
                    enterDelay={100}
                  >
                    <Button
                      sx={{ opacity: "0.5" }}
                      startIcon={<RiLock2Line />}
                      className={
                        classess.page__details__box__adetails__header__amendbtn
                      }
                    >
                      Add Revisions
                    </Button>
                  </Tooltip>
                )}
              </Box>
              <ContractHistory
                updateNotes={updateNotes}
                contract={contract}
                open={open}
                onvaluechange={handleClose}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PreviewContract;
