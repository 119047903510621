import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import classess from "./style.module.scss";

const Graph = ({ data }) => {
  const [Spotifystate, setSpotifystate] = useState({
    series: [],
    options: {
      chart: {
        id: "Spotifychart2",
        type: "area",
        height: 350,
        foreColor: "#1DB954",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#1DB954"],
      stroke: {
        width: 3,
        curve: "straight",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.4,
          opacityTo: 0,
        },
      },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
      },
    },
    seriesLine: [],
    optionsLine: {
      chart: {
        id: "Spotifychart1",
        height: 130,
        type: "bar",
        foreColor: "#ccc",
        brush: {
          target: "Spotifychart2",
          enabled: true,
        },
        toolbar: {
          autoSelected: "selection",
        },
        selection: {
          enabled: true,
          fill: {
            color: "#222c41",
            opacity: 0.4,
          },
          xaxis: {
            min: new Date().getTime(), // default to now if data is missing
            max: new Date().getTime(),
          },
        },
      },
      colors: ["#1DB954"],
      stroke: {
        width: 2,
      },
      grid: {
        borderColor: "#444",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.4,
          opacityTo: 0,
        },
      },
    },
  });

  useEffect(() => {
    if (data?.data) {
      setSpotifystate((prevState) => ({
        ...prevState,
        series: [
          {
            name: "Streams",
            data: data?.data,
          },
        ],
        seriesLine: [
          {
            data: data?.data,
          },
        ],
        optionsLine: {
          ...prevState.optionsLine,
          chart: {
            ...prevState.optionsLine.chart,
            selection: {
              ...prevState.optionsLine.chart.selection,
              xaxis: {
                min: new Date(data?.minDate).getTime(),
                max: new Date(data?.maxDate).getTime(),
              },
            },
          },
        },
      }));
    }
  }, [data]);
  if (!data?.data) {
    return <div>Loading...</div>; // Or any other fallback content
  }

  return (
    <div id="wrapper" className={classess.wrapper}>
      <div id="chart-line2">
        <ReactApexChart
          options={Spotifystate.options}
          series={Spotifystate.series}
          type="area"
          height={230}
        />
      </div>
      <div id="chart-line" className={classess.chart_line}>
        <ReactApexChart
          options={Spotifystate.optionsLine}
          series={Spotifystate.seriesLine}
          type="bar"
          height={130}
        />
      </div>
    </div>
  );
};

export default Graph;
