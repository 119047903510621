import React, { useEffect, useRef, useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
// import SaveBtn from "../../../saveBtn/saveBtn";
import VerifyArtistList from "../../../verify-artists/verify-artists";
import { useDispatch, useSelector } from "react-redux";
import {
  makeEmptySearchResultTracks,
  setSearchResultTracks,
  setTracks,
} from "../../../../redux/slice/artist";
import SearchIcon from "@mui/icons-material/Search";
import { Divider, FormControlLabel } from "@mui/material";
import {
  CustomCheckbox,
  CustomFilterSelectArtist,
  CustomMenuItemArtist,
  CustomSelect,
} from "../../../../custom-mui-style/custom-mui-styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoFilterOutline } from "react-icons/io5";

// import { MenuItem } from "@mui/base";

// import { ReactSVG } from "react-svg";
// import musicIcon from "../../../../assets/musicIcon.svg";
const VerifyConfig = ({
  included_music,
  contract_length,
  catelog_income,
  new_music_income,
  calcalute_tracks_estimate,
  calcalute_Year_income_by_tracks,
  showPublish,
  showPublishValue,
  tracksLoad,
  // artist,
}) => {
  const dispatch = useDispatch();
  const dispatchRef = useRef(dispatch);
  const tracks = useSelector((state) => state.artist.tracks);
  const searchTracks = useSelector((state) => state.artist.searchTracks);
  const [value, setValue] = useState("none");
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);

    let arr = tracks.map((el) => el);

    if (event.target.value === "none") {
      dispatch(setSearchResultTracks(tracks));
    }

    if (event.target.value === "high-streams") {
      let sortedArr = arr.sort((a, b) => {
        let AtotalStrams =
          a.spotify_streams_total +
          a.tiktok_views_total +
          a.youtube_video_views_total;
        let BtotalStrams =
          b.spotify_streams_total +
          b.tiktok_views_total +
          b.youtube_video_views_total;
        return BtotalStrams - AtotalStrams;
      });
      dispatch(setSearchResultTracks(sortedArr));
    }

    if (event.target.value === "low-streams") {
      let sortedArr = arr.sort((a, b) => {
        let AtotalStrams =
          a.spotify_streams_total +
          a.tiktok_views_total +
          a.youtube_video_views_total;
        let BtotalStrams =
          b.spotify_streams_total +
          b.tiktok_views_total +
          b.youtube_video_views_total;
        return AtotalStrams - BtotalStrams;
      });
      dispatch(setSearchResultTracks(sortedArr));
    }

    if (event.target.value === "latest-release") {
      let sortedArr = arr.sort((a, b) => {
        return new Date(b.release_date) - new Date(a.release_date);
      });
      dispatch(setSearchResultTracks(sortedArr));
    }

    if (event.target.value === "last-30-days-release") {
      let today = new Date();
      let last30Days = new Date();
      last30Days.setDate(today.getDate() - 30);

      let filterArr = arr.filter(
        (el) => new Date(el.release_date).getTime() > last30Days.getTime()
      );
      dispatch(setSearchResultTracks(filterArr));
    }

    if (event.target.value === "last-30-days-streams") {
      const d = new Date();
      let currentMonth = d.getMonth() + 1;
      let lastMonth = currentMonth - 1;

      let val = lastMonth.toString().padStart(2, "0");

      let sortedArr = arr.sort((a, b) => {
        let x = a.spotify_monthly_streams[0][`${d.getFullYear()}-${val}`];
        let y = a.youtube_monthly_streams[0][`${d.getFullYear()}-${val}`];
        let xx = b.spotify_monthly_streams[0][`${d.getFullYear()}-${val}`];
        let yy = b.youtube_monthly_streams[0][`${d.getFullYear()}-${val}`];

        let sp_curr = x ? x.streams_total : 0;
        let yt_curr = y ? y.video_views_total : 0;

        let sp_prev = xx ? xx.streams_total : 0;
        let yt_prev = yy ? yy.video_views_total : 0;

        let total_prev = sp_prev + yt_prev;
        let total_curr = sp_curr + yt_curr;

        return total_prev - total_curr;
      });
      dispatch(setSearchResultTracks(sortedArr));
    }

    if (event.target.value === "single") {
      let filterArr = arr.filter((el) => el.track_type === "single");
      dispatch(setSearchResultTracks(filterArr));
    }
    if (event.target.value === "album") {
      let filterArr = arr.filter((el) => el.track_type === "album");
      dispatch(setSearchResultTracks(filterArr));
    }
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(makeEmptySearchResultTracks());
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    if (value.length >= 3) {
      const filteredTracks = tracks.filter((e) =>
        e.title.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
      dispatch(setSearchResultTracks(filteredTracks));
    } else {
      dispatch(makeEmptySearchResultTracks());
    }
  };

  return (
    <Box className={classess.page}>
      <Box className={classess.page__top}>
        <Box className={classess.page__top__heading}>Select tracks</Box>
        <Box
          varient="div"
          component="div"
          className={classess.page__top__header}
        >
          {/* <Box display="flex" alignItems="center" gap={2}> */}
          {/* <p>Sort By: </p> */}
          <FilterSelect
            value={value}
            handleChange={handleChange}
            handleOpen={handleOpen}
            handleClose={handleClose}
            isOpen={isOpen}
          />
          {/* </Box> */}
          <Box>
            <FormControlLabel
              control={<CustomCheckbox checked={showPublishValue} />}
              label="Publishing"
              onChange={showPublish}
            />
          </Box>
          <Box
            varient="div"
            component="div"
            className={classess.page__top__header__seachBar}
          >
            <SearchIcon
              className={classess.page__top__header__seachBar__searchIcon}
            />
            <input
              className={classess.page__top__header__seachBar__search}
              placeholder="Search"
              type="search"
              onInput={(e) => handleSearch(e)}
              required
            />
          </Box>
        </Box>
      </Box>
      <Divider className={classess.page__divider} />
      {/* <Box className={classess.searchBarMob}></Box> */}

      <VerifyArtistList
        searchTracks={searchTracks}
        included_music={included_music}
        contract_length={contract_length}
        catelog_income={catelog_income}
        new_music_income={new_music_income}
        calcalute_tracks_estimate={calcalute_tracks_estimate}
        calcalute_Year_income_by_tracks={calcalute_Year_income_by_tracks}
        showPublishValue={showPublishValue}
        tracksLoad={tracksLoad}
      />
    </Box>
  );
};
const FilterSelect = ({
  value,
  handleChange,
  handleOpen,
  handleClose,
  isOpen,
}) => (
  <CustomFilterSelectArtist
    value={value}
    onChange={handleChange}
    onOpen={handleOpen}
    onClose={handleClose}
    IconComponent={() =>
      isOpen ? (
        <IoIosArrowUp className={classess.page__select_icon} />
      ) : (
        <IoIosArrowDown className={classess.page__select_icon} />
      )
    }
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 300,
          border: "2px solid var(--base-input-focus-color)",
          borderRadius: "12px",
        },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    }}
  >
    <CustomMenuItemArtist value="none">
      <Box display={"flex"} alignItems={"center"} gap={"5px"}>
        <IoFilterOutline /> Sort By
      </Box>
    </CustomMenuItemArtist>
    <CustomMenuItemArtist value={"single"}>Single</CustomMenuItemArtist>
    <CustomMenuItemArtist value={"album"}>Album</CustomMenuItemArtist>
    <CustomMenuItemArtist value={"high-streams"}>
      High Streams
    </CustomMenuItemArtist>
    <CustomMenuItemArtist value={"low-streams"}>
      Low Streams
    </CustomMenuItemArtist>
    <CustomMenuItemArtist value={"last-30-days-streams"}>
      Last 30 Days Streams
    </CustomMenuItemArtist>
    <CustomMenuItemArtist value={"latest-release"}>
      Latest Release
    </CustomMenuItemArtist>
    <CustomMenuItemArtist value={"last-30-days-release"}>
      Last 30 Days Release
    </CustomMenuItemArtist>
  </CustomFilterSelectArtist>
);
export default VerifyConfig;
