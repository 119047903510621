import React, { useEffect, useState } from "react";
import classes from "./style.module.scss";
import {
  Container,
  Grid,
  Box,
  Typography,
  Avatar,
  IconButton,
  Stack,
  Button,
  Tooltip,
} from "@mui/material";

import { useSelector } from "react-redux";

// Custom Components and Styles Imports
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../../custom-mui-style/custom-mui-styles";

import FinanceDeals from "../../../components/finance-deals/FinanceDeals";

const Deals = () => {
  const user = useSelector((state) => state.auth.user);

  const getCurrentTimeOfDay = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return "GOOD MORNING";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "GOOD AFTERNOON";
    } else {
      return "GOOD EVENING";
    }
  };

  const timeOfDay = getCurrentTimeOfDay();

  return (
    <Container maxWidth="xxl" className="graphToolsHide">
      <Grid container spacing={2} className={classes.page}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box className={classes.page__topSec}>
            <Box>
              <Typography className={classes.page__topSec__greeting}>
                {timeOfDay}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.page__topSec__userName}>
                {user.role === "artist"
                  ? user.meta_data.name
                  : user?.firstName + " " + user?.lastName + "!" || "N/A"}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {/* <MyDealsTable /> */}
          <FinanceDeals />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Deals;

const MyDealsTable = () => {
  return (
    <CustomTableContainer className={classes.page__table}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableHead>
          <CustomTableCellHeader
            sx={{ border: "none !important" }}
          ></CustomTableCellHeader>
          <CustomTableCellHeader sx={{ border: "none !important" }}>
            ARTIST NAME
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            STATUS
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            CREATION DATE
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            LAST PAID AMOUNT
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            PAYMENT DATE
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            NEXT PAYMENT DATE
          </CustomTableCellHeader>

          <CustomTableCellHeader
            sx={{ border: "none !important" }}
          ></CustomTableCellHeader>
        </CustomTableHead>

        <CustomTableBody>
          <React.Fragment>
            <Box sx={{ mb: "1rem" }} />
            <CustomTableRow>
              <CustomTableCell
                sx={{
                  maxWidth: 50,
                  borderTopLeftRadius: "12px",
                  borderBottomLeftRadius: "12px",
                }}
              >
                <Tooltip
                  title={"Artist Image"}
                  placement="top"
                  arrow
                  enterDelay={100}
                >
                  <Avatar
                    // src={}
                    alt={"Artists Image"}
                    onClick={() => {
                      // navigate(`/blig/track/${track?.id}`);
                    }}
                  />
                </Tooltip>
              </CustomTableCell>

              <CustomTableCell>
                <Tooltip
                  title={"DEAL AMOUNT"}
                  placement="top"
                  arrow
                  enterDelay={100}
                >
                  <Typography className={classes.page__heading1}>
                    {"Brandz"}
                  </Typography>
                </Tooltip>
              </CustomTableCell>

              <CustomTableCell>
                <Typography className={classes.page__heading2}>
                  {"PAID"}
                </Typography>
              </CustomTableCell>

              <CustomTableCell>
                <Typography className={classes.page__heading2}>
                  {"12.12.2023"}
                </Typography>
              </CustomTableCell>

              <CustomTableCell>
                <Typography className={classes.page__heading2}>
                  {"$250,000"}
                </Typography>
              </CustomTableCell>

              <CustomTableCell>
                <Typography className={classes.page__heading2}>
                  {"12.16.2023"}
                </Typography>
              </CustomTableCell>

              <CustomTableCell>
                <Typography className={classes.page__heading2}>
                  {"01.16.2024"}
                </Typography>
              </CustomTableCell>

              <CustomTableCell
                sx={{
                  borderBottomRightRadius: "12px",
                  borderTopRightRadius: "12px",
                }}
              >
                <Stack direction="row" spacing={1}>
                  <Tooltip
                    title={"View"}
                    placement="top"
                    arrow
                    enterDelay={100}
                  >
                    <Button className={classes.page__button}>
                      VIEW DETAILS
                    </Button>
                  </Tooltip>

                  <Tooltip
                    title={"View"}
                    placement="top"
                    arrow
                    enterDelay={100}
                  >
                    <Button className={classes.page__button}>
                      MAKE PAYMENT
                    </Button>
                  </Tooltip>
                </Stack>
              </CustomTableCell>
            </CustomTableRow>
          </React.Fragment>
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};
