import React from "react";
import classess from "./style.module.scss";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

const DeleteConformationDialog = ({
  onClose,
  open,
  artist,
  delete_new_music_records,
  deleteUploadFile,
}) => {
  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxHeight: 435,
          bgcolor: "#222c41",
          borderRadius: "12px",
        },
      }}
      maxWidth="xs"
      open={open}
      className={classess.dialog}
    >
      <DialogTitle className={classess.dialog__title}>
        Delete Confirmation
      </DialogTitle>
      <DialogContent dividers className={classess.dialog__content}>
        <DialogContentText
          className={classess.dialog__content__text}
          id="alert-dialog-description"
        >
          {artist.name && (
            <>
              This is to confirm that you want to delete the artist named{" "}
              <strong>{artist.name}</strong>. Are you sure you want to delete
              this artist?
            </>
          )}
          {artist.title && (
            <>
              This is to confirm you that, you want to delete the track named{" "}
              <strong>{artist.title}.</strong> Are you sure you want to delete
              this track?
            </>
          )}
          {delete_new_music_records && (
            <>
              This is to confirm you that, you want to delete the New Music
              Record from this Artist.
            </>
          )}
          {deleteUploadFile && (
            <>
              This is to confirm you that, you want to delete uploaded file from
              this Artist.
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classess.dialog__actions}>
        <Button
          onClick={handleOk}
          className={classess.dialog__actions__yesButton}
        >
          Yes, I approve
        </Button>
        <Button
          variant="outlined"
          autoFocus
          onClick={handleCancel}
          className={classess.dialog__actions__noButton}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConformationDialog;
